.control {
    display: block;
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    font-size: 18px;
    margin-bottom: 0;
}

.control input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

[type=checkbox],
[type=radio] {
    box-sizing: border-box;
    padding: 0;
}

.control__indicator {
    position: absolute;
    top: 2px;
    left: 0;
    height: 20px;
    width: 20px;
    background: #e6e6e6;
}

.control input:checked~.control__indicator {
    background: #2aa1c0;
}
.checkbox--details input:checked~.control__indicator {
    background: #25a56a;
}
.checkbox--details .control__indicator {
    height: 16px;
    width: 16px;
}

.control input:checked~.control__indicator:after {
    display: block;
}


.control__indicator:after {
    content: '';
    position: absolute;
    display: none;
}

.checkbox--details .control__indicator:after {
    left: 6px !important;
    top: 0px !important;
    width: 6px !important;
    height: 12px !important;
}


.control--checkbox .control__indicator:after {
    left: 8px;
    top: 4px;
    width: 6px;
    height: 10px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}