.rhap_main {
    position: relative;
    padding-bottom: 24px;
    flex-direction: column-reverse !important;
}

.rhap_container,
.rhap_volume-container {
    background-color: transparent !important;
    border: none !important;
    outline: none !important;
    border-radius: 0px !important;
    padding: 0px !important;
    box-shadow: none !important;
}

.rhap_controls-section {
    margin-bottom: 15px;
}

.rhap_progress-section {
    height: 20px;
    padding-bottom: 20px;
}

.rhap_main-controls-button svg {
    color: #ff5555;
}

.rhap_progress-filled {
    background-color: #25a56a !important;
}

.rhap_progress-indicator {
    background-color: #FFFFFF !important;
    width: 14px !important;
    height: 14px !important;
    top: -5px !important;
}

.rhap_download-progress {
    background-color: #7d8087 !important;
}

.rhap_button-clear.rhap_volume-button {
    color: #ff5555 !important;
}

.rhap_volume-bar {
    background-color: #7d8087 !important;
}

.rhap_volume-indicator {
    background-color: #FFFFFF !important;
}

.rhap_time {
    color: #c0c0c0 !important;
    font-size: 14px !important;
}

.rhap_main-controls-button {
    margin: 0 3px;
    color: #868686;
    font-size: 24px;
    width: 24px;
    height: 24px;
}

.rhap_play-pause-button {
    width: 35px !important;
    height: 35px !important;
    font-size: initial !important;
}


.rhap_additional-controls {
    display: none !important;
}

.rhap_controls-section {
    display: block !important;
}

.rhap_main .rhap_volume-controls {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: calc(100% - 30px);
    justify-content: flex-start;
}

.rhap_footer {
    position: relative;
}

@media only screen and (max-width: 600px) {
    .rhap_main .rhap_volume-controls {
        display: none;
    }
}