@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600&family=Roboto:wght@400;500&display=swap');

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

body,
html {
  height: 100%;
}

#root {
  height: auto !important;
  min-height: 0px !important;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  min-height: 100vh;
}

body {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  background-color: #16151a;
  -webkit-font-smoothing: antialiased;
}

button {
  padding: 0;
  border: none;
  background-color: transparent;
  transition: 0.5s ease;
  transition-property: color, background-color, border-color, box-shadow;
  cursor: pointer;
}

button:focus {
  outline: 0;
}

a {
  transition: 0.5s ease;
  transition-property: color, background-color, border-color, box-shadow;
}

a:active,
a:focus,
a:hover {
  outline: 0;
  text-decoration: none;
}

input,
select {
  padding: 0;
  margin: 0;
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  box-shadow: none;
  transition: 0.5s ease;
  transition-property: color, background-color, border-color, box-shadow;
}

input:focus,
select:focus {
  outline: 0;
}

select::-ms-expand {
  display: none;
}

input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

::-moz-selection {
  background: #fff;
  color: #222227;
  text-shadow: none;
}

::selection {
  background: #fff;
  color: #222227;
  text-shadow: none;
}

::-webkit-input-placeholder {
  color: silver;
  opacity: 1;
}

::-moz-placeholder {
  color: silver;
  opacity: 1;
}

:-moz-placeholder {
  color: silver;
  opacity: 1;
}

:-ms-input-placeholder {
  color: silver;
  opacity: 1;
}

:focus {
  outline: -webkit-focus-ring-color auto 0;
}

body::-webkit-scrollbar {
  width: 16px;
}

body::-webkit-scrollbar-track {
  background: #222227;
}

body::-webkit-scrollbar-thumb {
  background-color: silver;
  outline: 1px solid #222227;
}

:focus-visible {
  outline: 0 dotted;
}

.row--grid {
  margin-right: -10px;
  margin-left: -10px;
}

.row--grid .col-12,
.row--grid .col-6 {
  padding-left: 10px;
  padding-right: 10px;
}

@media (min-width: 768px) {
  .row--grid {
    margin-right: -15px;
    margin-left: -15px;
  }

  .row--grid .col-12,
  .row--grid .col-6 {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row--sidebar {
  margin-right: -10px;
  margin-left: -10px;
}

.row--sidebar .col-12,
.row--sidebar .col-6 {
  padding-left: 10px;
  padding-right: 10px;
}

svg {
  width: 24px;
  height: 24px;
  transition: fill 0.4s ease;
  fill: #ffffff;
}

.header {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: auto;
  height: 55px;
  background-color: #16151a;
  border-bottom: 1px solid #222227;
  z-index: 101;
}

.header__content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  position: relative;
  padding: 0 5px;
}

.header__logo {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 70px;
  width: 100%;
}

.header__logo img {
  width: 100px;
  height: auto;
  display: block;
}

.header__actions {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: auto;
  margin-left: auto;
  margin-right: 40px;
}

.header__action-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 36px;
  width: 100%;
}

.header__action-btn svg {
  width: 24px;
  height: auto;
  fill: silver;
  transition: fill 0.5s ease;
}

.header__btn {
  position: absolute;
  width: 24px;
  height: 22px;
  display: block;
  right: 15px;
  top: 18px;
}

.header__btn span {
  position: absolute;
  right: 0;
  width: 24px;
  height: 2px;
  background-color: silver;
  border-radius: 2px;
  transition: 0.5s ease;
}

.header__btn span:first-child {
  top: 0;
}

.header__btn span:nth-child(2) {
  top: 10px;
  width: 16px;
}

.header__btn span:last-child {
  top: 20px;
  width: 8px;
}

.header__btn:hover span {
  background-color: #25a56a;
}

.header__btn--active span {
  background-color: #25a56a;
}

.header__btn--active span:first-child {
  transform: rotate(-45deg);
  top: 9px;
}

.header__btn--active span:nth-child(2) {
  opacity: 0;
  width: 0;
}

.header__btn--active span:last-child {
  width: 24px;
  transform: rotate(45deg);
  top: 9px;
}

.header__search {
  position: absolute;
  left: 0;
  top: -71px;
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: #16151a;
  z-index: 1;
  padding: 0 15px;
  border-bottom: 1px solid #222227;
  transition: top 0.5s ease;
}

.header__search input {
  width: calc(100% - 30px);
  height: 40px;
  background-color: #222227;
  color: #fff;
  font-size: 16px;
  border-radius: 12px;
  border: none;
  padding: 0 20px;
  font-family: Inter, sans-serif;
}

.header__search input:focus {
  border-color: #fff;
}

.header__search button {
  position: absolute;
  right: 60px;
  top: 15px;
  height: 40px;
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.header__search button svg {
  width: 20px;
  height: auto;
  fill: silver;
  transition: fill 0.5s ease;
}

.header__search button:hover svg {
  fill: #25a56a;
}

.header__search button.close {
  right: 15px;
}

.header__search--active {
  top: 0;
}

.header__nav {
  display: none;
}

.header__product {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  position: relative;
  padding-left: 56px;
}

.header__product button {
  position: absolute;
  width: 14px;
  bottom: 5px;
  right: 0;
}

.header__product button svg {
  width: 14px;
  height: auto;
  fill: silver;
  transition: fill 0.4s ease;
}

.header__product button:hover svg {
  fill: #25a56a;
}

.header__product img {
  width: 46px;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 6px;
}

.header__product p {
  color: #fff;
  font-size: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  width: 100%;
  display: block;
  margin-bottom: 0;
  font-weight: 400;
}

.header__product p a {
  color: #fff;
}

.header__product p a:hover {
  color: #25a56a;
}

.header__product span {
  padding: 0;
  font-size: 14px;
  color: silver;
  position: relative;
  background-color: transparent;
  top: auto;
  left: auto;
  display: block;
  width: 100%;
  margin-top: 5px;
  line-height: 100%;
}

.header__product:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.header__all {
  display: none;
}

@media (min-width: 400px) {
  .header__action {
    margin-left: 20px;
  }

  .header__action:first-child {
    margin-left: 0;
  }

  .header__actions {
    margin-right: 44px;
  }
}

@media (min-width: 576px) {
  .header__action--signin {
    width: auto;
    padding-left: 22px;
  }

  .header__action--signin:before {
    content: '';
    position: absolute;
    display: block;
    width: 1px;
    height: 24px;
    background-color: #222227;
    top: 50%;
    left: 0;
    margin-top: -11px;
  }

  .header__action--signin:hover a span,
  .header__action--signin:hover button span {
    color: #fff;
  }

  .header__action--signin:hover a svg,
  .header__action--signin:hover button svg {
    fill: #25a56a;
  }

  .header__action-btn span {
    display: block;
    white-space: nowrap;
    color: silver;
    font-size: 16px;
    margin-right: 10px;
    font-family: Inter, sans-serif;
    transition: color 0.5s ease;
  }

  .header__content {
    padding: 0 30px;
  }

  .header__search {
    padding: 0 30px;
  }

  .header__search button {
    right: 75px;
  }

  .header__search button.close {
    right: 30px;
  }

  .header__btn {
    right: 30px;
  }
}

@media (min-width: 768px) {
  .header__action {
    margin-left: 0;
  }

  .header__action--signin {
    padding-left: 20px;
  }
}

@media (min-width: 1200px) {
  .header {
    position: relative;
    padding-left: 280px;
  }

  .header__btn,
  .header__logo {
    display: none;
  }

  .header__actions {
    margin-right: 0;
  }

  .header__action--search {
    display: none;
  }

  .header__search {
    position: relative;
    top: auto;
    left: auto;
    width: 320px;
    padding: 0;
    border: none;
    background-color: transparent;
  }

  .header__search input {
    padding: 0 60px 0 20px;
    width: 100%;
  }

  .header__search button {
    right: 20px;
  }

  .header__search button.close {
    display: none;
  }

  .header__search--active {
    top: auto;
  }

  .header__nav {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-right: 60px;
  }

  .header__nav a {
    font-size: 16px;
    color: silver;
    margin-right: 30px;
    font-family: Inter, sans-serif;
  }

  .header__nav a:last-child {
    margin-right: 0;
  }

  .header__nav a:hover {
    color: #25a56a;
  }

  .header__all {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.1);
    color: silver;
    font-size: 13px;
    font-weight: 400;
    font-family: Inter, sans-serif;
    margin-bottom: 15px;
    height: 24px;
    border-radius: 8px 8px 0 0;
  }

  .header__all:hover {
    color: #25a56a;
  }
}

.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #16151a;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 101;
  width: 280px;
  transform: translateX(-100%);
  transition: transform 0.5s ease;
  border-right: 1px solid #222227;
}

.sidebar__logo {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 70px;
  width: 100%;
  padding: 0 30px 0 15px;
  border-bottom: 1px solid #222227;
}

.sidebar__logo img {
  width: 100px;
  height: auto;
  display: block;
}

.sidebar__nav {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  padding: 30px 0 0;
  width: 100%;
  max-height: calc(100vh - 150px);
  overflow-y: auto;
}

.sidebar__nav__mobile {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;
  padding: 0 0;
  width: 100%;
  /* max-height: calc(100vh - 150px); */
  overflow-y: auto;
  z-index: 30;
  font-size: 14px;
}

.sidebar__nav-link {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
  color: silver;
  font-family: Inter, sans-serif;
  margin-left: 15px;
  transition: 0.4s ease;
  position: relative;
}

.sidebar__nav-link svg {
  fill: silver;
  width: 22px;
  height: auto;
  transition: fill 0.5s;
  margin-right: 12px;
  margin-bottom: 2px;
}

.sidebar__nav-link__mobile svg {
  fill: silver;
  width: 22px;
  height: auto;
  transition: fill 0.5s;
  /* margin-bottom: 5px; */
}


.sidebar__nav-link svg:last-child {
  width: 16px;
  margin-right: 0;
  margin-top: 3px;
  margin-left: 2px;
  margin-bottom: 0;
}

.sidebar__nav-link:hover {
  color: #fff;
}

.sidebar__nav-link:hover svg {
  fill: #25a56a;
}

.sidebar__nav-link--active,
.sidebar__nav-link[aria-expanded='true'] {
  color: #fff;
}

.sidebar__nav-link--active svg,
.sidebar__nav-link[aria-expanded='true'] svg {
  fill: #25a56a;
}

.sidebar__nav-link--active:hover svg,
.sidebar__nav-link[aria-expanded='true']:hover svg {
  fill: #25a56a;
}

.sidebar__nav-link[aria-expanded='true'] {
  margin-bottom: 15px;
}

.sidebar__nav-item {
  width: 100%;
  display: block;
  margin-bottom: 15px;
  position: relative;
}

.sidebar__nav-item:last-child {
  margin-bottom: 0;
}

.sidebar__nav-item__bottom {
  display: flex;
  justify-content:center;
  width: 100%;
}

.sidebar--active {
  transform: translateX(0);
}

@media (min-width: 576px) {
  .sidebar__logo {
    padding: 0 30px;
  }

  .sidebar__nav-link {
    margin-left: 30px;
  }
}

@media (min-width: 1200px) {
  .sidebar__nav__mobile {
    display: none;
  }
  .sidebar {
    transform: translateX(0);
  }

  .sidebar__nav {
    max-height: calc(100vh - 332px);
  }
}

.player {
  position: fixed;
  z-index: 102;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #16151a;
  padding: 30px 15px 20px 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-top: 1px solid #222227;
  transform: translateY(100%);
  transition: transform 0.5s ease;
}

.player__cover {
  width: 100%;
  max-width: 90px;
}

.player__cover img {
  width: 90px;
  height: 90px;
  object-fit: cover;
  display: block;
  border-radius: 12px;
  margin-bottom: 10px;
}

.player__track {
  font-size: 16px;
  line-height: 24px;
  display: block;
  width: 100%;
  text-align: center;
  font-weight: 400;
  color: silver;
  margin-bottom: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}

.player__track b {
  font-weight: 600;
  color: #fff;
}

.player__content {
  width: 100%;
}

.player__btn {
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #16151a;
  border-radius: 12px 12px 0 0;
  height: 40px;
  width: 90px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 103;
  color: #fff;
  font-size: 14px;
  border: 1px solid #222227;
  border-bottom: none;
}

.player__btn svg {
  width: 18px;
  height: auto;
  fill: #25a56a;
  margin-right: 8px;
}

.player--active {
  transform: translateY(0);
}

@media (min-width: 1200px) {
  .player {
    width: 278px;
    padding: 20px 30px;
  }
}

.plyr__controls {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.plyr__actions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.plyr__actions button {
  margin: 0 5px;
}

.plyr__time {
  margin-left: 10px;
  color: silver;
  font-size: 14px;
  line-height: 100%;
}

.plyr--audio .plyr__controls {
  padding: 0;
  color: #fff;
  background-color: transparent;
}

.main {
  -webkit-flex: 1 1;
  flex: 1 1;
  position: relative;
  margin-top: 70px;
  padding: 20px 0 60px;
}

.main__title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.main__title h1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: Inter, sans-serif;
  font-weight: 400;
  color: #fff;
  font-size: 30px;
  line-height: 140%;
  margin-bottom: 0;
  position: relative;
  text-transform: capitalize;
}

.main__title h1 b {
  font-weight: 500;
}

.main__title h1 a {
  color: #fff;
}

.main__title h1 a:hover {
  color: #25a56a;
}

.main__title h2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  font-weight: 400;
  color: #fff;
  font-size: 26px;
  line-height: 100%;
  margin-bottom: 0;
  position: relative;
}

.main__title h2 svg {
  width: 26px;
  height: auto;
  fill: #25a56a;
  margin-right: 10px;
}

.main__title h2 b {
  font-weight: 500;
}

.main__title h2 a {
  color: #fff;
}

.main__title h2 a:hover {
  color: #25a56a;
}

.main__title h3 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: Inter, sans-serif;
  font-weight: 400;
  font-size: 24px;
  color: #fff;
  margin-bottom: 0;
  line-height: 100%;
  position: relative;
}

.main__title p {
  display: block;
  width: 100%;
  font-size: 16px;
  line-height: 26px;
  color: silver;
  margin-top: 20px;
  margin-bottom: 0;
}

.main__title p b {
  font-weight: 600;
}

.main__title p a {
  color: #25a56a;
  text-decoration: underline;
}

.main__title p a:hover {
  text-decoration: none;
}

.main__link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: silver;
  font-weight: 400;
}

.main__link svg {
  width: 22px;
  height: auto;
  fill: silver;
  margin-left: 5px;
  transition: fill 0.5s ease;
}

.main__link:hover {
  color: #fff;
}

.main__link:hover svg {
  fill: #25a56a;
}

.main__nav {
  display: none;
}

@media (min-width: 576px) {
  .main {
    padding: 20px 15px 50px;
  }
}

@media (min-width: 768px) {
  .main {
    padding: 30px 15px 70px;
  }

  .main__title {
    margin-top: 70px;
    margin-bottom: 0;
  }

  .main__title h1 {
    font-size: 36px;
  }

  .main__title h2 {
    font-size: 30px;
  }

  .main__title h2 svg {
    width: 30px;
  }

  .main__title p:last-child {
    margin-bottom: 10px;
  }

  .main__title--sidebar {
    margin-bottom: 10px;
  }

  .main__list {
    margin-top: 0;
  }
}

@media (min-width: 992px) {
  .main__title--sidebar {
    margin-top: 30px;
  }
}

@media (min-width: 1200px) {
  .main {
    padding: 0 15px 70px 295px;
    margin-top: 0;
  }

  .main__nav {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 34px;
    height: 34px;
    right: 0;
    bottom: -14px;
    z-index: 2;
  }

  .main__nav svg {
    width: 30px;
    height: auto;
    fill: silver;
    transition: fill 0.5s ease;
  }

  .main__nav:hover svg {
    fill: #25a56a;
  }

  .main__nav--hero {
    bottom: 15px;
  }

  .main__nav--hero svg {
    fill: #fff;
  }

  .main__nav--hero:hover svg {
    fill: #25a56a;
  }
}


/*==============================
	Profile
==============================*/
.profile {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #16151a;
  padding: 20px 20px 0;
  border-radius: 12px;
  position: relative;
  margin-top: 20px;
  border: 1px solid #222227;
}

.profile__tabs {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  width: 100%;
}

.profile__tabs li {
  margin-right: 20px;
}

.profile__tabs li:last-child {
  margin-right: 0;
}

.profile__tabs span {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  height: 40px;
  color: #c0c0c0;
  position: relative;
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  cursor: pointer;
}

.profile__tabs span:hover {
  color: #fff;
}

.profile__tabs span:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  display: block;
  background-color: #25a56a;
  transition: 0.5s ease;
  transform: translateY(2px);
  opacity: 0;
  border-radius: 2px 2px 0 0;
}

.profile__tabs span.active {
  color: #fff;
  cursor: default;
}

.profile__tabs span.active:before {
  opacity: 1;
  transform: translateY(0);
}

.profile__logout {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: auto;
}

.profile__logout span {
  font-size: 16px;
  color: #c0c0c0;
  margin-right: 10px;
  transition: 0.5s ease;
  font-family: 'Inter', sans-serif;
}

.profile__logout svg {
  fill: #c0c0c0;
  width: 24px;
  height: auto;
  transition: fill 0.5s ease;
}

.profile__logout:hover span {
  color: #fff;
}

.profile__logout:hover svg {
  fill: #25a56a;
}

.profile__user {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
}

.profile__meta {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.profile__meta h3 {
  color: #fff;
  font-family: 'Inter', sans-serif;
  display: block;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 7px;
  line-height: 100%;
}

.profile__meta span {
  color: #c0c0c0;
  font-size: 12px;
  line-height: 100%;
}

.profile__avatar {
  display: block;
  position: relative;
  width: 40px;
  overflow: hidden;
  border-radius: 8px;
  margin-right: 15px;
}

.profile__avatar img {
  width: 100%;
}

@media (min-width: 768px) {
  .profile {
    margin-top: 30px;
    padding: 0 20px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .profile__tabs {
    border-bottom: none;
    margin-bottom: 0;
    width: auto;
    margin-right: auto;
  }

  .profile__tabs li {
    margin-right: 30px;
  }

  .profile__tabs li:last-child {
    margin-right: 0;
  }

  .profile__tabs span {
    align-items: center;
    height: 70px;
    font-size: 16px;
  }

  .profile__user {
    margin-bottom: 0;
    margin-right: 60px;
  }

}

.hero {
  display: block;
  width: 100%;
}

.hero__slide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  min-height: 300px;
  border-radius: 12px;
  overflow: hidden;
  padding: 40px 20px;
  position: relative;
}

.hero__slide:before {
  content: '';
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg,
      rgba(0, 0, 0, 0.7) 0,
      rgba(0, 0, 0, 0) 100%);
  z-index: 1;
}

.hero__title {
  position: relative;
  z-index: 2;
  color: #fff;
  font-weight: 400;
  font-size: 30px;
  font-family: Inter, sans-serif;
  margin-bottom: 0;
  line-height: 130%;
}

.hero__text {
  position: relative;
  z-index: 2;
  color: silver;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 0;
  margin-top: 15px;
}

.hero__btns {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 10px;
}

.hero__btn {
  width: auto;
  min-width: 120px;
  height: 50px;
  border-radius: 12px;
  background-color: #222227;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 14px;
  position: relative;
  z-index: 2;
  text-transform: uppercase;
  font-family: Inter, sans-serif;
  font-weight: 500;
  padding: 0 20px;
  margin-top: 20px;
  margin-right: 20px;
}

.hero__btn svg {
  fill: #fff;
  width: 28px;
  height: auto;
  margin-right: 7px;
}

.hero__btn:last-child {
  margin-right: 0;
}

.hero__btn:hover {
  color: #25a56a;
}

.hero__btn--video {
  text-transform: none;
  font-size: 16px;
}

.hero__btn--video svg {
  fill: #25a56a;
  width: 28px;
  height: auto;
  margin-right: 10px;
  transition: fill 0.5s ease;
}

.hero__btn--video:hover {
  background-color: #25a56a;
  color: #fff;
}

.hero__btn--video:hover svg {
  fill: #fff;
}

.hero__btn--green {
  background-color: #25a56a;
  color: #fff;
}

.hero__btn--green:hover {
  background-color: #222227;
  color: #25a56a;
}

@media (min-width: 576px) {
  .hero__slide {
    min-height: 400px;
  }
}

@media (min-width: 768px) {
  .hero__slide {
    padding: 40px 30px;
    padding-right: 10%;
    min-height: 460px;
  }

  .hero__title {
    font-size: 36px;
  }

  .hero__btn {
    margin-top: 30px;
    margin-right: 30px;
    min-width: 160px;
    padding: 0 25px;
  }

  .hero__btn:last-child {
    margin-right: 0;
  }
}

@media (min-width: 992px) {
  .hero__slide {
    padding-right: 25%;
  }
}

@media (min-width: 1200px) {
  .hero {
    margin-top: 30px;
  }

  .hero__title {
    font-size: 44px;
  }

  .hero__text {
    font-size: 17px;
    line-height: 28px;
  }

  .hero__btn {
    margin-top: 40px;
  }

  .hero__slide {
    padding: 60px;
    padding-right: 33%;
  }
}

@media (min-width: 1900px) {
  .hero__slide {
    padding-right: 50%;
  }
}

.album {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 20px;
  width: 100%;
}

.album__cover {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  position: relative;
  overflow: hidden;
  width: 100%;
  background-color: #000;
}

.album__cover a {
  position: relative;
  width: 100%;
}

.album__cover a:not(a.search-item-link):after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.album__cover img {
  width: 100%;
  display: block;
  position: relative;
  z-index: 1;
  opacity: 1;
  transition: .5s ease;
}

.album__cover img:not(img.search-item-img) {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.play-icon {
  position: absolute;
  z-index: 20;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  top: 50%;
  left: 50%;
  margin: -28px 0 0 -28px;
  border-radius: 12px;
  background-color: #222227;
  transform: scale(0.8);
  opacity: 0;
  transition: 0.5s ease;
  border: 1px solid rgba(255, 255, 255, 0.05);
  cursor: pointer;
}

.play-icon svg {
  fill: #fff;
  width: 24px;
  height: auto;
  margin: 1px 0 0 1px;
  transition: fill 0.5s ease;
}

.play-icon:hover svg {
  fill: #25a56a;
}

.album__cover:hover img {
  opacity: 0.6;
  transform: scale(1.08);
}

.album__cover:hover .play-icon {
  opacity: 1;
  transform: scale(1);
}

.album__cover:hover .album__stat {
  opacity: 1;
  transform: scale(1);
}

.album__title {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-top: 5px;
}

.album__title h3 {
  display: block;
  width: 100%;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}

.album__title h3 a {
  color: #fff;
}

.album__title h3 a:hover {
  color: #25a56a;
}

.album__title span {
  display: block;
  width: 100%;
  color: silver;
  font-size: 14px;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}

.album__title span a {
  color: silver;
}

.album__title span a:hover {
  color: #25a56a;
}

.album__stat {
  position: absolute;
  z-index: 2;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  bottom: 20px;
  left: 0;
  right: 0;
  color: #fff;
  font-size: 12px;
  transform: scale(0.8);
  opacity: 0;
  transition: 0.5s ease;
}

.album__stat span {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-right: 15px;
}

.album__stat span:last-child {
  margin-right: 0;
}

.album__stat svg {
  width: 14px;
  height: auto;
  fill: #fff;
  margin-right: 5px;
}

@media (min-width: 768px) {
  .album {
    margin-top: 30px;
  }

  .album--sidebar {
    margin-top: 20px;
  }

  .album__stat {
    font-size: 13px;
  }

  .album__stat span {
    margin-right: 20px;
  }

  .album__stat span:last-child {
    margin-right: 0;
  }

  .album__stat svg {
    width: 16px;
  }
}

.release {
  margin-top: 20px;
  border: 1px solid #222227;
  background-color: #16151a;
  padding: 20px;
  border-radius: 12px;
  position: release;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.release__cover {
  display: block;
  position: relative;
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
}

.release__cover img {
  width: 100%;
}

.release__list {
  position: relative;
  width: 100%;
  margin-top: 20px;
}

.release__list ul {
  height: 330px;
  overflow-y: auto;
  padding-right: 14px;
}

.release__list ul::-webkit-scrollbar {
  width: 4px;
  border-radius: 4px;
}

.release__list ul::-webkit-scrollbar-track {
  background: #222227;
  border-radius: 4px;
}

.release__list ul::-webkit-scrollbar-thumb {
  background: #25a56a;
  border-radius: 4px;
}

.release__list ul::-webkit-scrollbar-thumb:hover {
  background: #222227;
  border-radius: 4px;
}

.release__stat {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: silver;
  font-size: 14px;
  transition: 0.5s ease;
  width: 100%;
  margin-top: 15px;
}

.release__stat span {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-right: 20px;
}

.release__stat span:last-child {
  margin-right: 0;
}

.release__stat svg {
  width: 20px;
  height: auto;
  fill: #25a56a;
  margin-right: 5px;
}

.release__buy {
  width: 100%;
  height: 50px;
  border-radius: 12px;
  background-color: #25a56a;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 14px;
  position: relative;
  z-index: 2;
  text-transform: uppercase;
  font-family: Inter, sans-serif;
  font-weight: 500;
  padding: 0 25px;
  margin-top: 15px;
  cursor: pointer;
}

.release__buy svg {
  fill: #fff;
  width: 28px;
  height: auto;
  margin-right: 7px;
}

.release__buy:hover {
  background-color: #222227;
  color: #fff;
}

@media (min-width: 768px) {
  .release {
    flex-direction: row;
    margin-bottom: 10px;
  }

  .release__list {
    margin-top: 0;
    width: calc(100% - 250px);
  }

  .release__content {
    width: 230px;
    margin-right: 20px;
  }
}

@media (min-width: 1200px) {
  .release__list {
    width: calc(100% - 310px);
    height: 340px;
  }

  .release__content {
    width: 290px;
  }
}

.store-item {
  margin-top: 20px;
  border: 1px solid #222227;
  background-color: #16151a;
  padding: 20px;
  border-radius: 12px;
  position: release;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.store-item__cover {
  display: block;
  position: release;
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
}

.store-item__cover img {
  width: 100%;
}

.store-item__description {
  position: relative;
  width: 100%;
  margin-top: 20px;
}

.store-item__buy {
  width: 100%;
  height: 50px;
  border-radius: 12px;
  background-color: #25a56a;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 14px;
  position: relative;
  z-index: 2;
  text-transform: uppercase;
  font-family: Inter, sans-serif;
  font-weight: 500;
  padding: 0 25px;
  margin-top: 15px;
}

.store-item__buy svg {
  fill: #fff;
  width: 28px;
  height: auto;
  margin-right: 7px;
}

.store-item__buy:hover {
  background-color: #222227;
  color: #fff;
}

.store-item__content {
  width: 100%;
  display: block;
}

.store-item__carousel {
  position: relative;
  width: 100%;
}

@media (min-width: 768px) {
  .store-item {
    flex-direction: row;
  }

  .store-item__description {
    margin-top: 0;
    width: calc(100% - 250px);
  }

  .store-item__content {
    width: 230px;
    margin-right: 20px;
  }
}

@media (min-width: 1200px) {
  .store-item__description {
    width: calc(100% - 310px);
  }

  .store-item__content {
    width: 290px;
  }
}

.artist {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 20px;
  width: 100%;
  position: relative;
}

.artist__cover {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  position: relative;
  overflow: hidden;
  width: 100%;
}

.artist__cover:before {
  content: '';
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg,
      rgba(37, 165, 106, 0.8) 0,
      rgba(37, 165, 106, 0) 100%);
  opacity: 0;
  transition: opacity 0.5s ease;
  z-index: 2;
}

.artist__cover img {
  width: 100%;
  display: block;
  position: relative;
  z-index: 1;
}

.genre-item {
  position: relative;
}

.genre-item:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.genre-item img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.artist__title {
  z-index: 3;
  color: #fff;
  font-size: 16px;
  margin-bottom: 0;
  text-align: center;
  width: 100%;
  transition: color 0.5s ease;
  margin-top: 10px;
  font-family: Inter, sans-serif;
  font-weight: 400;
}

.artist:hover .artist__cover:before {
  opacity: 0.3;
}

.artist:hover .artist__title {
  color: #25a56a;
}

@media (min-width: 768px) {
  .artist {
    margin-top: 30px;
  }
}

.event {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  margin-top: 20px;
  width: 100%;
  border-radius: 12px;
  position: relative;
  overflow: hidden;
  padding: 20px;
  height: 300px;
}

.event:before {
  content: '';
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg,
      rgba(0, 0, 0, 0.8) 0,
      rgba(0, 0, 0, 0) 100%);
  opacity: 0.6;
  transition: opacity 0.5s ease;
  z-index: 1;
}

.event__date {
  position: relative;
  z-index: 2;
  font-size: 18px;
  font-weight: 400;
  color: #fff;
}

.event__time {
  position: relative;
  z-index: 2;
  font-size: 14px;
  color: silver;
  font-weight: 400;
  margin-bottom: 20px;
}

.event__title {
  position: relative;
  z-index: 2;
  font-family: Inter, sans-serif;
  font-weight: 400;
  color: #fff;
  font-size: 24px;
  margin-bottom: 5px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-height: 34px;
}

.event__title a {
  color: #fff;
}

.event__title a:hover {
  color: #25a56a;
}

.event__address {
  position: relative;
  z-index: 2;
  font-size: 14px;
  color: #fff;
  line-height: 24px;
  margin-bottom: 0;
  width: 100%;
}

.event__ticket {
  position: absolute;
  top: 20px;
  left: 20px;
  min-width: 130px;
  height: 40px;
  border-radius: 12px;
  background-color: #222227;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 14px;
  z-index: 2;
  font-family: Inter, sans-serif;
  font-weight: 500;
  padding: 0 15px;
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.event__ticket svg {
  width: 18px;
  height: auto;
  margin-right: 7px;
  fill: #25a56a;
  transition: fill 0.5s ease;
}

.event__ticket:hover {
  color: #fff;
  background-color: #25a56a;
  border-color: #25a56a;
}

.event__ticket:hover svg {
  fill: #fff;
}

.event:hover:before {
  opacity: 0.8;
}

@media (min-width: 768px) {
  .event {
    margin-top: 30px;
    height: 320px;
  }

  .event__title {
    font-size: 26px;
  }
}

.single-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #222227;
  height: 58px;
}

.single-item:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: none;
  height: 48px;
}

.single-item__number {
  color: silver;
  font-size: 22px;
  min-width: 20px;
  width: auto;
  font-family: Inter, sans-serif;
  font-weight: 400;
}

.single-item__cover {
  position: relative;
  min-width: 48px;
  max-width: 48px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  overflow: hidden;
  transition: 0.5s ease;
}

.single-item__export:hover {
  background-color: rgba(128, 81, 212, 0.3);
}

.single-item__cover img {
  width: 100%;
  height: 48px;
  opacity: 1;
  transition: opacity 0.5s ease;
  position: relative;
  object-fit: cover;
}

.single-item__cover svg {
  position: absolute;
  opacity: 0;
  fill: #fff;
  width: 24px;
  height: auto;
  margin: 1px 0 0 1px;
  transition: 0.5s ease;
}

.single-item__cover svg:last-child {
  opacity: 0;
}

.single-item__cover:hover svg {
  fill: #25a56a;
}

.single-item__cover.active {
  background-color: #000;
}

.single-item__cover.active img {
  opacity: 0.35;
}

.single-item__cover.active svg {
  fill: #25a56a;
}

.single-item__title {
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 15px;
  margin-right: auto;
  width: calc(100% - 48px);
}

.single-item__title h4 {
  display: block;
  width: 100%;
  color: #fff;
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.single-item__title h4 a {
  color: #fff;
}

.single-item__title h4 a:hover {
  color: #25a56a;
}

.single-item__title span {
  display: block;
  width: 100%;
  color: silver;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}

.single-item__title span a {
  color: silver;
}

.single-item__title span a:hover {
  color: #25a56a;
}

.single-item__time {
  margin-left: 15px;
  font-size: 13px;
  color: silver;
}

.single-item__time--live {
  background-color: #eb5757;
  color: #fff;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 24px;
  padding: 0 12px;
  border-radius: 8px;
  font-size: 12px;
  font-family: Inter, sans-serif;
  font-weight: 400;
}

.single-item__add {
  margin-left: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 32px;
  max-width: 32px;
  height: 32px;
  border-radius: 8px;
  background-color: rgba(37, 165, 106, 0.15);
  cursor: pointer;
}

.single-item__add svg {
  width: 18px;
  height: auto;
  fill: #25a56a;
}

.single-item__add:hover {
  background-color: rgba(37, 165, 106, 0.3);
}

.single-item__export {
  margin-left: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 32px;
  max-width: 32px;
  height: 32px;
  border-radius: 8px;
  background-color: rgba(128, 81, 212, 0.15);
}

@media only screen and (max-width: 600px) {
  .single-item__export {
    display: none;
  }
}

.single-item__export svg {
  width: 18px;
  height: auto;
  fill: #8051d4;
}

.single-item:hover .single-item__cover {
  background-color: #222227;
}

.single-item:hover .single-item__cover img {
  opacity: 0.35;
}

.single-item:hover .single-item__cover svg {
  opacity: 1;
}

.single-item:hover .single-item__cover svg:last-child {
  opacity: 0;
}

.live {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 20px;
  position: relative;
  overflow: hidden;
}

.live__cover {
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.live__cover img {
  position: relative;
  width: 100%;
  z-index: 1;
  transition: 0.5s ease;
}

.live__cover svg {
  position: absolute;
  display: block;
  fill: #fff;
  width: 46px;
  height: auto;
  transition: 0.5s ease;
  z-index: 3;
  transform: scale(0.8);
  opacity: 0;
}

.live__cover:before {
  content: '';
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 2;
  background: linear-gradient(45deg,
      rgba(37, 165, 106, 0.8) 0,
      rgba(37, 165, 106, 0) 100%);
  opacity: 0.1;
  transition: opacity 0.5s ease;
}

.live__value {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  bottom: 20px;
  left: 20px;
  z-index: 3;
  pointer-events: none;
  height: 28px;
  width: auto;
  padding: 0 15px;
  border-radius: 8px;
  background-color: rgba(34, 34, 39, 0.8);
  color: #fff;
  font-size: 12px;
  font-weight: 400;
}

.live__status {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 3;
  pointer-events: none;
  height: 24px;
  width: auto;
  padding: 0 12px;
  border-radius: 8px;
  background-color: #eb5757;
  color: #fff;
  font-size: 12px;
  text-transform: uppercase;
  font-family: Inter, sans-serif;
  font-weight: 400;
}

.live__title {
  font-size: 20px;
  font-family: Inter, sans-serif;
  font-weight: 400;
  color: #fff;
  margin-bottom: 0;
  margin-top: 10px;
  width: 100%;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  word-wrap: break-word;
  transition: color 0.5s ease;
}

.live__title a {
  color: #fff;
}

.live:hover .live__cover:before {
  opacity: 0.3;
}

.live:hover .live__cover svg {
  opacity: 1;
  transform: scale(1);
}

.live:hover .live__title {
  color: #25a56a;
}

.live:hover .live__title a {
  color: #25a56a;
}

@media (min-width: 768px) {
  .live {
    margin-top: 30px;
  }
}

.product {
  position: relative;
  margin-top: 20px;
  border-radius: 12px;
  width: 100%;
  overflow: hidden;
  background-color: #222227;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px;
}

.product__img {
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
}

.product__img:before {
  content: '';
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, #000 0, rgba(0, 0, 0, 0) 100%);
  opacity: 0;
  transition: opacity 0.5s ease;
  z-index: 2;
}

.product__img img {
  width: 100%;
  display: block;
  position: relative;
  z-index: 1;
}

.product__img:hover:before {
  opacity: 0.3;
}

.product__img:hover .product__add {
  transform: scale(1);
  opacity: 1;
}

.product__add {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  top: 50%;
  left: 50%;
  margin: -24px 0 0 -24px;
  border-radius: 12px;
  background-color: #222227;
  transform: scale(0.8);
  opacity: 0;
  transition: 0.5s ease;
  transition-property: opacity, transform;
  z-index: 3;
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.product__add svg {
  fill: #fff;
  width: 24px;
  height: auto;
  transition: fill 0.5s ease;
}

.product__add:hover svg {
  fill: #25a56a;
}

.product__title {
  display: block;
  width: 100%;
  font-size: 17px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  margin-top: 15px;
}

.product__title a {
  color: #fff;
}

.product__title a:hover {
  color: #25a56a;
}

.product__price {
  font-size: 16px;
  color: silver;
  margin-top: 5px;
  display: block;
  width: 100%;
  font-family: Inter, sans-serif;
  font-weight: 400;
}

.product__new {
  font-size: 14px;
  color: #eb5757;
  position: absolute;
  bottom: 20px;
  right: 20px;
  font-weight: 600;
}

@media (min-width: 768px) {
  .product {
    margin-top: 30px;
  }
}

.feature {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  overflow: hidden;
  margin-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #222227;
}

.feature__icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 46px;
  height: 46px;
  border-radius: 12px;
  background-color: rgba(37, 165, 106, 0.15);
}

.feature__icon svg {
  fill: #25a56a;
  width: auto;
  height: 26px;
}

.feature__title {
  font-family: Inter, sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 0;
  margin-left: 15px;
}

.feature__text {
  display: block;
  width: 100%;
  font-size: 16px;
  line-height: 26px;
  color: silver;
  margin-bottom: 0;
  margin-top: 15px;
}

@media (min-width: 768px) {
  .feature {
    margin-top: 30px;
    padding-bottom: 0;
    border-bottom: none;
  }
}

@media (min-width: 1200px) {
  .feature {
    margin-top: 40px;
  }
}

.contacts__list {
  margin-top: 20px;
}

.contacts__list li {
  margin-bottom: 15px;
}

.contacts__list li:last-child {
  margin-bottom: 0;
}

.contacts__list a {
  font-size: 16px;
  color: #fff;
  font-family: Inter, sans-serif;
  font-weight: 400;
}

.contacts__list a:hover {
  color: #25a56a;
}

.contacts__social {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
}

.contacts__social a {
  margin-top: 20px;
  margin-right: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  position: relative;
  transition-property: all;
}

.contacts__social a svg {
  width: 100%;
  height: auto;
}

.contacts__social a:last-child {
  margin-right: 0;
}

.contacts__social a:hover {
  opacity: 1 !important;
  -webkit-filter: grayscale(0) !important;
  filter: grayscale(0) !important;
}

.contacts__social:hover a {
  opacity: 0.7;
  -webkit-filter: grayscale(0.7);
  filter: grayscale(0.7);
}

@media (min-width: 768px) {
  .contacts__social {
    margin-top: 10px;
  }
}

.footer {
  margin-top: 100px;
  background-color: #222227;
  padding: 30px 0 20px;
  position: relative;
  overflow: hidden;
}

@media only screen and (max-width: 600px) {
  .footer {
    margin-top: 0px;
  }
}

.footer__logo {
  margin-top: 60px;
}

.footer__logo img {
  width: 100px;
  height: auto;
}

.footer__tagline {
  margin-top: 15px;
  display: block;
  font-size: 14px;
  line-height: 24px;
  color: silver;
  margin-bottom: 0;
}

.footer__links {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-top: 5px;
}

.footer__links a {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
  color: silver;
  font-size: 16px;
}

.footer__links a svg {
  width: 20px;
  height: auto;
  fill: #25a56a;
  margin-right: 10px;
}

.footer__links a:hover {
  color: #25a56a;
}

.footer__social {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
}

.footer__social a {
  margin-top: 20px;
  margin-right: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  position: relative;
  transition-property: all;
}

.footer__social a svg {
  width: 100%;
  height: auto;
}

.footer__social a:last-child {
  margin-right: 0;
}

.footer__social a:hover {
  opacity: 1 !important;
  -webkit-filter: grayscale(0) !important;
  filter: grayscale(0) !important;
}

.footer__social:hover a {
  opacity: 0.6;
  -webkit-filter: grayscale(0.6);
  filter: grayscale(0.6);
}

.footer__content {
  margin-top: 60px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.footer__copyright {
  display: block;
  font-size: 14px;
  color: silver;
  margin-top: 20px;
}

.footer__copyright a {
  color: silver;
}

.footer__copyright a:hover {
  color: #25a56a;
}

.footer__title {
  display: block;
  margin-top: 30px;
  font-size: 16px;
  color: #fff;
  font-family: Inter, sans-serif;
  font-weight: 500;
  margin-bottom: 15px;
}

.footer__nav {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.footer__nav a {
  font-size: 15px;
  line-height: 24px;
  margin-bottom: 10px;
  color: silver;
}

.footer__nav a:last-child {
  margin-bottom: 0;
}

.footer__nav a:hover {
  color: #25a56a;
}

@media (min-width: 576px) {
  .footer {
    padding: 30px 15px 20px;
  }
}

@media (min-width: 768px) {
  .footer {
    padding: 20px 15px 25px;
  }

  .footer__content {
    margin-top: 70px;
    padding-top: 25px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .footer__logo {
    margin-top: 50px;
  }

  .footer__title {
    margin-top: 50px;
  }

  .footer__social {
    width: auto;
    order: 2;
  }

  .footer__social a {
    margin-top: 0;
  }

  .footer__copyright {
    margin-top: 0;
    order: 1;
  }
}

@media (min-width: 1200px) {
  .footer {
    padding-left: 295px;
    padding-right: 15px;
  }

  .footer__social {
    display: inline-flex;
    width: auto;
  }
}

.sign {
  display: block;
  position: relative;
}

.sign__content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  padding: 20px 0 0;
}

.text-error {
  color: #f60000;
  font-size: 14px;
  font-weight: 400;
  padding-top: 6px;
}

.sign__form {
  background-color: #16151a;
  border-radius: 12px;
  padding: 30px 20px;
  position: relative;
  width: 100%;
  max-width: 420px;
  border: 1px solid #222227;
}

.sign__form--contacts,
.sign__form--profile {
  margin-top: 20px;
  max-width: 100%;
  padding: 20px 20px 5px;
  align-items: flex-start;
}

.sign__form--contacts .sign__btn,
.sign__form--profile .sign__btn {
  margin: 0 0 15px;
}

.sign__form--contacts .row,
.sign__form--profile .row {
  margin-left: -10px;
  margin-right: -10px;
}

.sign__form--contacts .col-12,
.sign__form--profile .col-12 {
  padding-left: 10px;
  padding-right: 10px;
}

.sign__form--profile .sign__btn {
  margin: 5px 0 15px;
}

.sign__logo {
  display: block;
  margin-bottom: 30px;
}

.sign__logo a {
  max-width: 100%;
  width: auto;
}

.sign__logo img {
  width: 150px;
}

.sign__title {
  font-size: 16px;
  font-weight: 500;
  font-family: Inter, sans-serif;
  color: #fff;
  margin-bottom: 20px;
}

.sign__label {
  font-weight: 400;
  font-size: 14px;
  color: silver;
  margin-bottom: 5px;
  padding-left: 20px;
}

.sign__label b {
  font-weight: 600;
  color: #fff;
}

.sign__group {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  flex-wrap: wrap;
  position: relative;
  margin-bottom: 20px;
  width: 100%;
}

.sign__textarea {
  background-color: #222227;
  border: 1px solid transparent;
  border-radius: 12px;
  height: 144px;
  position: relative;
  color: #fff;
  font-size: 16px;
  width: 100%;
  padding: 15px 20px;
  resize: none;
  font-family: 'Inter', sans-serif;
}

.sign__input {
  background-color: #222227;
  border: 1px solid transparent;
  border-radius: 12px;
  height: 46px;
  position: relative;
  color: #fff;
  font-size: 16px;
  width: 100%;
  padding: 0 20px;
  font-family: Inter, sans-serif;
}

.sign__input:focus,
.sign__textarea:focus {
  border-color: #25a56a;
}

.sign__select {
  background-size: 18px auto;
  border: 1px solid transparent;
  border-radius: 12px;
  height: 46px;
  position: relative;
  color: #fff;
  font-size: 16px;
  width: 100%;
  padding: 0 20px;
  cursor: pointer;
  font-family: Inter, sans-serif;
}

.sign__select:focus {
  border-color: #25a56a;
}

.sign__btn {
  width: 100%;
  height: 50px;
  border-radius: 12px;
  background-color: #25a56a;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 14px;
  position: relative;
  z-index: 2;
  text-transform: uppercase;
  font-family: Inter, sans-serif;
  font-weight: 500;
  margin: 15px auto;
}

.sign__btn:hover {
  color: #fff;
  background-color: #222227;
}

.sign__text {
  margin-top: 15px;
  font-size: 14px;
  line-height: 24px;
  color: #fff;
  width: 100%;
  text-align: center;
}

.sign__text:first-child {
  margin-top: 0;
}

.sign__text a {
  position: relative;
  color: #25a56a;
}

.sign__text a:hover {
  color: #25a56a;
  text-decoration: underline;
}

.sign__text--small {
  font-size: 13px;
  line-height: 20px;
  text-align: left;
}

.sign__social {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
  margin-top: 15px;
}

.sign__social a {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 46px;
  width: calc(33% - 10px);
  border-radius: 12px;
  color: #fff;
}

.sign__social a svg {
  height: auto;
  fill: #fff;
  transition: 0.5s ease;
}

.sign__social a:hover {
  background-color: silver;
}

.sign__value {
  font-size: 24px;
  color: #fff;
  display: block;
  font-weight: 500;
  margin-left: 10px;
  font-family: Inter, sans-serif;
}

.sign__radio {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-top: 5px;
}

.sign__radio li {
  position: relative;
  overflow: hidden;
  margin-bottom: 10px;
}

.sign__radio li:last-child {
  margin-bottom: 0;
}

.sign__radio input:checked,
.sign__radio input:not(:checked) {
  position: absolute;
  left: -9999px;
}

.sign__radio label {
  display: block;
  margin: 0;
  position: relative;
  font-weight: 400;
  cursor: pointer;
  font-size: 16px;
  color: silver;
  line-height: 24px;
  padding-left: 32px;
  transition: 0.5s ease;
}

.sign__radio label:before {
  content: '';
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  border: 6px solid #222227;
  background-color: transparent;
  border-radius: 50%;
  left: 0;
  top: 2px;
  transition: 0.5s ease;
}

.sign__radio label:hover {
  color: #fff;
}

.sign__radio input:checked+label {
  color: #fff;
}

.sign__radio input:checked+label:before {
  border-color: #25a56a;
}

@media (min-width: 576px) {
  .sign__form {
    padding: 40px;
  }

  .sign__form--contacts,
  .sign__form--profile {
    padding: 20px 20px 5px;
  }

  .sign__logo {
    margin-bottom: 40px;
    text-align: center;
  }
}

@media (min-width: 768px) {

  .sign__form--contacts,
  .sign__form--profile {
    margin-top: 30px;
  }

  .sign__form--contacts .sign__btn,
  .sign__form--profile .sign__btn {
    margin: 10px 0 15px;
  }

  .sign__content {
    padding: 30px 0 0;
  }
}

@media (min-width: 992px) {
  .sign__form--profile .sign__btn {
    width: 180px;
  }
}

.modal {
  position: relative;
  display: block;
  background-color: #16151a;
  margin: 40px auto;
  width: 100%;
  max-width: 420px;
  padding: 20px;
  border-radius: 12px;
  overflow: hidden;
}

.modal__icon {
  display: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 15px;
  right: 15px;
  pointer-events: none;
  opacity: 0.35;
}

.modal__icon svg {
  width: 70px;
  height: auto;
  fill: silver;
}

.modal__icon--green svg {
  fill: #25a56a;
}

.modal--form {
  padding: 20px 20px 5px;
}

.modal__close {
  position: absolute;
  top: 20px;
  right: 20px;
}

.modal__close svg {
  fill: silver;
  opacity: 0.6;
  transition: 0.5s ease;
  height: auto;
  width: 24px;
}

.modal__close:hover svg {
  opacity: 1;
  fill: #25a56a;
}

@media (min-width: 768px) {
  .modal {
    padding: 40px;
  }

  .modal__icon {
    bottom: 30px;
    right: 30px;
  }

  .modal--form {
    padding: 40px 40px 25px;
  }

  .modal--form .modal__close {
    top: 37px;
    right: 35px;
  }

  .modal--form .sign__btn {
    margin-top: 30px;
  }
}

.main__filter {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  background-color: #16151a;
  position: relative;
}

.slider-radio {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #222227;
  padding: 5px 0;
  border-radius: 12px;
  margin-top: 10px;
  position: relative;
  width: 100%;
  z-index: 2;
}

.slider-radio label {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 30px;
  padding: 0 15px;
  font-size: 14px;
  color: silver;
  margin-bottom: 0;
  cursor: pointer;
  border-radius: 10px;
  margin: 0 5px;
  position: relative;
  z-index: 100;
  transition: color 0.5s;
  font-family: Inter, sans-serif;
  font-weight: 400;
}

.slider-radio label:hover {
  color: #fff;
}

.slider-radio input[type='radio']:checked+label {
  color: #fff;
  background-color: #25a56a;
}

@media (min-width: 768px) {
  .slider-radio {
    margin-left: auto;
    margin-top: 0;
    width: auto;
  }
}

.select2-container {
  width: auto !important;
  margin-right: 30px;
}


@media (max-width: 600px) {
  .header__actions .header__action--signin {
    display: none;
  }

  .sidebar .header__action {
    display: block;
  }

  .header__action-btn span {
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 16px;
    color: silver;
    font-family: Inter, sans-serif;
    margin: 0 10px 0 15px;
    transition: 0.4s ease;
    position: relative;
  }

  .header__action-btn {
    justify-content: flex-start;
  }

  .release {
    display: block;
  }

  .album__stat {
    opacity: 1;
    transform: scale(1);
    bottom: 6px;
  }

  .play-icon {
    width: 40px;
    height: 40px;
    opacity: 1;
    transform: scale(1);
    margin: -20px 0 0 -20px;
    border-radius: 8px;
    opacity: 0.45;
  }

  .single-item__cover img+svg {
    opacity: 1;
  }

  .main__title {
    margin-top: 40px;
  }

  .release {
    padding: 0;
    border: 0px;
  }
}

.infinite-scroll-component {
  overflow: visible !important;
}

.container svg {
  height: unset;
  width: unset;
}

.time_options {
  display: flex;
  justify-content: center;
}

.time_options-item{
  font-size: 30px;
  color: #545454;
  padding: 0 15px;
  cursor: pointer;
}

.time_options-item:active {
  color: #25a56a;
}

.dialog__actions__timer {
  display: flex;
  padding: 32px 0;
  font-size: 32px;
  font-weight: 400;
  justify-content: space-evenly;
}
.container {
  font-family: sans-serif;
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin: 10px;
}

.textContainer {
  position: absolute;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 100px;
  line-height: 90px;
  text-align: center;
  color: #545454;
}

.textContainer .minute {
  font-size: 24px;
  line-height: 20px;
  background: #545454;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.seperate {
  border: 0.5px solid #545454;
}

.timer__button {
  width: 100px !important;
  padding:10px 0 !important;
  
}

.dialog__timer .MuiDialog-paper {
  background: #15161A;
  overflow: hidden;
}

.sleep__timer_button {
  cursor: pointer;
 
}
.music-actions {
  display: flex;
  margin: 10px 0;
  margin-bottom: 32px;
}

.left__action {
  display: flex;
  margin-right:0 !important;
}

.right__action {
  display: flex;
}

.item__action {
  padding-right: 16px;
}

@media only screen and (max-width: 768px) {
  .music-actions {
    display: block !important;
    margin: 8px 0;
  }

  .right__action {
    padding:16px 0;
  }
}

@media only screen and (max-width: 576px) {
  .music-actions {
    display: block !important;
    margin:0 !important;
  }

  .right__action {
    padding: 16px 0;
  }
}

.checkbox--details {
  padding-left: 24px !important;
  display: flex;
}

.title_bottom_nav {
  font-size: 10px;
}

.channel_info {
  display: flex;
  align-items: center;
  padding: 8px 0;
}

.navbar-footer {
  overflow: hidden;
  background-color: #333;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 25;
}

.navbar-footer a {
  float: left;
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 0 16px;
  text-decoration: none;
  font-size: 17px;
}

.navbar-footer a:hover {
 cursor: pointer;
}

.title_bottom_nav {
  font-size: 10px;
}