@import url(https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600&family=Roboto:wght@400;500&display=swap);
.rhap_main {
    position: relative;
    padding-bottom: 24px;
    flex-direction: column-reverse !important;
}

.rhap_container,
.rhap_volume-container {
    background-color: transparent !important;
    border: none !important;
    outline: none !important;
    border-radius: 0px !important;
    padding: 0px !important;
    box-shadow: none !important;
}

.rhap_controls-section {
    margin-bottom: 15px;
}

.rhap_progress-section {
    height: 20px;
    padding-bottom: 20px;
}

.rhap_main-controls-button svg {
    color: #ff5555;
}

.rhap_progress-filled {
    background-color: #25a56a !important;
}

.rhap_progress-indicator {
    background-color: #FFFFFF !important;
    width: 14px !important;
    height: 14px !important;
    top: -5px !important;
}

.rhap_download-progress {
    background-color: #7d8087 !important;
}

.rhap_button-clear.rhap_volume-button {
    color: #ff5555 !important;
}

.rhap_volume-bar {
    background-color: #7d8087 !important;
}

.rhap_volume-indicator {
    background-color: #FFFFFF !important;
}

.rhap_time {
    color: #c0c0c0 !important;
    font-size: 14px !important;
}

.rhap_main-controls-button {
    margin: 0 3px;
    color: #868686;
    font-size: 24px;
    width: 24px;
    height: 24px;
}

.rhap_play-pause-button {
    width: 35px !important;
    height: 35px !important;
    font-size: medium !important;
    font-size: initial !important;
}


.rhap_additional-controls {
    display: none !important;
}

.rhap_controls-section {
    display: block !important;
}

.rhap_main .rhap_volume-controls {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: calc(100% - 30px);
    justify-content: flex-start;
}

.rhap_footer {
    position: relative;
}

@media only screen and (max-width: 600px) {
    .rhap_main .rhap_volume-controls {
        display: none;
    }
}
/*!
 * Bootstrap Grid v4.5.1 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
::after,
::before {
  box-sizing: inherit;
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1140px;
  }
}

.row {
  display: flex;
  flex: 1 0 100%;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-2 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-md-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-md-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-md-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-md-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.d-none {
  display: none !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }
}

/*!
 * Bootstrap Reboot v4.5.1 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 * Forked from Normalize.css, licensed MIT (https://github.com/necolas/normalize.css/blob/master/LICENSE.md)
 */
*,
::after,
::before {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

footer,
header,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex='-1']:focus:not(:focus-visible) {
  outline: 0 !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ul {
  margin-top: 0;
  margin-bottom: 1rem;
}

ul ul {
  margin-bottom: 0;
}

b {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

button,
input,
select {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role='button'] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

[type='button'],
[type='reset'],
[type='submit'],
button {
  -webkit-appearance: button;
}

[type='button']:not(:disabled),
[type='reset']:not(:disabled),
[type='submit']:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}

[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type='checkbox'],
input[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

.control {
    display: block;
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    font-size: 18px;
    margin-bottom: 0;
}

.control input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

[type=checkbox],
[type=radio] {
    box-sizing: border-box;
    padding: 0;
}

.control__indicator {
    position: absolute;
    top: 2px;
    left: 0;
    height: 20px;
    width: 20px;
    background: #e6e6e6;
}

.control input:checked~.control__indicator {
    background: #2aa1c0;
}
.checkbox--details input:checked~.control__indicator {
    background: #25a56a;
}
.checkbox--details .control__indicator {
    height: 16px;
    width: 16px;
}

.control input:checked~.control__indicator:after {
    display: block;
}


.control__indicator:after {
    content: '';
    position: absolute;
    display: none;
}

.checkbox--details .control__indicator:after {
    left: 6px !important;
    top: 0px !important;
    width: 6px !important;
    height: 12px !important;
}


.control--checkbox .control__indicator:after {
    left: 8px;
    top: 4px;
    width: 6px;
    height: 10px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}
.la,
.lab,
.lad,
.lal,
.lar,
.las {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    -webkit-font-feature-settings: normal;
            font-feature-settings: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1
}

.la-lg {
    font-size: 1.33333em;
    line-height: .75em;
    vertical-align: -.0667em
}

.la-xs {
    font-size: .75em
}

.la-sm {
    font-size: .875em
}

.la-1x {
    font-size: 1em
}

.la-2x {
    font-size: 2em
}

.la-3x {
    font-size: 3em
}

.la-4x {
    font-size: 4em
}

.la-5x {
    font-size: 5em
}

.la-6x {
    font-size: 6em
}

.la-7x {
    font-size: 7em
}

.la-8x {
    font-size: 8em
}

.la-9x {
    font-size: 9em
}

.la-10x {
    font-size: 10em
}

.la-fw {
    text-align: center;
    width: 1.25em
}

.la-ul {
    list-style-type: none;
    margin-left: 2.5em;
    padding-left: 0
}

.la-ul>li {
    position: relative
}

.la-li {
    left: -2em;
    position: absolute;
    text-align: center;
    width: 2em;
    line-height: inherit
}

.la-border {
    border: solid .08em #eee;
    border-radius: .1em;
    padding: .2em .25em .15em
}

.la-pull-left {
    float: left
}

.la-pull-right {
    float: right
}

.la.la-pull-left,
.lab.la-pull-left,
.lal.la-pull-left,
.lar.la-pull-left,
.las.la-pull-left {
    margin-right: .3em
}

.la.la-pull-right,
.lab.la-pull-right,
.lal.la-pull-right,
.lar.la-pull-right,
.las.la-pull-right {
    margin-left: .3em
}

.la-spin {
    -webkit-animation: la-spin 2s infinite linear;
    animation: la-spin 2s infinite linear
}

.la-pulse {
    -webkit-animation: la-spin 1s infinite steps(8);
    animation: la-spin 1s infinite steps(8)
}

@-webkit-keyframes la-spin {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes la-spin {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.la-rotate-90 {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg)
}

.la-rotate-180 {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
}

.la-rotate-270 {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg)
}

.la-flip-horizontal {
    -webkit-transform: scale(-1, 1);
    transform: scale(-1, 1)
}

.la-flip-vertical {
    -webkit-transform: scale(1, -1);
    transform: scale(1, -1)
}

.la-flip-both,
.la-flip-horizontal.la-flip-vertical {
    -webkit-transform: scale(-1, -1);
    transform: scale(-1, -1)
}

:root .la-flip-both,
:root .la-flip-horizontal,
:root .la-flip-vertical,
:root .la-rotate-180,
:root .la-rotate-270,
:root .la-rotate-90 {
    -webkit-filter: none;
    filter: none
}

.la-stack {
    display: inline-block;
    height: 2em;
    line-height: 2em;
    position: relative;
    vertical-align: middle;
    width: 2.5em
}

.la-stack-1x,
.la-stack-2x {
    left: 0;
    position: absolute;
    text-align: center;
    width: 100%
}

.la-stack-1x {
    line-height: inherit
}

.la-stack-2x {
    font-size: 2em
}

.la-inverse {
    color: #fff
}

.la-500px:before {
    content: "\f26e"
}

.la-accessible-icon:before {
    content: "\f368"
}

.la-accusoft:before {
    content: "\f369"
}

.la-acquisitions-incorporated:before {
    content: "\f6af"
}

.la-ad:before {
    content: "\f641"
}

.la-address-book:before {
    content: "\f2b9"
}

.la-address-card:before {
    content: "\f2bb"
}

.la-adjust:before {
    content: "\f042"
}

.la-adn:before {
    content: "\f170"
}

.la-adobe:before {
    content: "\f778"
}

.la-adversal:before {
    content: "\f36a"
}

.la-affiliatetheme:before {
    content: "\f36b"
}

.la-air-freshener:before {
    content: "\f5d0"
}

.la-airbnb:before {
    content: "\f834"
}

.la-algolia:before {
    content: "\f36c"
}

.la-align-center:before {
    content: "\f037"
}

.la-align-justify:before {
    content: "\f039"
}

.la-align-left:before {
    content: "\f036"
}

.la-align-right:before {
    content: "\f038"
}

.la-alipay:before {
    content: "\f642"
}

.la-allergies:before {
    content: "\f461"
}

.la-amazon:before {
    content: "\f270"
}

.la-amazon-pay:before {
    content: "\f42c"
}

.la-ambulance:before {
    content: "\f0f9"
}

.la-american-sign-language-interpreting:before {
    content: "\f2a3"
}

.la-amilia:before {
    content: "\f36d"
}

.la-anchor:before {
    content: "\f13d"
}

.la-android:before {
    content: "\f17b"
}

.la-angellist:before {
    content: "\f209"
}

.la-angle-double-down:before {
    content: "\f103"
}

.la-angle-double-left:before {
    content: "\f100"
}

.la-angle-double-right:before {
    content: "\f101"
}

.la-angle-double-up:before {
    content: "\f102"
}

.la-angle-down:before {
    content: "\f107"
}

.la-angle-left:before {
    content: "\f104"
}

.la-angle-right:before {
    content: "\f105"
}

.la-angle-up:before {
    content: "\f106"
}

.la-angry:before {
    content: "\f556"
}

.la-angrycreative:before {
    content: "\f36e"
}

.la-angular:before {
    content: "\f420"
}

.la-ankh:before {
    content: "\f644"
}

.la-app-store:before {
    content: "\f36f"
}

.la-app-store-ios:before {
    content: "\f370"
}

.la-apper:before {
    content: "\f371"
}

.la-apple:before {
    content: "\f179"
}

.la-apple-alt:before {
    content: "\f5d1"
}

.la-apple-pay:before {
    content: "\f415"
}

.la-archive:before {
    content: "\f187"
}

.la-archway:before {
    content: "\f557"
}

.la-arrow-alt-circle-down:before {
    content: "\f358"
}

.la-arrow-alt-circle-left:before {
    content: "\f359"
}

.la-arrow-alt-circle-right:before {
    content: "\f35a"
}

.la-arrow-alt-circle-up:before {
    content: "\f35b"
}

.la-arrow-circle-down:before {
    content: "\f0ab"
}

.la-arrow-circle-left:before {
    content: "\f0a8"
}

.la-arrow-circle-right:before {
    content: "\f0a9"
}

.la-arrow-circle-up:before {
    content: "\f0aa"
}

.la-arrow-down:before {
    content: "\f063"
}

.la-arrow-left:before {
    content: "\f060"
}

.la-arrow-right:before {
    content: "\f061"
}

.la-arrow-up:before {
    content: "\f062"
}

.la-arrows-alt:before {
    content: "\f0b2"
}

.la-arrows-alt-h:before {
    content: "\f337"
}

.la-arrows-alt-v:before {
    content: "\f338"
}

.la-artstation:before {
    content: "\f77a"
}

.la-assistive-listening-systems:before {
    content: "\f2a2"
}

.la-asterisk:before {
    content: "\f069"
}

.la-asymmetrik:before {
    content: "\f372"
}

.la-at:before {
    content: "\f1fa"
}

.la-atlas:before {
    content: "\f558"
}

.la-atlassian:before {
    content: "\f77b"
}

.la-atom:before {
    content: "\f5d2"
}

.la-audible:before {
    content: "\f373"
}

.la-audio-description:before {
    content: "\f29e"
}

.la-autoprefixer:before {
    content: "\f41c"
}

.la-avianex:before {
    content: "\f374"
}

.la-aviato:before {
    content: "\f421"
}

.la-award:before {
    content: "\f559"
}

.la-aws:before {
    content: "\f375"
}

.la-baby:before {
    content: "\f77c"
}

.la-baby-carriage:before {
    content: "\f77d"
}

.la-backspace:before {
    content: "\f55a"
}

.la-backward:before {
    content: "\f04a"
}

.la-bacon:before {
    content: "\f7e5"
}

.la-balance-scale:before {
    content: "\f24e"
}

.la-balance-scale-left:before {
    content: "\f515"
}

.la-balance-scale-right:before {
    content: "\f516"
}

.la-ban:before {
    content: "\f05e"
}

.la-band-aid:before {
    content: "\f462"
}

.la-bandcamp:before {
    content: "\f2d5"
}

.la-barcode:before {
    content: "\f02a"
}

.la-bars:before {
    content: "\f0c9"
}

.la-baseball-ball:before {
    content: "\f433"
}

.la-basketball-ball:before {
    content: "\f434"
}

.la-bath:before {
    content: "\f2cd"
}

.la-battery-empty:before {
    content: "\f244"
}

.la-battery-full:before {
    content: "\f240"
}

.la-battery-half:before {
    content: "\f242"
}

.la-battery-quarter:before {
    content: "\f243"
}

.la-battery-three-quarters:before {
    content: "\f241"
}

.la-battle-net:before {
    content: "\f835"
}

.la-bed:before {
    content: "\f236"
}

.la-beer:before {
    content: "\f0fc"
}

.la-behance:before {
    content: "\f1b4"
}

.la-behance-square:before {
    content: "\f1b5"
}

.la-bell:before {
    content: "\f0f3"
}

.la-bell-slash:before {
    content: "\f1f6"
}

.la-bezier-curve:before {
    content: "\f55b"
}

.la-bible:before {
    content: "\f647"
}

.la-bicycle:before {
    content: "\f206"
}

.la-biking:before {
    content: "\f84a"
}

.la-bimobject:before {
    content: "\f378"
}

.la-binoculars:before {
    content: "\f1e5"
}

.la-biohazard:before {
    content: "\f780"
}

.la-birthday-cake:before {
    content: "\f1fd"
}

.la-bitbucket:before {
    content: "\f171"
}

.la-bitcoin:before {
    content: "\f379"
}

.la-bity:before {
    content: "\f37a"
}

.la-black-tie:before {
    content: "\f27e"
}

.la-blackberry:before {
    content: "\f37b"
}

.la-blender:before {
    content: "\f517"
}

.la-blender-phone:before {
    content: "\f6b6"
}

.la-blind:before {
    content: "\f29d"
}

.la-blog:before {
    content: "\f781"
}

.la-blogger:before {
    content: "\f37c"
}

.la-blogger-b:before {
    content: "\f37d"
}

.la-bluetooth:before {
    content: "\f293"
}

.la-bluetooth-b:before {
    content: "\f294"
}

.la-bold:before {
    content: "\f032"
}

.la-bolt:before {
    content: "\f0e7"
}

.la-bomb:before {
    content: "\f1e2"
}

.la-bone:before {
    content: "\f5d7"
}

.la-bong:before {
    content: "\f55c"
}

.la-book:before {
    content: "\f02d"
}

.la-book-dead:before {
    content: "\f6b7"
}

.la-book-medical:before {
    content: "\f7e6"
}

.la-book-open:before {
    content: "\f518"
}

.la-book-reader:before {
    content: "\f5da"
}

.la-bookmark:before {
    content: "\f02e"
}

.la-bootstrap:before {
    content: "\f836"
}

.la-border-all:before {
    content: "\f84c"
}

.la-border-none:before {
    content: "\f850"
}

.la-border-style:before {
    content: "\f853"
}

.la-bowling-ball:before {
    content: "\f436"
}

.la-box:before {
    content: "\f466"
}

.la-box-open:before {
    content: "\f49e"
}

.la-boxes:before {
    content: "\f468"
}

.la-braille:before {
    content: "\f2a1"
}

.la-brain:before {
    content: "\f5dc"
}

.la-bread-slice:before {
    content: "\f7ec"
}

.la-briefcase:before {
    content: "\f0b1"
}

.la-briefcase-medical:before {
    content: "\f469"
}

.la-broadcast-tower:before {
    content: "\f519"
}

.la-broom:before {
    content: "\f51a"
}

.la-brush:before {
    content: "\f55d"
}

.la-btc:before {
    content: "\f15a"
}

.la-buffer:before {
    content: "\f837"
}

.la-bug:before {
    content: "\f188"
}

.la-building:before {
    content: "\f1ad"
}

.la-bullhorn:before {
    content: "\f0a1"
}

.la-bullseye:before {
    content: "\f140"
}

.la-burn:before {
    content: "\f46a"
}

.la-buromobelexperte:before {
    content: "\f37f"
}

.la-bus:before {
    content: "\f207"
}

.la-bus-alt:before {
    content: "\f55e"
}

.la-business-time:before {
    content: "\f64a"
}

.la-buy-n-large:before {
    content: "\f8a6"
}

.la-buysellads:before {
    content: "\f20d"
}

.la-calculator:before {
    content: "\f1ec"
}

.la-calendar:before {
    content: "\f133"
}

.la-calendar-alt:before {
    content: "\f073"
}

.la-calendar-check:before {
    content: "\f274"
}

.la-calendar-day:before {
    content: "\f783"
}

.la-calendar-minus:before {
    content: "\f272"
}

.la-calendar-plus:before {
    content: "\f271"
}

.la-calendar-times:before {
    content: "\f273"
}

.la-calendar-week:before {
    content: "\f784"
}

.la-camera:before {
    content: "\f030"
}

.la-camera-retro:before {
    content: "\f083"
}

.la-campground:before {
    content: "\f6bb"
}

.la-canadian-maple-leaf:before {
    content: "\f785"
}

.la-candy-cane:before {
    content: "\f786"
}

.la-cannabis:before {
    content: "\f55f"
}

.la-capsules:before {
    content: "\f46b"
}

.la-car:before {
    content: "\f1b9"
}

.la-car-alt:before {
    content: "\f5de"
}

.la-car-battery:before {
    content: "\f5df"
}

.la-car-crash:before {
    content: "\f5e1"
}

.la-car-side:before {
    content: "\f5e4"
}

.la-caret-down:before {
    content: "\f0d7"
}

.la-caret-left:before {
    content: "\f0d9"
}

.la-caret-right:before {
    content: "\f0da"
}

.la-caret-square-down:before {
    content: "\f150"
}

.la-caret-square-left:before {
    content: "\f191"
}

.la-caret-square-right:before {
    content: "\f152"
}

.la-caret-square-up:before {
    content: "\f151"
}

.la-caret-up:before {
    content: "\f0d8"
}

.la-carrot:before {
    content: "\f787"
}

.la-cart-arrow-down:before {
    content: "\f218"
}

.la-cart-plus:before {
    content: "\f217"
}

.la-cash-register:before {
    content: "\f788"
}

.la-cat:before {
    content: "\f6be"
}

.la-cc-amazon-pay:before {
    content: "\f42d"
}

.la-cc-amex:before {
    content: "\f1f3"
}

.la-cc-apple-pay:before {
    content: "\f416"
}

.la-cc-diners-club:before {
    content: "\f24c"
}

.la-cc-discover:before {
    content: "\f1f2"
}

.la-cc-jcb:before {
    content: "\f24b"
}

.la-cc-mastercard:before {
    content: "\f1f1"
}

.la-cc-paypal:before {
    content: "\f1f4"
}

.la-cc-stripe:before {
    content: "\f1f5"
}

.la-cc-visa:before {
    content: "\f1f0"
}

.la-centercode:before {
    content: "\f380"
}

.la-centos:before {
    content: "\f789"
}

.la-certificate:before {
    content: "\f0a3"
}

.la-chair:before {
    content: "\f6c0"
}

.la-chalkboard:before {
    content: "\f51b"
}

.la-chalkboard-teacher:before {
    content: "\f51c"
}

.la-charging-station:before {
    content: "\f5e7"
}

.la-chart-area:before {
    content: "\f1fe"
}

.la-chart-bar:before {
    content: "\f080"
}

.la-chart-line:before {
    content: "\f201"
}

.la-chart-pie:before {
    content: "\f200"
}

.la-check:before {
    content: "\f00c"
}

.la-check-circle:before {
    content: "\f058"
}

.la-check-double:before {
    content: "\f560"
}

.la-check-square:before {
    content: "\f14a"
}

.la-cheese:before {
    content: "\f7ef"
}

.la-chess:before {
    content: "\f439"
}

.la-chess-bishop:before {
    content: "\f43a"
}

.la-chess-board:before {
    content: "\f43c"
}

.la-chess-king:before {
    content: "\f43f"
}

.la-chess-knight:before {
    content: "\f441"
}

.la-chess-pawn:before {
    content: "\f443"
}

.la-chess-queen:before {
    content: "\f445"
}

.la-chess-rook:before {
    content: "\f447"
}

.la-chevron-circle-down:before {
    content: "\f13a"
}

.la-chevron-circle-left:before {
    content: "\f137"
}

.la-chevron-circle-right:before {
    content: "\f138"
}

.la-chevron-circle-up:before {
    content: "\f139"
}

.la-chevron-down:before {
    content: "\f078"
}

.la-chevron-left:before {
    content: "\f053"
}

.la-chevron-right:before {
    content: "\f054"
}

.la-chevron-up:before {
    content: "\f077"
}

.la-child:before {
    content: "\f1ae"
}

.la-chrome:before {
    content: "\f268"
}

.la-chromecast:before {
    content: "\f838"
}

.la-church:before {
    content: "\f51d"
}

.la-circle:before {
    content: "\f111"
}

.la-circle-notch:before {
    content: "\f1ce"
}

.la-city:before {
    content: "\f64f"
}

.la-clinic-medical:before {
    content: "\f7f2"
}

.la-clipboard:before {
    content: "\f328"
}

.la-clipboard-check:before {
    content: "\f46c"
}

.la-clipboard-list:before {
    content: "\f46d"
}

.la-clock:before {
    content: "\f017"
}

.la-clone:before {
    content: "\f24d"
}

.la-closed-captioning:before {
    content: "\f20a"
}

.la-cloud:before {
    content: "\f0c2"
}

.la-cloud-download-alt:before {
    content: "\f381"
}

.la-cloud-meatball:before {
    content: "\f73b"
}

.la-cloud-moon:before {
    content: "\f6c3"
}

.la-cloud-moon-rain:before {
    content: "\f73c"
}

.la-cloud-rain:before {
    content: "\f73d"
}

.la-cloud-showers-heavy:before {
    content: "\f740"
}

.la-cloud-sun:before {
    content: "\f6c4"
}

.la-cloud-sun-rain:before {
    content: "\f743"
}

.la-cloud-upload-alt:before {
    content: "\f382"
}

.la-cloudscale:before {
    content: "\f383"
}

.la-cloudsmith:before {
    content: "\f384"
}

.la-cloudversify:before {
    content: "\f385"
}

.la-cocktail:before {
    content: "\f561"
}

.la-code:before {
    content: "\f121"
}

.la-code-branch:before {
    content: "\f126"
}

.la-codepen:before {
    content: "\f1cb"
}

.la-codiepie:before {
    content: "\f284"
}

.la-coffee:before {
    content: "\f0f4"
}

.la-cog:before {
    content: "\f013"
}

.la-cogs:before {
    content: "\f085"
}

.la-coins:before {
    content: "\f51e"
}

.la-columns:before {
    content: "\f0db"
}

.la-comment:before {
    content: "\f075"
}

.la-comment-alt:before {
    content: "\f27a"
}

.la-comment-dollar:before {
    content: "\f651"
}

.la-comment-dots:before {
    content: "\f4ad"
}

.la-comment-medical:before {
    content: "\f7f5"
}

.la-comment-slash:before {
    content: "\f4b3"
}

.la-comments:before {
    content: "\f086"
}

.la-comments-dollar:before {
    content: "\f653"
}

.la-compact-disc:before {
    content: "\f51f"
}

.la-compass:before {
    content: "\f14e"
}

.la-compress:before {
    content: "\f066"
}

.la-compress-arrows-alt:before {
    content: "\f78c"
}

.la-concierge-bell:before {
    content: "\f562"
}

.la-confluence:before {
    content: "\f78d"
}

.la-connectdevelop:before {
    content: "\f20e"
}

.la-contao:before {
    content: "\f26d"
}

.la-cookie:before {
    content: "\f563"
}

.la-cookie-bite:before {
    content: "\f564"
}

.la-copy:before {
    content: "\f0c5"
}

.la-copyright:before {
    content: "\f1f9"
}

.la-cotton-bureau:before {
    content: "\f89e"
}

.la-couch:before {
    content: "\f4b8"
}

.la-cpanel:before {
    content: "\f388"
}

.la-creative-commons:before {
    content: "\f25e"
}

.la-creative-commons-by:before {
    content: "\f4e7"
}

.la-creative-commons-nc:before {
    content: "\f4e8"
}

.la-creative-commons-nc-eu:before {
    content: "\f4e9"
}

.la-creative-commons-nc-jp:before {
    content: "\f4ea"
}

.la-creative-commons-nd:before {
    content: "\f4eb"
}

.la-creative-commons-pd:before {
    content: "\f4ec"
}

.la-creative-commons-pd-alt:before {
    content: "\f4ed"
}

.la-creative-commons-remix:before {
    content: "\f4ee"
}

.la-creative-commons-sa:before {
    content: "\f4ef"
}

.la-creative-commons-sampling:before {
    content: "\f4f0"
}

.la-creative-commons-sampling-plus:before {
    content: "\f4f1"
}

.la-creative-commons-share:before {
    content: "\f4f2"
}

.la-creative-commons-zero:before {
    content: "\f4f3"
}

.la-credit-card:before {
    content: "\f09d"
}

.la-critical-role:before {
    content: "\f6c9"
}

.la-crop:before {
    content: "\f125"
}

.la-crop-alt:before {
    content: "\f565"
}

.la-cross:before {
    content: "\f654"
}

.la-crosshairs:before {
    content: "\f05b"
}

.la-crow:before {
    content: "\f520"
}

.la-crown:before {
    content: "\f521"
}

.la-crutch:before {
    content: "\f7f7"
}

.la-css3:before {
    content: "\f13c"
}

.la-css3-alt:before {
    content: "\f38b"
}

.la-cube:before {
    content: "\f1b2"
}

.la-cubes:before {
    content: "\f1b3"
}

.la-cut:before {
    content: "\f0c4"
}

.la-cuttlefish:before {
    content: "\f38c"
}

.la-d-and-d:before {
    content: "\f38d"
}

.la-d-and-d-beyond:before {
    content: "\f6ca"
}

.la-dashcube:before {
    content: "\f210"
}

.la-database:before {
    content: "\f1c0"
}

.la-deaf:before {
    content: "\f2a4"
}

.la-delicious:before {
    content: "\f1a5"
}

.la-democrat:before {
    content: "\f747"
}

.la-deploydog:before {
    content: "\f38e"
}

.la-deskpro:before {
    content: "\f38f"
}

.la-desktop:before {
    content: "\f108"
}

.la-dev:before {
    content: "\f6cc"
}

.la-deviantart:before {
    content: "\f1bd"
}

.la-dharmachakra:before {
    content: "\f655"
}

.la-dhl:before {
    content: "\f790"
}

.la-diagnoses:before {
    content: "\f470"
}

.la-diaspora:before {
    content: "\f791"
}

.la-dice:before {
    content: "\f522"
}

.la-dice-d20:before {
    content: "\f6cf"
}

.la-dice-d6:before {
    content: "\f6d1"
}

.la-dice-five:before {
    content: "\f523"
}

.la-dice-four:before {
    content: "\f524"
}

.la-dice-one:before {
    content: "\f525"
}

.la-dice-six:before {
    content: "\f526"
}

.la-dice-three:before {
    content: "\f527"
}

.la-dice-two:before {
    content: "\f528"
}

.la-digg:before {
    content: "\f1a6"
}

.la-digital-ocean:before {
    content: "\f391"
}

.la-digital-tachograph:before {
    content: "\f566"
}

.la-directions:before {
    content: "\f5eb"
}

.la-discord:before {
    content: "\f392"
}

.la-discourse:before {
    content: "\f393"
}

.la-divide:before {
    content: "\f529"
}

.la-dizzy:before {
    content: "\f567"
}

.la-dna:before {
    content: "\f471"
}

.la-dochub:before {
    content: "\f394"
}

.la-docker:before {
    content: "\f395"
}

.la-dog:before {
    content: "\f6d3"
}

.la-dollar-sign:before {
    content: "\f155"
}

.la-dolly:before {
    content: "\f472"
}

.la-dolly-flatbed:before {
    content: "\f474"
}

.la-donate:before {
    content: "\f4b9"
}

.la-door-closed:before {
    content: "\f52a"
}

.la-door-open:before {
    content: "\f52b"
}

.la-dot-circle:before {
    content: "\f192"
}

.la-dove:before {
    content: "\f4ba"
}

.la-download:before {
    content: "\f019"
}

.la-draft2digital:before {
    content: "\f396"
}

.la-drafting-compass:before {
    content: "\f568"
}

.la-dragon:before {
    content: "\f6d5"
}

.la-draw-polygon:before {
    content: "\f5ee"
}

.la-dribbble:before {
    content: "\f17d"
}

.la-dribbble-square:before {
    content: "\f397"
}

.la-dropbox:before {
    content: "\f16b"
}

.la-drum:before {
    content: "\f569"
}

.la-drum-steelpan:before {
    content: "\f56a"
}

.la-drumstick-bite:before {
    content: "\f6d7"
}

.la-drupal:before {
    content: "\f1a9"
}

.la-dumbbell:before {
    content: "\f44b"
}

.la-dumpster:before {
    content: "\f793"
}

.la-dumpster-fire:before {
    content: "\f794"
}

.la-dungeon:before {
    content: "\f6d9"
}

.la-dyalog:before {
    content: "\f399"
}

.la-earlybirds:before {
    content: "\f39a"
}

.la-ebay:before {
    content: "\f4f4"
}

.la-edge:before {
    content: "\f282"
}

.la-edit:before {
    content: "\f044"
}

.la-egg:before {
    content: "\f7fb"
}

.la-eject:before {
    content: "\f052"
}

.la-elementor:before {
    content: "\f430"
}

.la-ellipsis-h:before {
    content: "\f141"
}

.la-ellipsis-v:before {
    content: "\f142"
}

.la-ello:before {
    content: "\f5f1"
}

.la-ember:before {
    content: "\f423"
}

.la-empire:before {
    content: "\f1d1"
}

.la-envelope:before {
    content: "\f0e0"
}

.la-envelope-open:before {
    content: "\f2b6"
}

.la-envelope-open-text:before {
    content: "\f658"
}

.la-envelope-square:before {
    content: "\f199"
}

.la-envira:before {
    content: "\f299"
}

.la-equals:before {
    content: "\f52c"
}

.la-eraser:before {
    content: "\f12d"
}

.la-erlang:before {
    content: "\f39d"
}

.la-ethereum:before {
    content: "\f42e"
}

.la-ethernet:before {
    content: "\f796"
}

.la-etsy:before {
    content: "\f2d7"
}

.la-euro-sign:before {
    content: "\f153"
}

.la-evernote:before {
    content: "\f839"
}

.la-exchange-alt:before {
    content: "\f362"
}

.la-exclamation:before {
    content: "\f12a"
}

.la-exclamation-circle:before {
    content: "\f06a"
}

.la-exclamation-triangle:before {
    content: "\f071"
}

.la-expand:before {
    content: "\f065"
}

.la-expand-arrows-alt:before {
    content: "\f31e"
}

.la-expeditedssl:before {
    content: "\f23e"
}

.la-external-link-alt:before {
    content: "\f35d"
}

.la-external-link-square-alt:before {
    content: "\f360"
}

.la-eye:before {
    content: "\f06e"
}

.la-eye-dropper:before {
    content: "\f1fb"
}

.la-eye-slash:before {
    content: "\f070"
}

.la-facebook:before {
    content: "\f09a"
}

.la-facebook-f:before {
    content: "\f39e"
}

.la-facebook-messenger:before {
    content: "\f39f"
}

.la-facebook-square:before {
    content: "\f082"
}

.la-fan:before {
    content: "\f863"
}

.la-fantasy-flight-games:before {
    content: "\f6dc"
}

.la-fast-backward:before {
    content: "\f049"
}

.la-fast-forward:before {
    content: "\f050"
}

.la-fax:before {
    content: "\f1ac"
}

.la-feather:before {
    content: "\f52d"
}

.la-feather-alt:before {
    content: "\f56b"
}

.la-fedex:before {
    content: "\f797"
}

.la-fedora:before {
    content: "\f798"
}

.la-female:before {
    content: "\f182"
}

.la-fighter-jet:before {
    content: "\f0fb"
}

.la-figma:before {
    content: "\f799"
}

.la-file:before {
    content: "\f15b"
}

.la-file-alt:before {
    content: "\f15c"
}

.la-file-archive:before {
    content: "\f1c6"
}

.la-file-audio:before {
    content: "\f1c7"
}

.la-file-code:before {
    content: "\f1c9"
}

.la-file-contract:before {
    content: "\f56c"
}

.la-file-csv:before {
    content: "\f6dd"
}

.la-file-download:before {
    content: "\f56d"
}

.la-file-excel:before {
    content: "\f1c3"
}

.la-file-export:before {
    content: "\f56e"
}

.la-file-image:before {
    content: "\f1c5"
}

.la-file-import:before {
    content: "\f56f"
}

.la-file-invoice:before {
    content: "\f570"
}

.la-file-invoice-dollar:before {
    content: "\f571"
}

.la-file-medical:before {
    content: "\f477"
}

.la-file-medical-alt:before {
    content: "\f478"
}

.la-file-pdf:before {
    content: "\f1c1"
}

.la-file-powerpoint:before {
    content: "\f1c4"
}

.la-file-prescription:before {
    content: "\f572"
}

.la-file-signature:before {
    content: "\f573"
}

.la-file-upload:before {
    content: "\f574"
}

.la-file-video:before {
    content: "\f1c8"
}

.la-file-word:before {
    content: "\f1c2"
}

.la-fill:before {
    content: "\f575"
}

.la-fill-drip:before {
    content: "\f576"
}

.la-film:before {
    content: "\f008"
}

.la-filter:before {
    content: "\f0b0"
}

.la-fingerprint:before {
    content: "\f577"
}

.la-fire:before {
    content: "\f06d"
}

.la-fire-alt:before {
    content: "\f7e4"
}

.la-fire-extinguisher:before {
    content: "\f134"
}

.la-firefox:before {
    content: "\f269"
}

.la-first-aid:before {
    content: "\f479"
}

.la-first-order:before {
    content: "\f2b0"
}

.la-first-order-alt:before {
    content: "\f50a"
}

.la-firstdraft:before {
    content: "\f3a1"
}

.la-fish:before {
    content: "\f578"
}

.la-fist-raised:before {
    content: "\f6de"
}

.la-flag:before {
    content: "\f024"
}

.la-flag-checkered:before {
    content: "\f11e"
}

.la-flag-usa:before {
    content: "\f74d"
}

.la-flask:before {
    content: "\f0c3"
}

.la-flickr:before {
    content: "\f16e"
}

.la-flipboard:before {
    content: "\f44d"
}

.la-flushed:before {
    content: "\f579"
}

.la-fly:before {
    content: "\f417"
}

.la-folder:before {
    content: "\f07b"
}

.la-folder-minus:before {
    content: "\f65d"
}

.la-folder-open:before {
    content: "\f07c"
}

.la-folder-plus:before {
    content: "\f65e"
}

.la-font:before {
    content: "\f031"
}

.la-font-awesome:before {
    content: "\f2b4"
}

.la-font-awesome-alt:before {
    content: "\f35c"
}

.la-font-awesome-flag:before {
    content: "\f425"
}

.la-font-awesome-logo-full:before {
    content: "\f4e6"
}

.la-fonticons:before {
    content: "\f280"
}

.la-fonticons-fi:before {
    content: "\f3a2"
}

.la-football-ball:before {
    content: "\f44e"
}

.la-fort-awesome:before {
    content: "\f286"
}

.la-fort-awesome-alt:before {
    content: "\f3a3"
}

.la-forumbee:before {
    content: "\f211"
}

.la-forward:before {
    content: "\f04e"
}

.la-foursquare:before {
    content: "\f180"
}

.la-free-code-camp:before {
    content: "\f2c5"
}

.la-freebsd:before {
    content: "\f3a4"
}

.la-frog:before {
    content: "\f52e"
}

.la-frown:before {
    content: "\f119"
}

.la-frown-open:before {
    content: "\f57a"
}

.la-fulcrum:before {
    content: "\f50b"
}

.la-funnel-dollar:before {
    content: "\f662"
}

.la-futbol:before {
    content: "\f1e3"
}

.la-galactic-republic:before {
    content: "\f50c"
}

.la-galactic-senate:before {
    content: "\f50d"
}

.la-gamepad:before {
    content: "\f11b"
}

.la-gas-pump:before {
    content: "\f52f"
}

.la-gavel:before {
    content: "\f0e3"
}

.la-gem:before {
    content: "\f3a5"
}

.la-genderless:before {
    content: "\f22d"
}

.la-get-pocket:before {
    content: "\f265"
}

.la-gg:before {
    content: "\f260"
}

.la-gg-circle:before {
    content: "\f261"
}

.la-ghost:before {
    content: "\f6e2"
}

.la-gift:before {
    content: "\f06b"
}

.la-gifts:before {
    content: "\f79c"
}

.la-git:before {
    content: "\f1d3"
}

.la-git-alt:before {
    content: "\f841"
}

.la-git-square:before {
    content: "\f1d2"
}

.la-github:before {
    content: "\f09b"
}

.la-github-alt:before {
    content: "\f113"
}

.la-github-square:before {
    content: "\f092"
}

.la-gitkraken:before {
    content: "\f3a6"
}

.la-gitlab:before {
    content: "\f296"
}

.la-gitter:before {
    content: "\f426"
}

.la-glass-cheers:before {
    content: "\f79f"
}

.la-glass-martini:before {
    content: "\f000"
}

.la-glass-martini-alt:before {
    content: "\f57b"
}

.la-glass-whiskey:before {
    content: "\f7a0"
}

.la-glasses:before {
    content: "\f530"
}

.la-glide:before {
    content: "\f2a5"
}

.la-glide-g:before {
    content: "\f2a6"
}

.la-globe:before {
    content: "\f0ac"
}

.la-globe-africa:before {
    content: "\f57c"
}

.la-globe-americas:before {
    content: "\f57d"
}

.la-globe-asia:before {
    content: "\f57e"
}

.la-globe-europe:before {
    content: "\f7a2"
}

.la-gofore:before {
    content: "\f3a7"
}

.la-golf-ball:before {
    content: "\f450"
}

.la-goodreads:before {
    content: "\f3a8"
}

.la-goodreads-g:before {
    content: "\f3a9"
}

.la-google:before {
    content: "\f1a0"
}

.la-google-drive:before {
    content: "\f3aa"
}

.la-google-play:before {
    content: "\f3ab"
}

.la-google-plus:before {
    content: "\f2b3"
}

.la-google-plus-g:before {
    content: "\f0d5"
}

.la-google-plus-square:before {
    content: "\f0d4"
}

.la-google-wallet:before {
    content: "\f1ee"
}

.la-gopuram:before {
    content: "\f664"
}

.la-graduation-cap:before {
    content: "\f19d"
}

.la-gratipay:before {
    content: "\f184"
}

.la-grav:before {
    content: "\f2d6"
}

.la-greater-than:before {
    content: "\f531"
}

.la-greater-than-equal:before {
    content: "\f532"
}

.la-grimace:before {
    content: "\f57f"
}

.la-grin:before {
    content: "\f580"
}

.la-grin-alt:before {
    content: "\f581"
}

.la-grin-beam:before {
    content: "\f582"
}

.la-grin-beam-sweat:before {
    content: "\f583"
}

.la-grin-hearts:before {
    content: "\f584"
}

.la-grin-squint:before {
    content: "\f585"
}

.la-grin-squint-tears:before {
    content: "\f586"
}

.la-grin-stars:before {
    content: "\f587"
}

.la-grin-tears:before {
    content: "\f588"
}

.la-grin-tongue:before {
    content: "\f589"
}

.la-grin-tongue-squint:before {
    content: "\f58a"
}

.la-grin-tongue-wink:before {
    content: "\f58b"
}

.la-grin-wink:before {
    content: "\f58c"
}

.la-grip-horizontal:before {
    content: "\f58d"
}

.la-grip-lines:before {
    content: "\f7a4"
}

.la-grip-lines-vertical:before {
    content: "\f7a5"
}

.la-grip-vertical:before {
    content: "\f58e"
}

.la-gripfire:before {
    content: "\f3ac"
}

.la-grunt:before {
    content: "\f3ad"
}

.la-guitar:before {
    content: "\f7a6"
}

.la-gulp:before {
    content: "\f3ae"
}

.la-h-square:before {
    content: "\f0fd"
}

.la-hacker-news:before {
    content: "\f1d4"
}

.la-hacker-news-square:before {
    content: "\f3af"
}

.la-hackerrank:before {
    content: "\f5f7"
}

.la-hamburger:before {
    content: "\f805"
}

.la-hammer:before {
    content: "\f6e3"
}

.la-hamsa:before {
    content: "\f665"
}

.la-hand-holding:before {
    content: "\f4bd"
}

.la-hand-holding-heart:before {
    content: "\f4be"
}

.la-hand-holding-usd:before {
    content: "\f4c0"
}

.la-hand-lizard:before {
    content: "\f258"
}

.la-hand-middle-finger:before {
    content: "\f806"
}

.la-hand-paper:before {
    content: "\f256"
}

.la-hand-peace:before {
    content: "\f25b"
}

.la-hand-point-down:before {
    content: "\f0a7"
}

.la-hand-point-left:before {
    content: "\f0a5"
}

.la-hand-point-right:before {
    content: "\f0a4"
}

.la-hand-point-up:before {
    content: "\f0a6"
}

.la-hand-pointer:before {
    content: "\f25a"
}

.la-hand-rock:before {
    content: "\f255"
}

.la-hand-scissors:before {
    content: "\f257"
}

.la-hand-spock:before {
    content: "\f259"
}

.la-hands:before {
    content: "\f4c2"
}

.la-hands-helping:before {
    content: "\f4c4"
}

.la-handshake:before {
    content: "\f2b5"
}

.la-hanukiah:before {
    content: "\f6e6"
}

.la-hard-hat:before {
    content: "\f807"
}

.la-hashtag:before {
    content: "\f292"
}

.la-hat-cowboy:before {
    content: "\f8c0"
}

.la-hat-cowboy-side:before {
    content: "\f8c1"
}

.la-hat-wizard:before {
    content: "\f6e8"
}

.la-haykal:before {
    content: "\f666"
}

.la-hdd:before {
    content: "\f0a0"
}

.la-heading:before {
    content: "\f1dc"
}

.la-headphones:before {
    content: "\f025"
}

.la-headphones-alt:before {
    content: "\f58f"
}

.la-headset:before {
    content: "\f590"
}

.la-heart:before {
    content: "\f004"
}

.la-heart-broken:before {
    content: "\f7a9"
}

.la-heartbeat:before {
    content: "\f21e"
}

.la-helicopter:before {
    content: "\f533"
}

.la-highlighter:before {
    content: "\f591"
}

.la-hiking:before {
    content: "\f6ec"
}

.la-hippo:before {
    content: "\f6ed"
}

.la-hips:before {
    content: "\f452"
}

.la-hire-a-helper:before {
    content: "\f3b0"
}

.la-history:before {
    content: "\f1da"
}

.la-hockey-puck:before {
    content: "\f453"
}

.la-holly-berry:before {
    content: "\f7aa"
}

.la-home:before {
    content: "\f015"
}

.la-hooli:before {
    content: "\f427"
}

.la-hornbill:before {
    content: "\f592"
}

.la-horse:before {
    content: "\f6f0"
}

.la-horse-head:before {
    content: "\f7ab"
}

.la-hospital:before {
    content: "\f0f8"
}

.la-hospital-alt:before {
    content: "\f47d"
}

.la-hospital-symbol:before {
    content: "\f47e"
}

.la-hot-tub:before {
    content: "\f593"
}

.la-hotdog:before {
    content: "\f80f"
}

.la-hotel:before {
    content: "\f594"
}

.la-hotjar:before {
    content: "\f3b1"
}

.la-hourglass:before {
    content: "\f254"
}

.la-hourglass-end:before {
    content: "\f253"
}

.la-hourglass-half:before {
    content: "\f252"
}

.la-hourglass-start:before {
    content: "\f251"
}

.la-house-damage:before {
    content: "\f6f1"
}

.la-houzz:before {
    content: "\f27c"
}

.la-hryvnia:before {
    content: "\f6f2"
}

.la-html5:before {
    content: "\f13b"
}

.la-hubspot:before {
    content: "\f3b2"
}

.la-i-cursor:before {
    content: "\f246"
}

.la-ice-cream:before {
    content: "\f810"
}

.la-icicles:before {
    content: "\f7ad"
}

.la-icons:before {
    content: "\f86d"
}

.la-id-badge:before {
    content: "\f2c1"
}

.la-id-card:before {
    content: "\f2c2"
}

.la-id-card-alt:before {
    content: "\f47f"
}

.la-igloo:before {
    content: "\f7ae"
}

.la-image:before {
    content: "\f03e"
}

.la-images:before {
    content: "\f302"
}

.la-imdb:before {
    content: "\f2d8"
}

.la-inbox:before {
    content: "\f01c"
}

.la-indent:before {
    content: "\f03c"
}

.la-industry:before {
    content: "\f275"
}

.la-infinity:before {
    content: "\f534"
}

.la-info:before {
    content: "\f129"
}

.la-info-circle:before {
    content: "\f05a"
}

.la-instagram:before {
    content: "\f16d"
}

.la-intercom:before {
    content: "\f7af"
}

.la-internet-explorer:before {
    content: "\f26b"
}

.la-invision:before {
    content: "\f7b0"
}

.la-ioxhost:before {
    content: "\f208"
}

.la-italic:before {
    content: "\f033"
}

.la-itch-io:before {
    content: "\f83a"
}

.la-itunes:before {
    content: "\f3b4"
}

.la-itunes-note:before {
    content: "\f3b5"
}

.la-java:before {
    content: "\f4e4"
}

.la-jedi:before {
    content: "\f669"
}

.la-jedi-order:before {
    content: "\f50e"
}

.la-jenkins:before {
    content: "\f3b6"
}

.la-jira:before {
    content: "\f7b1"
}

.la-joget:before {
    content: "\f3b7"
}

.la-joint:before {
    content: "\f595"
}

.la-joomla:before {
    content: "\f1aa"
}

.la-journal-whills:before {
    content: "\f66a"
}

.la-js:before {
    content: "\f3b8"
}

.la-js-square:before {
    content: "\f3b9"
}

.la-jsfiddle:before {
    content: "\f1cc"
}

.la-kaaba:before {
    content: "\f66b"
}

.la-kaggle:before {
    content: "\f5fa"
}

.la-key:before {
    content: "\f084"
}

.la-keybase:before {
    content: "\f4f5"
}

.la-keyboard:before {
    content: "\f11c"
}

.la-keycdn:before {
    content: "\f3ba"
}

.la-khanda:before {
    content: "\f66d"
}

.la-kickstarter:before {
    content: "\f3bb"
}

.la-kickstarter-k:before {
    content: "\f3bc"
}

.la-kiss:before {
    content: "\f596"
}

.la-kiss-beam:before {
    content: "\f597"
}

.la-kiss-wink-heart:before {
    content: "\f598"
}

.la-kiwi-bird:before {
    content: "\f535"
}

.la-korvue:before {
    content: "\f42f"
}

.la-landmark:before {
    content: "\f66f"
}

.la-language:before {
    content: "\f1ab"
}

.la-laptop:before {
    content: "\f109"
}

.la-laptop-code:before {
    content: "\f5fc"
}

.la-laptop-medical:before {
    content: "\f812"
}

.la-laravel:before {
    content: "\f3bd"
}

.la-lastfm:before {
    content: "\f202"
}

.la-lastfm-square:before {
    content: "\f203"
}

.la-laugh:before {
    content: "\f599"
}

.la-laugh-beam:before {
    content: "\f59a"
}

.la-laugh-squint:before {
    content: "\f59b"
}

.la-laugh-wink:before {
    content: "\f59c"
}

.la-layer-group:before {
    content: "\f5fd"
}

.la-leaf:before {
    content: "\f06c"
}

.la-leanpub:before {
    content: "\f212"
}

.la-lemon:before {
    content: "\f094"
}

.la-less:before {
    content: "\f41d"
}

.la-less-than:before {
    content: "\f536"
}

.la-less-than-equal:before {
    content: "\f537"
}

.la-level-down-alt:before {
    content: "\f3be"
}

.la-level-up-alt:before {
    content: "\f3bf"
}

.la-life-ring:before {
    content: "\f1cd"
}

.la-lightbulb:before {
    content: "\f0eb"
}

.la-line:before {
    content: "\f3c0"
}

.la-link:before {
    content: "\f0c1"
}

.la-linkedin:before {
    content: "\f08c"
}

.la-linkedin-in:before {
    content: "\f0e1"
}

.la-linode:before {
    content: "\f2b8"
}

.la-linux:before {
    content: "\f17c"
}

.la-lira-sign:before {
    content: "\f195"
}

.la-list:before {
    content: "\f03a"
}

.la-list-alt:before {
    content: "\f022"
}

.la-list-ol:before {
    content: "\f0cb"
}

.la-list-ul:before {
    content: "\f0ca"
}

.la-location-arrow:before {
    content: "\f124"
}

.la-lock:before {
    content: "\f023"
}

.la-lock-open:before {
    content: "\f3c1"
}

.la-long-arrow-alt-down:before {
    content: "\f309"
}

.la-long-arrow-alt-left:before {
    content: "\f30a"
}

.la-long-arrow-alt-right:before {
    content: "\f30b"
}

.la-long-arrow-alt-up:before {
    content: "\f30c"
}

.la-low-vision:before {
    content: "\f2a8"
}

.la-luggage-cart:before {
    content: "\f59d"
}

.la-lyft:before {
    content: "\f3c3"
}

.la-magento:before {
    content: "\f3c4"
}

.la-magic:before {
    content: "\f0d0"
}

.la-magnet:before {
    content: "\f076"
}

.la-mail-bulk:before {
    content: "\f674"
}

.la-mailchimp:before {
    content: "\f59e"
}

.la-male:before {
    content: "\f183"
}

.la-mandalorian:before {
    content: "\f50f"
}

.la-map:before {
    content: "\f279"
}

.la-map-marked:before {
    content: "\f59f"
}

.la-map-marked-alt:before {
    content: "\f5a0"
}

.la-map-marker:before {
    content: "\f041"
}

.la-map-marker-alt:before {
    content: "\f3c5"
}

.la-map-pin:before {
    content: "\f276"
}

.la-map-signs:before {
    content: "\f277"
}

.la-markdown:before {
    content: "\f60f"
}

.la-marker:before {
    content: "\f5a1"
}

.la-mars:before {
    content: "\f222"
}

.la-mars-double:before {
    content: "\f227"
}

.la-mars-stroke:before {
    content: "\f229"
}

.la-mars-stroke-h:before {
    content: "\f22b"
}

.la-mars-stroke-v:before {
    content: "\f22a"
}

.la-mask:before {
    content: "\f6fa"
}

.la-mastodon:before {
    content: "\f4f6"
}

.la-maxcdn:before {
    content: "\f136"
}

.la-mdb:before {
    content: "\f8ca"
}

.la-medal:before {
    content: "\f5a2"
}

.la-medapps:before {
    content: "\f3c6"
}

.la-medium:before {
    content: "\f23a"
}

.la-medium-m:before {
    content: "\f3c7"
}

.la-medkit:before {
    content: "\f0fa"
}

.la-medrt:before {
    content: "\f3c8"
}

.la-meetup:before {
    content: "\f2e0"
}

.la-megaport:before {
    content: "\f5a3"
}

.la-meh:before {
    content: "\f11a"
}

.la-meh-blank:before {
    content: "\f5a4"
}

.la-meh-rolling-eyes:before {
    content: "\f5a5"
}

.la-memory:before {
    content: "\f538"
}

.la-mendeley:before {
    content: "\f7b3"
}

.la-menorah:before {
    content: "\f676"
}

.la-mercury:before {
    content: "\f223"
}

.la-meteor:before {
    content: "\f753"
}

.la-microchip:before {
    content: "\f2db"
}

.la-microphone:before {
    content: "\f130"
}

.la-microphone-alt:before {
    content: "\f3c9"
}

.la-microphone-alt-slash:before {
    content: "\f539"
}

.la-microphone-slash:before {
    content: "\f131"
}

.la-microscope:before {
    content: "\f610"
}

.la-microsoft:before {
    content: "\f3ca"
}

.la-minus:before {
    content: "\f068"
}

.la-minus-circle:before {
    content: "\f056"
}

.la-minus-square:before {
    content: "\f146"
}

.la-mitten:before {
    content: "\f7b5"
}

.la-mix:before {
    content: "\f3cb"
}

.la-mixcloud:before {
    content: "\f289"
}

.la-mizuni:before {
    content: "\f3cc"
}

.la-mobile:before {
    content: "\f10b"
}

.la-mobile-alt:before {
    content: "\f3cd"
}

.la-modx:before {
    content: "\f285"
}

.la-monero:before {
    content: "\f3d0"
}

.la-money-bill:before {
    content: "\f0d6"
}

.la-money-bill-alt:before {
    content: "\f3d1"
}

.la-money-bill-wave:before {
    content: "\f53a"
}

.la-money-bill-wave-alt:before {
    content: "\f53b"
}

.la-money-check:before {
    content: "\f53c"
}

.la-money-check-alt:before {
    content: "\f53d"
}

.la-monument:before {
    content: "\f5a6"
}

.la-moon:before {
    content: "\f186"
}

.la-mortar-pestle:before {
    content: "\f5a7"
}

.la-mosque:before {
    content: "\f678"
}

.la-motorcycle:before {
    content: "\f21c"
}

.la-mountain:before {
    content: "\f6fc"
}

.la-mouse:before {
    content: "\f8cc"
}

.la-mouse-pointer:before {
    content: "\f245"
}

.la-mug-hot:before {
    content: "\f7b6"
}

.la-music:before {
    content: "\f001"
}

.la-napster:before {
    content: "\f3d2"
}

.la-neos:before {
    content: "\f612"
}

.la-network-wired:before {
    content: "\f6ff"
}

.la-neuter:before {
    content: "\f22c"
}

.la-newspaper:before {
    content: "\f1ea"
}

.la-nimblr:before {
    content: "\f5a8"
}

.la-node:before {
    content: "\f419"
}

.la-node-js:before {
    content: "\f3d3"
}

.la-not-equal:before {
    content: "\f53e"
}

.la-notes-medical:before {
    content: "\f481"
}

.la-npm:before {
    content: "\f3d4"
}

.la-ns8:before {
    content: "\f3d5"
}

.la-nutritionix:before {
    content: "\f3d6"
}

.la-object-group:before {
    content: "\f247"
}

.la-object-ungroup:before {
    content: "\f248"
}

.la-odnoklassniki:before {
    content: "\f263"
}

.la-odnoklassniki-square:before {
    content: "\f264"
}

.la-oil-can:before {
    content: "\f613"
}

.la-old-republic:before {
    content: "\f510"
}

.la-om:before {
    content: "\f679"
}

.la-opencart:before {
    content: "\f23d"
}

.la-openid:before {
    content: "\f19b"
}

.la-opera:before {
    content: "\f26a"
}

.la-optin-monster:before {
    content: "\f23c"
}

.la-orcid:before {
    content: "\f8d2"
}

.la-osi:before {
    content: "\f41a"
}

.la-otter:before {
    content: "\f700"
}

.la-outdent:before {
    content: "\f03b"
}

.la-page4:before {
    content: "\f3d7"
}

.la-pagelines:before {
    content: "\f18c"
}

.la-pager:before {
    content: "\f815"
}

.la-paint-brush:before {
    content: "\f1fc"
}

.la-paint-roller:before {
    content: "\f5aa"
}

.la-palette:before {
    content: "\f53f"
}

.la-palfed:before {
    content: "\f3d8"
}

.la-pallet:before {
    content: "\f482"
}

.la-paper-plane:before {
    content: "\f1d8"
}

.la-paperclip:before {
    content: "\f0c6"
}

.la-parachute-box:before {
    content: "\f4cd"
}

.la-paragraph:before {
    content: "\f1dd"
}

.la-parking:before {
    content: "\f540"
}

.la-passport:before {
    content: "\f5ab"
}

.la-pastafarianism:before {
    content: "\f67b"
}

.la-paste:before {
    content: "\f0ea"
}

.la-patreon:before {
    content: "\f3d9"
}

.la-pause:before {
    content: "\f04c"
}

.la-pause-circle:before {
    content: "\f28b"
}

.la-paw:before {
    content: "\f1b0"
}

.la-paypal:before {
    content: "\f1ed"
}

.la-peace:before {
    content: "\f67c"
}

.la-pen:before {
    content: "\f304"
}

.la-pen-alt:before {
    content: "\f305"
}

.la-pen-fancy:before {
    content: "\f5ac"
}

.la-pen-nib:before {
    content: "\f5ad"
}

.la-pen-square:before {
    content: "\f14b"
}

.la-pencil-alt:before {
    content: "\f303"
}

.la-pencil-ruler:before {
    content: "\f5ae"
}

.la-penny-arcade:before {
    content: "\f704"
}

.la-people-carry:before {
    content: "\f4ce"
}

.la-pepper-hot:before {
    content: "\f816"
}

.la-percent:before {
    content: "\f295"
}

.la-percentage:before {
    content: "\f541"
}

.la-periscope:before {
    content: "\f3da"
}

.la-person-booth:before {
    content: "\f756"
}

.la-phabricator:before {
    content: "\f3db"
}

.la-phoenix-framework:before {
    content: "\f3dc"
}

.la-phoenix-squadron:before {
    content: "\f511"
}

.la-phone:before {
    content: "\f095"
}

.la-phone-alt:before {
    content: "\f879"
}

.la-phone-slash:before {
    content: "\f3dd"
}

.la-phone-square:before {
    content: "\f098"
}

.la-phone-square-alt:before {
    content: "\f87b"
}

.la-phone-volume:before {
    content: "\f2a0"
}

.la-photo-video:before {
    content: "\f87c"
}

.la-php:before {
    content: "\f457"
}

.la-pied-piper:before {
    content: "\f2ae"
}

.la-pied-piper-alt:before {
    content: "\f1a8"
}

.la-pied-piper-hat:before {
    content: "\f4e5"
}

.la-pied-piper-pp:before {
    content: "\f1a7"
}

.la-piggy-bank:before {
    content: "\f4d3"
}

.la-pills:before {
    content: "\f484"
}

.la-pinterest:before {
    content: "\f0d2"
}

.la-pinterest-p:before {
    content: "\f231"
}

.la-pinterest-square:before {
    content: "\f0d3"
}

.la-pizza-slice:before {
    content: "\f818"
}

.la-place-of-worship:before {
    content: "\f67f"
}

.la-plane:before {
    content: "\f072"
}

.la-plane-arrival:before {
    content: "\f5af"
}

.la-plane-departure:before {
    content: "\f5b0"
}

.la-play:before {
    content: "\f04b"
}

.la-play-circle:before {
    content: "\f144"
}

.la-playstation:before {
    content: "\f3df"
}

.la-plug:before {
    content: "\f1e6"
}

.la-plus:before {
    content: "\f067"
}

.la-plus-circle:before {
    content: "\f055"
}

.la-plus-square:before {
    content: "\f0fe"
}

.la-podcast:before {
    content: "\f2ce"
}

.la-poll:before {
    content: "\f681"
}

.la-poll-h:before {
    content: "\f682"
}

.la-poo:before {
    content: "\f2fe"
}

.la-poo-storm:before {
    content: "\f75a"
}

.la-poop:before {
    content: "\f619"
}

.la-portrait:before {
    content: "\f3e0"
}

.la-pound-sign:before {
    content: "\f154"
}

.la-power-off:before {
    content: "\f011"
}

.la-pray:before {
    content: "\f683"
}

.la-praying-hands:before {
    content: "\f684"
}

.la-prescription:before {
    content: "\f5b1"
}

.la-prescription-bottle:before {
    content: "\f485"
}

.la-prescription-bottle-alt:before {
    content: "\f486"
}

.la-print:before {
    content: "\f02f"
}

.la-procedures:before {
    content: "\f487"
}

.la-product-hunt:before {
    content: "\f288"
}

.la-project-diagram:before {
    content: "\f542"
}

.la-pushed:before {
    content: "\f3e1"
}

.la-puzzle-piece:before {
    content: "\f12e"
}

.la-python:before {
    content: "\f3e2"
}

.la-qq:before {
    content: "\f1d6"
}

.la-qrcode:before {
    content: "\f029"
}

.la-question:before {
    content: "\f128"
}

.la-question-circle:before {
    content: "\f059"
}

.la-quidditch:before {
    content: "\f458"
}

.la-quinscape:before {
    content: "\f459"
}

.la-quora:before {
    content: "\f2c4"
}

.la-quote-left:before {
    content: "\f10d"
}

.la-quote-right:before {
    content: "\f10e"
}

.la-quran:before {
    content: "\f687"
}

.la-r-project:before {
    content: "\f4f7"
}

.la-radiation:before {
    content: "\f7b9"
}

.la-radiation-alt:before {
    content: "\f7ba"
}

.la-rainbow:before {
    content: "\f75b"
}

.la-random:before {
    content: "\f074"
}

.la-raspberry-pi:before {
    content: "\f7bb"
}

.la-ravelry:before {
    content: "\f2d9"
}

.la-react:before {
    content: "\f41b"
}

.la-reacteurope:before {
    content: "\f75d"
}

.la-readme:before {
    content: "\f4d5"
}

.la-rebel:before {
    content: "\f1d0"
}

.la-receipt:before {
    content: "\f543"
}

.la-record-vinyl:before {
    content: "\f8d9"
}

.la-recycle:before {
    content: "\f1b8"
}

.la-red-river:before {
    content: "\f3e3"
}

.la-reddit:before {
    content: "\f1a1"
}

.la-reddit-alien:before {
    content: "\f281"
}

.la-reddit-square:before {
    content: "\f1a2"
}

.la-redhat:before {
    content: "\f7bc"
}

.la-redo:before {
    content: "\f01e"
}

.la-redo-alt:before {
    content: "\f2f9"
}

.la-registered:before {
    content: "\f25d"
}

.la-remove-format:before {
    content: "\f87d"
}

.la-renren:before {
    content: "\f18b"
}

.la-reply:before {
    content: "\f3e5"
}

.la-reply-all:before {
    content: "\f122"
}

.la-replyd:before {
    content: "\f3e6"
}

.la-republican:before {
    content: "\f75e"
}

.la-researchgate:before {
    content: "\f4f8"
}

.la-resolving:before {
    content: "\f3e7"
}

.la-restroom:before {
    content: "\f7bd"
}

.la-retweet:before {
    content: "\f079"
}

.la-rev:before {
    content: "\f5b2"
}

.la-ribbon:before {
    content: "\f4d6"
}

.la-ring:before {
    content: "\f70b"
}

.la-road:before {
    content: "\f018"
}

.la-robot:before {
    content: "\f544"
}

.la-rocket:before {
    content: "\f135"
}

.la-rocketchat:before {
    content: "\f3e8"
}

.la-rockrms:before {
    content: "\f3e9"
}

.la-route:before {
    content: "\f4d7"
}

.la-rss:before {
    content: "\f09e"
}

.la-rss-square:before {
    content: "\f143"
}

.la-ruble-sign:before {
    content: "\f158"
}

.la-ruler:before {
    content: "\f545"
}

.la-ruler-combined:before {
    content: "\f546"
}

.la-ruler-horizontal:before {
    content: "\f547"
}

.la-ruler-vertical:before {
    content: "\f548"
}

.la-running:before {
    content: "\f70c"
}

.la-rupee-sign:before {
    content: "\f156"
}

.la-sad-cry:before {
    content: "\f5b3"
}

.la-sad-tear:before {
    content: "\f5b4"
}

.la-safari:before {
    content: "\f267"
}

.la-salesforce:before {
    content: "\f83b"
}

.la-sass:before {
    content: "\f41e"
}

.la-satellite:before {
    content: "\f7bf"
}

.la-satellite-dish:before {
    content: "\f7c0"
}

.la-save:before {
    content: "\f0c7"
}

.la-schlix:before {
    content: "\f3ea"
}

.la-school:before {
    content: "\f549"
}

.la-screwdriver:before {
    content: "\f54a"
}

.la-scribd:before {
    content: "\f28a"
}

.la-scroll:before {
    content: "\f70e"
}

.la-sd-card:before {
    content: "\f7c2"
}

.la-search:before {
    content: "\f002"
}

.la-search-dollar:before {
    content: "\f688"
}

.la-search-location:before {
    content: "\f689"
}

.la-search-minus:before {
    content: "\f010"
}

.la-search-plus:before {
    content: "\f00e"
}

.la-searchengin:before {
    content: "\f3eb"
}

.la-seedling:before {
    content: "\f4d8"
}

.la-sellcast:before {
    content: "\f2da"
}

.la-sellsy:before {
    content: "\f213"
}

.la-server:before {
    content: "\f233"
}

.la-servicestack:before {
    content: "\f3ec"
}

.la-shapes:before {
    content: "\f61f"
}

.la-share:before {
    content: "\f064"
}

.la-share-alt:before {
    content: "\f1e0"
}

.la-share-alt-square:before {
    content: "\f1e1"
}

.la-share-square:before {
    content: "\f14d"
}

.la-shekel-sign:before {
    content: "\f20b"
}

.la-shield-alt:before {
    content: "\f3ed"
}

.la-ship:before {
    content: "\f21a"
}

.la-shipping-fast:before {
    content: "\f48b"
}

.la-shirtsinbulk:before {
    content: "\f214"
}

.la-shoe-prints:before {
    content: "\f54b"
}

.la-shopping-bag:before {
    content: "\f290"
}

.la-shopping-basket:before {
    content: "\f291"
}

.la-shopping-cart:before {
    content: "\f07a"
}

.la-shopware:before {
    content: "\f5b5"
}

.la-shower:before {
    content: "\f2cc"
}

.la-shuttle-van:before {
    content: "\f5b6"
}

.la-sign:before {
    content: "\f4d9"
}

.la-sign-in-alt:before {
    content: "\f2f6"
}

.la-sign-language:before {
    content: "\f2a7"
}

.la-sign-out-alt:before {
    content: "\f2f5"
}

.la-signal:before {
    content: "\f012"
}

.la-signature:before {
    content: "\f5b7"
}

.la-sim-card:before {
    content: "\f7c4"
}

.la-simplybuilt:before {
    content: "\f215"
}

.la-sistrix:before {
    content: "\f3ee"
}

.la-sitemap:before {
    content: "\f0e8"
}

.la-sith:before {
    content: "\f512"
}

.la-skating:before {
    content: "\f7c5"
}

.la-sketch:before {
    content: "\f7c6"
}

.la-skiing:before {
    content: "\f7c9"
}

.la-skiing-nordic:before {
    content: "\f7ca"
}

.la-skull:before {
    content: "\f54c"
}

.la-skull-crossbones:before {
    content: "\f714"
}

.la-skyatlas:before {
    content: "\f216"
}

.la-skype:before {
    content: "\f17e"
}

.la-slack:before {
    content: "\f198"
}

.la-slack-hash:before {
    content: "\f3ef"
}

.la-slash:before {
    content: "\f715"
}

.la-sleigh:before {
    content: "\f7cc"
}

.la-sliders-h:before {
    content: "\f1de"
}

.la-slideshare:before {
    content: "\f1e7"
}

.la-smile:before {
    content: "\f118"
}

.la-smile-beam:before {
    content: "\f5b8"
}

.la-smile-wink:before {
    content: "\f4da"
}

.la-smog:before {
    content: "\f75f"
}

.la-smoking:before {
    content: "\f48d"
}

.la-smoking-ban:before {
    content: "\f54d"
}

.la-sms:before {
    content: "\f7cd"
}

.la-snapchat:before {
    content: "\f2ab"
}

.la-snapchat-ghost:before {
    content: "\f2ac"
}

.la-snapchat-square:before {
    content: "\f2ad"
}

.la-snowboarding:before {
    content: "\f7ce"
}

.la-snowflake:before {
    content: "\f2dc"
}

.la-snowman:before {
    content: "\f7d0"
}

.la-snowplow:before {
    content: "\f7d2"
}

.la-socks:before {
    content: "\f696"
}

.la-solar-panel:before {
    content: "\f5ba"
}

.la-sort:before {
    content: "\f0dc"
}

.la-sort-alpha-down:before {
    content: "\f15d"
}

.la-sort-alpha-down-alt:before {
    content: "\f881"
}

.la-sort-alpha-up:before {
    content: "\f15e"
}

.la-sort-alpha-up-alt:before {
    content: "\f882"
}

.la-sort-amount-down:before {
    content: "\f160"
}

.la-sort-amount-down-alt:before {
    content: "\f884"
}

.la-sort-amount-up:before {
    content: "\f161"
}

.la-sort-amount-up-alt:before {
    content: "\f885"
}

.la-sort-down:before {
    content: "\f0dd"
}

.la-sort-numeric-down:before {
    content: "\f162"
}

.la-sort-numeric-down-alt:before {
    content: "\f886"
}

.la-sort-numeric-up:before {
    content: "\f163"
}

.la-sort-numeric-up-alt:before {
    content: "\f887"
}

.la-sort-up:before {
    content: "\f0de"
}

.la-soundcloud:before {
    content: "\f1be"
}

.la-sourcetree:before {
    content: "\f7d3"
}

.la-spa:before {
    content: "\f5bb"
}

.la-space-shuttle:before {
    content: "\f197"
}

.la-speakap:before {
    content: "\f3f3"
}

.la-speaker-deck:before {
    content: "\f83c"
}

.la-spell-check:before {
    content: "\f891"
}

.la-spider:before {
    content: "\f717"
}

.la-spinner:before {
    content: "\f110"
}

.la-splotch:before {
    content: "\f5bc"
}

.la-spotify:before {
    content: "\f1bc"
}

.la-spray-can:before {
    content: "\f5bd"
}

.la-square:before {
    content: "\f0c8"
}

.la-square-full:before {
    content: "\f45c"
}

.la-square-root-alt:before {
    content: "\f698"
}

.la-squarespace:before {
    content: "\f5be"
}

.la-stack-exchange:before {
    content: "\f18d"
}

.la-stack-overflow:before {
    content: "\f16c"
}

.la-stackpath:before {
    content: "\f842"
}

.la-stamp:before {
    content: "\f5bf"
}

.la-star:before {
    content: "\f005"
}

.la-star-and-crescent:before {
    content: "\f699"
}

.la-star-half:before {
    content: "\f089"
}

.la-star-half-alt:before {
    content: "\f5c0"
}

.la-star-of-david:before {
    content: "\f69a"
}

.la-star-of-life:before {
    content: "\f621"
}

.la-staylinked:before {
    content: "\f3f5"
}

.la-steam:before {
    content: "\f1b6"
}

.la-steam-square:before {
    content: "\f1b7"
}

.la-steam-symbol:before {
    content: "\f3f6"
}

.la-step-backward:before {
    content: "\f048"
}

.la-step-forward:before {
    content: "\f051"
}

.la-stethoscope:before {
    content: "\f0f1"
}

.la-sticker-mule:before {
    content: "\f3f7"
}

.la-sticky-note:before {
    content: "\f249"
}

.la-stop:before {
    content: "\f04d"
}

.la-stop-circle:before {
    content: "\f28d"
}

.la-stopwatch:before {
    content: "\f2f2"
}

.la-store:before {
    content: "\f54e"
}

.la-store-alt:before {
    content: "\f54f"
}

.la-strava:before {
    content: "\f428"
}

.la-stream:before {
    content: "\f550"
}

.la-street-view:before {
    content: "\f21d"
}

.la-strikethrough:before {
    content: "\f0cc"
}

.la-stripe:before {
    content: "\f429"
}

.la-stripe-s:before {
    content: "\f42a"
}

.la-stroopwafel:before {
    content: "\f551"
}

.la-studiovinari:before {
    content: "\f3f8"
}

.la-stumbleupon:before {
    content: "\f1a4"
}

.la-stumbleupon-circle:before {
    content: "\f1a3"
}

.la-subscript:before {
    content: "\f12c"
}

.la-subway:before {
    content: "\f239"
}

.la-suitcase:before {
    content: "\f0f2"
}

.la-suitcase-rolling:before {
    content: "\f5c1"
}

.la-sun:before {
    content: "\f185"
}

.la-superpowers:before {
    content: "\f2dd"
}

.la-superscript:before {
    content: "\f12b"
}

.la-supple:before {
    content: "\f3f9"
}

.la-surprise:before {
    content: "\f5c2"
}

.la-suse:before {
    content: "\f7d6"
}

.la-swatchbook:before {
    content: "\f5c3"
}

.la-swift:before {
    content: "\f8e1"
}

.la-swimmer:before {
    content: "\f5c4"
}

.la-swimming-pool:before {
    content: "\f5c5"
}

.la-symfony:before {
    content: "\f83d"
}

.la-synagogue:before {
    content: "\f69b"
}

.la-sync:before {
    content: "\f021"
}

.la-sync-alt:before {
    content: "\f2f1"
}

.la-syringe:before {
    content: "\f48e"
}

.la-table:before {
    content: "\f0ce"
}

.la-table-tennis:before {
    content: "\f45d"
}

.la-tablet:before {
    content: "\f10a"
}

.la-tablet-alt:before {
    content: "\f3fa"
}

.la-tablets:before {
    content: "\f490"
}

.la-tachometer-alt:before {
    content: "\f3fd"
}

.la-tag:before {
    content: "\f02b"
}

.la-tags:before {
    content: "\f02c"
}

.la-tape:before {
    content: "\f4db"
}

.la-tasks:before {
    content: "\f0ae"
}

.la-taxi:before {
    content: "\f1ba"
}

.la-teamspeak:before {
    content: "\f4f9"
}

.la-teeth:before {
    content: "\f62e"
}

.la-teeth-open:before {
    content: "\f62f"
}

.la-telegram:before {
    content: "\f2c6"
}

.la-telegram-plane:before {
    content: "\f3fe"
}

.la-temperature-high:before {
    content: "\f769"
}

.la-temperature-low:before {
    content: "\f76b"
}

.la-tencent-weibo:before {
    content: "\f1d5"
}

.la-tenge:before {
    content: "\f7d7"
}

.la-terminal:before {
    content: "\f120"
}

.la-text-height:before {
    content: "\f034"
}

.la-text-width:before {
    content: "\f035"
}

.la-th:before {
    content: "\f00a"
}

.la-th-large:before {
    content: "\f009"
}

.la-th-list:before {
    content: "\f00b"
}

.la-the-red-yeti:before {
    content: "\f69d"
}

.la-theater-masks:before {
    content: "\f630"
}

.la-themeco:before {
    content: "\f5c6"
}

.la-themeisle:before {
    content: "\f2b2"
}

.la-thermometer:before {
    content: "\f491"
}

.la-thermometer-empty:before {
    content: "\f2cb"
}

.la-thermometer-full:before {
    content: "\f2c7"
}

.la-thermometer-half:before {
    content: "\f2c9"
}

.la-thermometer-quarter:before {
    content: "\f2ca"
}

.la-thermometer-three-quarters:before {
    content: "\f2c8"
}

.la-think-peaks:before {
    content: "\f731"
}

.la-thumbs-down:before {
    content: "\f165"
}

.la-thumbs-up:before {
    content: "\f164"
}

.la-thumbtack:before {
    content: "\f08d"
}

.la-ticket-alt:before {
    content: "\f3ff"
}

.la-times:before {
    content: "\f00d"
}

.la-times-circle:before {
    content: "\f057"
}

.la-tint:before {
    content: "\f043"
}

.la-tint-slash:before {
    content: "\f5c7"
}

.la-tired:before {
    content: "\f5c8"
}

.la-toggle-off:before {
    content: "\f204"
}

.la-toggle-on:before {
    content: "\f205"
}

.la-toilet:before {
    content: "\f7d8"
}

.la-toilet-paper:before {
    content: "\f71e"
}

.la-toolbox:before {
    content: "\f552"
}

.la-tools:before {
    content: "\f7d9"
}

.la-tooth:before {
    content: "\f5c9"
}

.la-torah:before {
    content: "\f6a0"
}

.la-torii-gate:before {
    content: "\f6a1"
}

.la-tractor:before {
    content: "\f722"
}

.la-trade-federation:before {
    content: "\f513"
}

.la-trademark:before {
    content: "\f25c"
}

.la-traffic-light:before {
    content: "\f637"
}

.la-train:before {
    content: "\f238"
}

.la-tram:before {
    content: "\f7da"
}

.la-transgender:before {
    content: "\f224"
}

.la-transgender-alt:before {
    content: "\f225"
}

.la-trash:before {
    content: "\f1f8"
}

.la-trash-alt:before {
    content: "\f2ed"
}

.la-trash-restore:before {
    content: "\f829"
}

.la-trash-restore-alt:before {
    content: "\f82a"
}

.la-tree:before {
    content: "\f1bb"
}

.la-trello:before {
    content: "\f181"
}

.la-tripadvisor:before {
    content: "\f262"
}

.la-trophy:before {
    content: "\f091"
}

.la-truck:before {
    content: "\f0d1"
}

.la-truck-loading:before {
    content: "\f4de"
}

.la-truck-monster:before {
    content: "\f63b"
}

.la-truck-moving:before {
    content: "\f4df"
}

.la-truck-pickup:before {
    content: "\f63c"
}

.la-tshirt:before {
    content: "\f553"
}

.la-tty:before {
    content: "\f1e4"
}

.la-tumblr:before {
    content: "\f173"
}

.la-tumblr-square:before {
    content: "\f174"
}

.la-tv:before {
    content: "\f26c"
}

.la-twitch:before {
    content: "\f1e8"
}

.la-twitter:before {
    content: "\f099"
}

.la-twitter-square:before {
    content: "\f081"
}

.la-typo3:before {
    content: "\f42b"
}

.la-uber:before {
    content: "\f402"
}

.la-ubuntu:before {
    content: "\f7df"
}

.la-uikit:before {
    content: "\f403"
}

.la-umbraco:before {
    content: "\f8e8"
}

.la-umbrella:before {
    content: "\f0e9"
}

.la-umbrella-beach:before {
    content: "\f5ca"
}

.la-underline:before {
    content: "\f0cd"
}

.la-undo:before {
    content: "\f0e2"
}

.la-undo-alt:before {
    content: "\f2ea"
}

.la-uniregistry:before {
    content: "\f404"
}

.la-universal-access:before {
    content: "\f29a"
}

.la-university:before {
    content: "\f19c"
}

.la-unlink:before {
    content: "\f127"
}

.la-unlock:before {
    content: "\f09c"
}

.la-unlock-alt:before {
    content: "\f13e"
}

.la-untappd:before {
    content: "\f405"
}

.la-upload:before {
    content: "\f093"
}

.la-ups:before {
    content: "\f7e0"
}

.la-usb:before {
    content: "\f287"
}

.la-user:before {
    content: "\f007"
}

.la-user-alt:before {
    content: "\f406"
}

.la-user-alt-slash:before {
    content: "\f4fa"
}

.la-user-astronaut:before {
    content: "\f4fb"
}

.la-user-check:before {
    content: "\f4fc"
}

.la-user-circle:before {
    content: "\f2bd"
}

.la-user-clock:before {
    content: "\f4fd"
}

.la-user-cog:before {
    content: "\f4fe"
}

.la-user-edit:before {
    content: "\f4ff"
}

.la-user-friends:before {
    content: "\f500"
}

.la-user-graduate:before {
    content: "\f501"
}

.la-user-injured:before {
    content: "\f728"
}

.la-user-lock:before {
    content: "\f502"
}

.la-user-md:before {
    content: "\f0f0"
}

.la-user-minus:before {
    content: "\f503"
}

.la-user-ninja:before {
    content: "\f504"
}

.la-user-nurse:before {
    content: "\f82f"
}

.la-user-plus:before {
    content: "\f234"
}

.la-user-secret:before {
    content: "\f21b"
}

.la-user-shield:before {
    content: "\f505"
}

.la-user-slash:before {
    content: "\f506"
}

.la-user-tag:before {
    content: "\f507"
}

.la-user-tie:before {
    content: "\f508"
}

.la-user-times:before {
    content: "\f235"
}

.la-users:before {
    content: "\f0c0"
}

.la-users-cog:before {
    content: "\f509"
}

.la-usps:before {
    content: "\f7e1"
}

.la-ussunnah:before {
    content: "\f407"
}

.la-utensil-spoon:before {
    content: "\f2e5"
}

.la-utensils:before {
    content: "\f2e7"
}

.la-vaadin:before {
    content: "\f408"
}

.la-vector-square:before {
    content: "\f5cb"
}

.la-venus:before {
    content: "\f221"
}

.la-venus-double:before {
    content: "\f226"
}

.la-venus-mars:before {
    content: "\f228"
}

.la-viacoin:before {
    content: "\f237"
}

.la-viadeo:before {
    content: "\f2a9"
}

.la-viadeo-square:before {
    content: "\f2aa"
}

.la-vial:before {
    content: "\f492"
}

.la-vials:before {
    content: "\f493"
}

.la-viber:before {
    content: "\f409"
}

.la-video:before {
    content: "\f03d"
}

.la-video-slash:before {
    content: "\f4e2"
}

.la-vihara:before {
    content: "\f6a7"
}

.la-vimeo:before {
    content: "\f40a"
}

.la-vimeo-square:before {
    content: "\f194"
}

.la-vimeo-v:before {
    content: "\f27d"
}

.la-vine:before {
    content: "\f1ca"
}

.la-vk:before {
    content: "\f189"
}

.la-vnv:before {
    content: "\f40b"
}

.la-voicemail:before {
    content: "\f897"
}

.la-volleyball-ball:before {
    content: "\f45f"
}

.la-volume-down:before {
    content: "\f027"
}

.la-volume-mute:before {
    content: "\f6a9"
}

.la-volume-off:before {
    content: "\f026"
}

.la-volume-up:before {
    content: "\f028"
}

.la-vote-yea:before {
    content: "\f772"
}

.la-vr-cardboard:before {
    content: "\f729"
}

.la-vuejs:before {
    content: "\f41f"
}

.la-walking:before {
    content: "\f554"
}

.la-wallet:before {
    content: "\f555"
}

.la-warehouse:before {
    content: "\f494"
}

.la-water:before {
    content: "\f773"
}

.la-wave-square:before {
    content: "\f83e"
}

.la-waze:before {
    content: "\f83f"
}

.la-weebly:before {
    content: "\f5cc"
}

.la-weibo:before {
    content: "\f18a"
}

.la-weight:before {
    content: "\f496"
}

.la-weight-hanging:before {
    content: "\f5cd"
}

.la-weixin:before {
    content: "\f1d7"
}

.la-whatsapp:before {
    content: "\f232"
}

.la-whatsapp-square:before {
    content: "\f40c"
}

.la-wheelchair:before {
    content: "\f193"
}

.la-whmcs:before {
    content: "\f40d"
}

.la-wifi:before {
    content: "\f1eb"
}

.la-wikipedia-w:before {
    content: "\f266"
}

.la-wind:before {
    content: "\f72e"
}

.la-window-close:before {
    content: "\f410"
}

.la-window-maximize:before {
    content: "\f2d0"
}

.la-window-minimize:before {
    content: "\f2d1"
}

.la-window-restore:before {
    content: "\f2d2"
}

.la-windows:before {
    content: "\f17a"
}

.la-wine-bottle:before {
    content: "\f72f"
}

.la-wine-glass:before {
    content: "\f4e3"
}

.la-wine-glass-alt:before {
    content: "\f5ce"
}

.la-wix:before {
    content: "\f5cf"
}

.la-wizards-of-the-coast:before {
    content: "\f730"
}

.la-wolf-pack-battalion:before {
    content: "\f514"
}

.la-won-sign:before {
    content: "\f159"
}

.la-wordpress:before {
    content: "\f19a"
}

.la-wordpress-simple:before {
    content: "\f411"
}

.la-wpbeginner:before {
    content: "\f297"
}

.la-wpexplorer:before {
    content: "\f2de"
}

.la-wpforms:before {
    content: "\f298"
}

.la-wpressr:before {
    content: "\f3e4"
}

.la-wrench:before {
    content: "\f0ad"
}

.la-x-ray:before {
    content: "\f497"
}

.la-xbox:before {
    content: "\f412"
}

.la-xing:before {
    content: "\f168"
}

.la-xing-square:before {
    content: "\f169"
}

.la-y-combinator:before {
    content: "\f23b"
}

.la-yahoo:before {
    content: "\f19e"
}

.la-yammer:before {
    content: "\f840"
}

.la-yandex:before {
    content: "\f413"
}

.la-yandex-international:before {
    content: "\f414"
}

.la-yarn:before {
    content: "\f7e3"
}

.la-yelp:before {
    content: "\f1e9"
}

.la-yen-sign:before {
    content: "\f157"
}

.la-yin-yang:before {
    content: "\f6ad"
}

.la-yoast:before {
    content: "\f2b1"
}

.la-youtube:before {
    content: "\f167"
}

.la-youtube-square:before {
    content: "\f431"
}

.la-zhihu:before {
    content: "\f63f"
}

.sr-only {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto
}

@font-face {
    font-family: 'Line Awesome Brands';
    font-style: normal;
    font-weight: 400;
    font-display: auto;
    src: url(/static/media/la-brands-400.908ce19d.eot);
    src: url(/static/media/la-brands-400.908ce19d.eot?#iefix) format("embedded-opentype"), url(/static/media/la-brands-400.3a8109c4.woff2) format("woff2"), url(/static/media/la-brands-400.925b340f.woff) format("woff"), url(/static/media/la-brands-400.6ecea482.ttf) format("truetype"), url(/static/media/la-brands-400.928495fa.svg#lineawesome) format("svg")
}

.lab {
    font-family: 'Line Awesome Brands'
}

@font-face {
    font-family: 'Line Awesome Free';
    font-style: normal;
    font-weight: 400;
    font-display: auto;
    src: url(/static/media/la-regular-400.77111476.eot);
    src: url(/static/media/la-regular-400.77111476.eot?#iefix) format("embedded-opentype"), url(/static/media/la-regular-400.8dc1cedf.woff2) format("woff2"), url(/static/media/la-regular-400.aa859c0d.woff) format("woff"), url(/static/media/la-regular-400.cf246e21.ttf) format("truetype"), url(/static/media/la-regular-400.86128b71.svg#lineawesome) format("svg")
}

.lar {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

@font-face {
    font-family: 'Line Awesome Free';
    font-style: normal;
    font-weight: 900;
    font-display: auto;
    src: url(/static/media/la-solid-900.4184d740.eot);
    src: url(/static/media/la-solid-900.4184d740.eot?#iefix) format("embedded-opentype"), url(/static/media/la-solid-900.3efd5ba1.woff2) format("woff2"), url(/static/media/la-solid-900.89363487.woff) format("woff"), url(/static/media/la-solid-900.279f3860.ttf) format("truetype"), url(/static/media/la-solid-900.a8130346.svg#lineawesome) format("svg")
}

.la,
.las {
    font-family: 'Line Awesome Free';
    font-weight: 900
}

.la.la-glass:before {
    content: "\f000"
}

.la.la-meetup {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-star-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-star-o:before {
    content: "\f005"
}

.la.la-remove:before {
    content: "\f00d"
}

.la.la-close:before {
    content: "\f00d"
}

.la.la-gear:before {
    content: "\f013"
}

.la.la-trash-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-trash-o:before {
    content: "\f2ed"
}

.la.la-file-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-file-o:before {
    content: "\f15b"
}

.la.la-clock-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-clock-o:before {
    content: "\f017"
}

.la.la-arrow-circle-o-down {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-arrow-circle-o-down:before {
    content: "\f358"
}

.la.la-arrow-circle-o-up {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-arrow-circle-o-up:before {
    content: "\f35b"
}

.la.la-play-circle-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-play-circle-o:before {
    content: "\f144"
}

.la.la-repeat:before {
    content: "\f01e"
}

.la.la-rotate-right:before {
    content: "\f01e"
}

.la.la-refresh:before {
    content: "\f021"
}

.la.la-list-alt {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-dedent:before {
    content: "\f03b"
}

.la.la-video-camera:before {
    content: "\f03d"
}

.la.la-picture-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-picture-o:before {
    content: "\f03e"
}

.la.la-photo {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-photo:before {
    content: "\f03e"
}

.la.la-image {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-image:before {
    content: "\f03e"
}

.la.la-pencil:before {
    content: "\f303"
}

.la.la-map-marker:before {
    content: "\f3c5"
}

.la.la-pencil-square-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-pencil-square-o:before {
    content: "\f044"
}

.la.la-share-square-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-share-square-o:before {
    content: "\f14d"
}

.la.la-check-square-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-check-square-o:before {
    content: "\f14a"
}

.la.la-arrows:before {
    content: "\f0b2"
}

.la.la-times-circle-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-times-circle-o:before {
    content: "\f057"
}

.la.la-check-circle-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-check-circle-o:before {
    content: "\f058"
}

.la.la-mail-forward:before {
    content: "\f064"
}

.la.la-eye {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-eye-slash {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-warning:before {
    content: "\f071"
}

.la.la-calendar:before {
    content: "\f073"
}

.la.la-arrows-v:before {
    content: "\f338"
}

.la.la-arrows-h:before {
    content: "\f337"
}

.la.la-bar-chart {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-bar-chart:before {
    content: "\f080"
}

.la.la-bar-chart-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-bar-chart-o:before {
    content: "\f080"
}

.la.la-twitter-square {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-facebook-square {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-gears:before {
    content: "\f085"
}

.la.la-thumbs-o-up {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-thumbs-o-up:before {
    content: "\f164"
}

.la.la-thumbs-o-down {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-thumbs-o-down:before {
    content: "\f165"
}

.la.la-heart-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-heart-o:before {
    content: "\f004"
}

.la.la-sign-out:before {
    content: "\f2f5"
}

.la.la-linkedin-square {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-linkedin-square:before {
    content: "\f08c"
}

.la.la-thumb-tack:before {
    content: "\f08d"
}

.la.la-external-link:before {
    content: "\f35d"
}

.la.la-sign-in:before {
    content: "\f2f6"
}

.la.la-github-square {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-lemon-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-lemon-o:before {
    content: "\f094"
}

.la.la-square-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-square-o:before {
    content: "\f0c8"
}

.la.la-bookmark-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-bookmark-o:before {
    content: "\f02e"
}

.la.la-twitter {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-facebook {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-facebook:before {
    content: "\f39e"
}

.la.la-facebook-f {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-facebook-f:before {
    content: "\f39e"
}

.la.la-github {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-credit-card {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-feed:before {
    content: "\f09e"
}

.la.la-hdd-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-hdd-o:before {
    content: "\f0a0"
}

.la.la-hand-o-right {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-hand-o-right:before {
    content: "\f0a4"
}

.la.la-hand-o-left {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-hand-o-left:before {
    content: "\f0a5"
}

.la.la-hand-o-up {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-hand-o-up:before {
    content: "\f0a6"
}

.la.la-hand-o-down {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-hand-o-down:before {
    content: "\f0a7"
}

.la.la-arrows-alt:before {
    content: "\f31e"
}

.la.la-group:before {
    content: "\f0c0"
}

.la.la-chain:before {
    content: "\f0c1"
}

.la.la-scissors:before {
    content: "\f0c4"
}

.la.la-files-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-files-o:before {
    content: "\f0c5"
}

.la.la-floppy-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-floppy-o:before {
    content: "\f0c7"
}

.la.la-navicon:before {
    content: "\f0c9"
}

.la.la-reorder:before {
    content: "\f0c9"
}

.la.la-pinterest {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-pinterest-square {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-google-plus-square {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-google-plus {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-google-plus:before {
    content: "\f0d5"
}

.la.la-money {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-money:before {
    content: "\f3d1"
}

.la.la-unsorted:before {
    content: "\f0dc"
}

.la.la-sort-desc:before {
    content: "\f0dd"
}

.la.la-sort-asc:before {
    content: "\f0de"
}

.la.la-linkedin {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-linkedin:before {
    content: "\f0e1"
}

.la.la-rotate-left:before {
    content: "\f0e2"
}

.la.la-legal:before {
    content: "\f0e3"
}

.la.la-tachometer:before {
    content: "\f3fd"
}

.la.la-dashboard:before {
    content: "\f3fd"
}

.la.la-comment-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-comment-o:before {
    content: "\f075"
}

.la.la-comments-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-comments-o:before {
    content: "\f086"
}

.la.la-flash:before {
    content: "\f0e7"
}

.la.la-clipboard {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-paste {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-paste:before {
    content: "\f328"
}

.la.la-lightbulb-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-lightbulb-o:before {
    content: "\f0eb"
}

.la.la-exchange:before {
    content: "\f362"
}

.la.la-cloud-download:before {
    content: "\f381"
}

.la.la-cloud-upload:before {
    content: "\f382"
}

.la.la-bell-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-bell-o:before {
    content: "\f0f3"
}

.la.la-cutlery:before {
    content: "\f2e7"
}

.la.la-file-text-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-file-text-o:before {
    content: "\f15c"
}

.la.la-building-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-building-o:before {
    content: "\f1ad"
}

.la.la-hospital-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-hospital-o:before {
    content: "\f0f8"
}

.la.la-tablet:before {
    content: "\f3fa"
}

.la.la-mobile:before {
    content: "\f3cd"
}

.la.la-mobile-phone:before {
    content: "\f3cd"
}

.la.la-circle-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-circle-o:before {
    content: "\f111"
}

.la.la-mail-reply:before {
    content: "\f3e5"
}

.la.la-github-alt {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-folder-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-folder-o:before {
    content: "\f07b"
}

.la.la-folder-open-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-folder-open-o:before {
    content: "\f07c"
}

.la.la-smile-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-smile-o:before {
    content: "\f118"
}

.la.la-frown-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-frown-o:before {
    content: "\f119"
}

.la.la-meh-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-meh-o:before {
    content: "\f11a"
}

.la.la-keyboard-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-keyboard-o:before {
    content: "\f11c"
}

.la.la-flag-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-flag-o:before {
    content: "\f024"
}

.la.la-mail-reply-all:before {
    content: "\f122"
}

.la.la-star-half-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-star-half-o:before {
    content: "\f089"
}

.la.la-star-half-empty {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-star-half-empty:before {
    content: "\f089"
}

.la.la-star-half-full {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-star-half-full:before {
    content: "\f089"
}

.la.la-code-fork:before {
    content: "\f126"
}

.la.la-chain-broken:before {
    content: "\f127"
}

.la.la-shield:before {
    content: "\f3ed"
}

.la.la-calendar-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-calendar-o:before {
    content: "\f133"
}

.la.la-maxcdn {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-html5 {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-css3 {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-ticket:before {
    content: "\f3ff"
}

.la.la-minus-square-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-minus-square-o:before {
    content: "\f146"
}

.la.la-level-up:before {
    content: "\f3bf"
}

.la.la-level-down:before {
    content: "\f3be"
}

.la.la-pencil-square:before {
    content: "\f14b"
}

.la.la-external-link-square:before {
    content: "\f360"
}

.la.la-compass {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-caret-square-o-down {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-caret-square-o-down:before {
    content: "\f150"
}

.la.la-toggle-down {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-toggle-down:before {
    content: "\f150"
}

.la.la-caret-square-o-up {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-caret-square-o-up:before {
    content: "\f151"
}

.la.la-toggle-up {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-toggle-up:before {
    content: "\f151"
}

.la.la-caret-square-o-right {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-caret-square-o-right:before {
    content: "\f152"
}

.la.la-toggle-right {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-toggle-right:before {
    content: "\f152"
}

.la.la-eur:before {
    content: "\f153"
}

.la.la-euro:before {
    content: "\f153"
}

.la.la-gbp:before {
    content: "\f154"
}

.la.la-usd:before {
    content: "\f155"
}

.la.la-dollar:before {
    content: "\f155"
}

.la.la-inr:before {
    content: "\f156"
}

.la.la-rupee:before {
    content: "\f156"
}

.la.la-jpy:before {
    content: "\f157"
}

.la.la-cny:before {
    content: "\f157"
}

.la.la-rmb:before {
    content: "\f157"
}

.la.la-yen:before {
    content: "\f157"
}

.la.la-rub:before {
    content: "\f158"
}

.la.la-ruble:before {
    content: "\f158"
}

.la.la-rouble:before {
    content: "\f158"
}

.la.la-krw:before {
    content: "\f159"
}

.la.la-won:before {
    content: "\f159"
}

.la.la-btc {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-bitcoin {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-bitcoin:before {
    content: "\f15a"
}

.la.la-file-text:before {
    content: "\f15c"
}

.la.la-sort-alpha-asc:before {
    content: "\f15d"
}

.la.la-sort-alpha-desc:before {
    content: "\f881"
}

.la.la-sort-amount-asc:before {
    content: "\f160"
}

.la.la-sort-amount-desc:before {
    content: "\f884"
}

.la.la-sort-numeric-asc:before {
    content: "\f162"
}

.la.la-sort-numeric-desc:before {
    content: "\f886"
}

.la.la-youtube-square {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-youtube {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-xing {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-xing-square {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-youtube-play {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-youtube-play:before {
    content: "\f167"
}

.la.la-dropbox {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-stack-overflow {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-instagram {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-flickr {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-adn {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-bitbucket {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-bitbucket-square {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-bitbucket-square:before {
    content: "\f171"
}

.la.la-tumblr {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-tumblr-square {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-long-arrow-down:before {
    content: "\f309"
}

.la.la-long-arrow-up:before {
    content: "\f30c"
}

.la.la-long-arrow-left:before {
    content: "\f30a"
}

.la.la-long-arrow-right:before {
    content: "\f30b"
}

.la.la-apple {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-windows {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-android {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-linux {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-dribbble {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-skype {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-foursquare {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-trello {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-gratipay {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-gittip {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-gittip:before {
    content: "\f184"
}

.la.la-sun-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-sun-o:before {
    content: "\f185"
}

.la.la-moon-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-moon-o:before {
    content: "\f186"
}

.la.la-vk {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-weibo {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-renren {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-pagelines {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-stack-exchange {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-arrow-circle-o-right {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-arrow-circle-o-right:before {
    content: "\f35a"
}

.la.la-arrow-circle-o-left {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-arrow-circle-o-left:before {
    content: "\f359"
}

.la.la-caret-square-o-left {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-caret-square-o-left:before {
    content: "\f191"
}

.la.la-toggle-left {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-toggle-left:before {
    content: "\f191"
}

.la.la-dot-circle-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-dot-circle-o:before {
    content: "\f192"
}

.la.la-vimeo-square {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-try:before {
    content: "\f195"
}

.la.la-turkish-lira:before {
    content: "\f195"
}

.la.la-plus-square-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-plus-square-o:before {
    content: "\f0fe"
}

.la.la-slack {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-wordpress {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-openid {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-institution:before {
    content: "\f19c"
}

.la.la-bank:before {
    content: "\f19c"
}

.la.la-mortar-board:before {
    content: "\f19d"
}

.la.la-yahoo {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-google {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-reddit {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-reddit-square {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-stumbleupon-circle {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-stumbleupon {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-delicious {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-digg {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-pied-piper-pp {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-pied-piper-alt {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-drupal {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-joomla {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-spoon:before {
    content: "\f2e5"
}

.la.la-behance {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-behance-square {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-steam {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-steam-square {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-automobile:before {
    content: "\f1b9"
}

.la.la-cab:before {
    content: "\f1ba"
}

.la.la-envelope-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-envelope-o:before {
    content: "\f0e0"
}

.la.la-deviantart {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-soundcloud {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-file-pdf-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-file-pdf-o:before {
    content: "\f1c1"
}

.la.la-file-word-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-file-word-o:before {
    content: "\f1c2"
}

.la.la-file-excel-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-file-excel-o:before {
    content: "\f1c3"
}

.la.la-file-powerpoint-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-file-powerpoint-o:before {
    content: "\f1c4"
}

.la.la-file-image-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-file-image-o:before {
    content: "\f1c5"
}

.la.la-file-photo-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-file-photo-o:before {
    content: "\f1c5"
}

.la.la-file-picture-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-file-picture-o:before {
    content: "\f1c5"
}

.la.la-file-archive-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-file-archive-o:before {
    content: "\f1c6"
}

.la.la-file-zip-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-file-zip-o:before {
    content: "\f1c6"
}

.la.la-file-audio-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-file-audio-o:before {
    content: "\f1c7"
}

.la.la-file-sound-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-file-sound-o:before {
    content: "\f1c7"
}

.la.la-file-video-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-file-video-o:before {
    content: "\f1c8"
}

.la.la-file-movie-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-file-movie-o:before {
    content: "\f1c8"
}

.la.la-file-code-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-file-code-o:before {
    content: "\f1c9"
}

.la.la-vine {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-codepen {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-jsfiddle {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-life-ring {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-life-bouy {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-life-bouy:before {
    content: "\f1cd"
}

.la.la-life-buoy {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-life-buoy:before {
    content: "\f1cd"
}

.la.la-life-saver {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-life-saver:before {
    content: "\f1cd"
}

.la.la-support {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-support:before {
    content: "\f1cd"
}

.la.la-circle-o-notch:before {
    content: "\f1ce"
}

.la.la-rebel {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-ra {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-ra:before {
    content: "\f1d0"
}

.la.la-resistance {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-resistance:before {
    content: "\f1d0"
}

.la.la-empire {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-ge {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-ge:before {
    content: "\f1d1"
}

.la.la-git-square {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-git {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-hacker-news {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-y-combinator-square {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-y-combinator-square:before {
    content: "\f1d4"
}

.la.la-yc-square {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-yc-square:before {
    content: "\f1d4"
}

.la.la-tencent-weibo {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-qq {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-weixin {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-wechat {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-wechat:before {
    content: "\f1d7"
}

.la.la-send:before {
    content: "\f1d8"
}

.la.la-paper-plane-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-paper-plane-o:before {
    content: "\f1d8"
}

.la.la-send-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-send-o:before {
    content: "\f1d8"
}

.la.la-circle-thin {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-circle-thin:before {
    content: "\f111"
}

.la.la-header:before {
    content: "\f1dc"
}

.la.la-sliders:before {
    content: "\f1de"
}

.la.la-futbol-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-futbol-o:before {
    content: "\f1e3"
}

.la.la-soccer-ball-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-soccer-ball-o:before {
    content: "\f1e3"
}

.la.la-slideshare {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-twitch {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-yelp {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-newspaper-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-newspaper-o:before {
    content: "\f1ea"
}

.la.la-paypal {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-google-wallet {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-cc-visa {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-cc-mastercard {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-cc-discover {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-cc-amex {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-cc-paypal {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-cc-stripe {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-bell-slash-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-bell-slash-o:before {
    content: "\f1f6"
}

.la.la-trash:before {
    content: "\f2ed"
}

.la.la-copyright {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-eyedropper:before {
    content: "\f1fb"
}

.la.la-area-chart:before {
    content: "\f1fe"
}

.la.la-pie-chart:before {
    content: "\f200"
}

.la.la-line-chart:before {
    content: "\f201"
}

.la.la-lastfm {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-lastfm-square {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-ioxhost {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-angellist {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-cc {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-cc:before {
    content: "\f20a"
}

.la.la-ils:before {
    content: "\f20b"
}

.la.la-shekel:before {
    content: "\f20b"
}

.la.la-sheqel:before {
    content: "\f20b"
}

.la.la-meanpath {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-meanpath:before {
    content: "\f2b4"
}

.la.la-buysellads {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-connectdevelop {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-dashcube {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-forumbee {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-leanpub {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-sellsy {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-shirtsinbulk {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-simplybuilt {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-skyatlas {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-diamond {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-diamond:before {
    content: "\f3a5"
}

.la.la-intersex:before {
    content: "\f224"
}

.la.la-facebook-official {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-facebook-official:before {
    content: "\f09a"
}

.la.la-pinterest-p {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-whatsapp {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-hotel:before {
    content: "\f236"
}

.la.la-viacoin {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-medium {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-y-combinator {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-yc {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-yc:before {
    content: "\f23b"
}

.la.la-optin-monster {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-opencart {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-expeditedssl {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-battery-4:before {
    content: "\f240"
}

.la.la-battery:before {
    content: "\f240"
}

.la.la-battery-3:before {
    content: "\f241"
}

.la.la-battery-2:before {
    content: "\f242"
}

.la.la-battery-1:before {
    content: "\f243"
}

.la.la-battery-0:before {
    content: "\f244"
}

.la.la-object-group {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-object-ungroup {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-sticky-note-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-sticky-note-o:before {
    content: "\f249"
}

.la.la-cc-jcb {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-cc-diners-club {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-clone {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-hourglass-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-hourglass-o:before {
    content: "\f254"
}

.la.la-hourglass-1:before {
    content: "\f251"
}

.la.la-hourglass-2:before {
    content: "\f252"
}

.la.la-hourglass-3:before {
    content: "\f253"
}

.la.la-hand-rock-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-hand-rock-o:before {
    content: "\f255"
}

.la.la-hand-grab-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-hand-grab-o:before {
    content: "\f255"
}

.la.la-hand-paper-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-hand-paper-o:before {
    content: "\f256"
}

.la.la-hand-stop-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-hand-stop-o:before {
    content: "\f256"
}

.la.la-hand-scissors-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-hand-scissors-o:before {
    content: "\f257"
}

.la.la-hand-lizard-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-hand-lizard-o:before {
    content: "\f258"
}

.la.la-hand-spock-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-hand-spock-o:before {
    content: "\f259"
}

.la.la-hand-pointer-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-hand-pointer-o:before {
    content: "\f25a"
}

.la.la-hand-peace-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-hand-peace-o:before {
    content: "\f25b"
}

.la.la-registered {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-creative-commons {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-gg {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-gg-circle {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-tripadvisor {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-odnoklassniki {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-odnoklassniki-square {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-get-pocket {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-wikipedia-w {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-safari {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-chrome {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-firefox {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-opera {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-internet-explorer {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-television:before {
    content: "\f26c"
}

.la.la-contao {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-500px {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-amazon {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-calendar-plus-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-calendar-plus-o:before {
    content: "\f271"
}

.la.la-calendar-minus-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-calendar-minus-o:before {
    content: "\f272"
}

.la.la-calendar-times-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-calendar-times-o:before {
    content: "\f273"
}

.la.la-calendar-check-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-calendar-check-o:before {
    content: "\f274"
}

.la.la-map-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-map-o:before {
    content: "\f279"
}

.la.la-commenting:before {
    content: "\f4ad"
}

.la.la-commenting-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-commenting-o:before {
    content: "\f4ad"
}

.la.la-houzz {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-vimeo {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-vimeo:before {
    content: "\f27d"
}

.la.la-black-tie {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-fonticons {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-reddit-alien {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-edge {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-credit-card-alt:before {
    content: "\f09d"
}

.la.la-codiepie {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-modx {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-fort-awesome {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-usb {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-product-hunt {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-mixcloud {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-scribd {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-pause-circle-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-pause-circle-o:before {
    content: "\f28b"
}

.la.la-stop-circle-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-stop-circle-o:before {
    content: "\f28d"
}

.la.la-bluetooth {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-bluetooth-b {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-gitlab {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-wpbeginner {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-wpforms {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-envira {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-wheelchair-alt {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-wheelchair-alt:before {
    content: "\f368"
}

.la.la-question-circle-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-question-circle-o:before {
    content: "\f059"
}

.la.la-volume-control-phone:before {
    content: "\f2a0"
}

.la.la-asl-interpreting:before {
    content: "\f2a3"
}

.la.la-deafness:before {
    content: "\f2a4"
}

.la.la-hard-of-hearing:before {
    content: "\f2a4"
}

.la.la-glide {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-glide-g {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-signing:before {
    content: "\f2a7"
}

.la.la-viadeo {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-viadeo-square {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-snapchat {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-snapchat-ghost {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-snapchat-square {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-pied-piper {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-first-order {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-yoast {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-themeisle {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-google-plus-official {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-google-plus-official:before {
    content: "\f2b3"
}

.la.la-google-plus-circle {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-google-plus-circle:before {
    content: "\f2b3"
}

.la.la-font-awesome {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-fa {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-fa:before {
    content: "\f2b4"
}

.la.la-handshake-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-handshake-o:before {
    content: "\f2b5"
}

.la.la-envelope-open-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-envelope-open-o:before {
    content: "\f2b6"
}

.la.la-linode {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-address-book-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-address-book-o:before {
    content: "\f2b9"
}

.la.la-vcard:before {
    content: "\f2bb"
}

.la.la-address-card-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-address-card-o:before {
    content: "\f2bb"
}

.la.la-vcard-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-vcard-o:before {
    content: "\f2bb"
}

.la.la-user-circle-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-user-circle-o:before {
    content: "\f2bd"
}

.la.la-user-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-user-o:before {
    content: "\f007"
}

.la.la-id-badge {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-drivers-license:before {
    content: "\f2c2"
}

.la.la-id-card-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-id-card-o:before {
    content: "\f2c2"
}

.la.la-drivers-license-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-drivers-license-o:before {
    content: "\f2c2"
}

.la.la-quora {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-free-code-camp {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-telegram {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-thermometer-4:before {
    content: "\f2c7"
}

.la.la-thermometer:before {
    content: "\f2c7"
}

.la.la-thermometer-3:before {
    content: "\f2c8"
}

.la.la-thermometer-2:before {
    content: "\f2c9"
}

.la.la-thermometer-1:before {
    content: "\f2ca"
}

.la.la-thermometer-0:before {
    content: "\f2cb"
}

.la.la-bathtub:before {
    content: "\f2cd"
}

.la.la-s15:before {
    content: "\f2cd"
}

.la.la-window-maximize {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-window-restore {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-times-rectangle:before {
    content: "\f410"
}

.la.la-window-close-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-window-close-o:before {
    content: "\f410"
}

.la.la-times-rectangle-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-times-rectangle-o:before {
    content: "\f410"
}

.la.la-bandcamp {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-grav {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-etsy {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-imdb {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-ravelry {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-eercast {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-eercast:before {
    content: "\f2da"
}

.la.la-snowflake-o {
    font-family: 'Line Awesome Free';
    font-weight: 400
}

.la.la-snowflake-o:before {
    content: "\f2dc"
}

.la.la-superpowers {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-wpexplorer {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}

.la.la-spotify {
    font-family: 'Line Awesome Brands';
    font-weight: 400
}
@keyframes loader {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        border: 4px solid #f44336;
        border-left-color: transparent;
    }

    50% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
        border: 4px solid #673ab7;
        border-left-color: transparent;
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
        border: 4px solid #f44336;
        border-left-color: transparent;
    }
}

@-webkit-keyframes loader {
    0% {
        -webkit-transform: rotate(0deg);
        border: 4px solid #f44336;
        border-left-color: transparent;
    }

    50% {
        -webkit-transform: rotate(180deg);
        border: 4px solid #673ab7;
        border-left-color: transparent;
    }

    100% {
        -webkit-transform: rotate(360deg);
        border: 4px solid #f44336;
        border-left-color: transparent;
    }
}

/* Indicator styles */


/* Progress indicator */

.ipl-progress-indicator.available {
    opacity: 0
}

.ipl-progress-indicator {
    width: 100%;
    height: 100%;
    top: 0;
    position: fixed;
    opacity: 1;
    pointer-events: none;
    transition: opacity cubic-bezier(.4, 0, .2, 1) 436ms;
    z-index: 9999;
}

.ipl-progress-indicator-head {
    background-color: #c6dafc;
    height: 5px;
    overflow: hidden;
    position: relative
}

.ipl-progress-indicator .first-indicator,
.ipl-progress-indicator .second-indicator {
    background-color: #36a8ff;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    position: absolute;
    -webkit-transform-origin: left center;
    transform-origin: left center;
    -webkit-transform: scaleX(0);
    transform: scaleX(0)
}

.ipl-progress-indicator .first-indicator {
    -webkit-animation: first-indicator 2s linear infinite;
    animation: first-indicator 2s linear infinite
}

.ipl-progress-indicator .second-indicator {
    -webkit-animation: second-indicator 2s linear infinite;
    animation: second-indicator 2s linear infinite
}

.ipl-progress-indicator .insp-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
    border-radius: 50%;
    align-self: center
}

@-webkit-keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0);
                transform: rotate(0)
    }

    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg)
    }
}

@keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0);
                transform: rotate(0)
    }

    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg)
    }
}

@-webkit-keyframes fadein {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@keyframes fadein {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@-webkit-keyframes first-indicator {
    0% {
        -webkit-transform: translate(0) scaleX(0);
                transform: translate(0) scaleX(0)
    }

    25% {
        -webkit-transform: translate(0) scaleX(.5);
                transform: translate(0) scaleX(.5)
    }

    50% {
        -webkit-transform: translate(25%) scaleX(.75);
                transform: translate(25%) scaleX(.75)
    }

    75% {
        -webkit-transform: translate(100%) scaleX(0);
                transform: translate(100%) scaleX(0)
    }

    100% {
        -webkit-transform: translate(100%) scaleX(0);
                transform: translate(100%) scaleX(0)
    }
}

@keyframes first-indicator {
    0% {
        -webkit-transform: translate(0) scaleX(0);
                transform: translate(0) scaleX(0)
    }

    25% {
        -webkit-transform: translate(0) scaleX(.5);
                transform: translate(0) scaleX(.5)
    }

    50% {
        -webkit-transform: translate(25%) scaleX(.75);
                transform: translate(25%) scaleX(.75)
    }

    75% {
        -webkit-transform: translate(100%) scaleX(0);
                transform: translate(100%) scaleX(0)
    }

    100% {
        -webkit-transform: translate(100%) scaleX(0);
                transform: translate(100%) scaleX(0)
    }
}

@-webkit-keyframes second-indicator {
    0% {
        -webkit-transform: translate(0) scaleX(0);
                transform: translate(0) scaleX(0)
    }

    60% {
        -webkit-transform: translate(0) scaleX(0);
                transform: translate(0) scaleX(0)
    }

    80% {
        -webkit-transform: translate(0) scaleX(.6);
                transform: translate(0) scaleX(.6)
    }

    100% {
        -webkit-transform: translate(100%) scaleX(.1);
                transform: translate(100%) scaleX(.1)
    }
}

@keyframes second-indicator {
    0% {
        -webkit-transform: translate(0) scaleX(0);
                transform: translate(0) scaleX(0)
    }

    60% {
        -webkit-transform: translate(0) scaleX(0);
                transform: translate(0) scaleX(0)
    }

    80% {
        -webkit-transform: translate(0) scaleX(.6);
                transform: translate(0) scaleX(.6)
    }

    100% {
        -webkit-transform: translate(100%) scaleX(.1);
                transform: translate(100%) scaleX(.1)
    }
}
.App {
  font-family: sans-serif;
  padding: 30px;
}

.audio-controls-container {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: center;
  margin-bottom: 16px;
}

.audio-icon-button {
  cursor: pointer;
  outline: none;
  color: rgb(30, 38, 46);
}

.toggle-button-container {
  height: 20px;
  margin-right: 10px;
  display: inline-block;
  position: relative;
}

.toggle-button-container>* {
  display: block;
  height: 20px;
  top: 0;
  left: 0;
}

.progress-bar-container {
  width: 100%;
  border-radius: 3px;
  position: relative;
  background-color: #7d8087;
}

.progress-bar-container .progress-bar {
  background-color: #25a56a;
  border-radius: 6px;
  height: 6px;
}

.progress-bar-container .progress-bar-caret {
  cursor: pointer;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
          transform: translateY(-50%) translateX(-50%);
  transition: 0.2s ease-out transform;
  background-color: #ffffff;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  border: 1px solid #ffffff;
}

.progress-bar-container .progress-bar-caret:hover {
  -webkit-transform: translateY(-50%) translateX(-50%) scale(1.2);
          transform: translateY(-50%) translateX(-50%) scale(1.2);
}

.time-container {
  margin: 0 15px;
  font-size: 14px;
  color: #ffffff;
  font-weight: 100;
}

.song-container {
  display: flex;
  flex-wrap: none;
}

.song-container img {
  box-shadow: 0px 3px 13px -7px rgba(0, 0, 0, 0.75);
  margin-right: 30px;
  border-radius: 3px;
  height: 100px;
}

.song-container .song-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
}

.song-container .song-title {
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 500;
}

.song-container .song-album {
  margin-bottom: 15px;
  font-weight: 100;
  color: rgb(163, 163, 163);
  text-decoration: underline;
}

@media screen and (max-width: 480px) {
  .song-container {
    flex-direction: column;
  }

  .song-container img {
    box-shadow: 0px 3px 13px -5px rgba(0, 0, 0, 0.75);
    border-radius: 6px;
    height: auto;
    width: 100%;
    margin-bottom: 20px;
  }

  .song-container .song-title {
    margin-bottom: 5px;
    font-size: 25px;
    font-weight: 600;
  }
}
/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8;
}

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden;
}

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box;
}

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
  display: none;
}

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto;
}

.mfp-ajax-cur {
  cursor: progress;
}

.mfp-zoom-out-cur,
.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: zoom-out;
}

.mfp-zoom {
  cursor: pointer;
  cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
  cursor: auto;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -ms-user-select: none;
      user-select: none;
}

.mfp-loading.mfp-figure {
  display: none;
}

.mfp-hide {
  display: none !important;
}

.mfp-preloader {
  color: #ccc;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044;
}

.mfp-preloader a {
  color: #ccc;
}

.mfp-preloader a:hover {
  color: #fff;
}

.mfp-s-ready .mfp-preloader {
  display: none;
}

.mfp-s-error .mfp-content {
  display: none;
}

button.mfp-close,
button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #fff;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace;
}

.mfp-close:hover,
.mfp-close:focus {
  opacity: 1;
}

.mfp-close:active {
  top: 1px;
}

.mfp-close-btn-in .mfp-close {
  color: #333;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #fff;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%;
}

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #ccc;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
}

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent;
}

.mfp-arrow:active {
  margin-top: -54px;
}

.mfp-arrow:hover,
.mfp-arrow:focus {
  opacity: 1;
}

.mfp-arrow:before,
.mfp-arrow:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent;
}

.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px;
}

.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7;
}

.mfp-arrow-left {
  left: 0;
}

.mfp-arrow-left:after {
  border-right: 17px solid #fff;
  margin-left: 31px;
}

.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3f3f3f;
}

.mfp-arrow-right {
  right: 0;
}

.mfp-arrow-right:after {
  border-left: 17px solid #fff;
  margin-left: 39px;
}

.mfp-arrow-right:before {
  border-left: 27px solid #3f3f3f;
}

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px;
}

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px;
}

.mfp-iframe-holder .mfp-close {
  top: -40px;
}

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
}

.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000;
}

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto;
}

/* The shadow behind the image */
.mfp-figure {
  line-height: 0;
}

.mfp-figure:after {
  content: '';
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444;
}

.mfp-figure small {
  color: #bdbdbd;
  display: block;
  font-size: 12px;
  line-height: 14px;
}

.mfp-figure figure {
  margin: 0;
}

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto;
}

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #f3f3f3;
  word-wrap: break-word;
  padding-right: 36px;
}

.mfp-image-holder .mfp-content {
  max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}

@media screen and (max-width: 800px) and (orientation: landscape),
  screen and (max-height: 300px) {
  /**
         * Remove all paddings around the image on small screen
         */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }

  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }

  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }

  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px;
  }

  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box;
  }

  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }

  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px;
  }

  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0;
  }
}

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
  }

  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0;
  }

  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%;
  }

  .mfp-container {
    padding-left: 6px;
    padding-right: 6px;
  }
}

@-webkit-keyframes rotating {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotating {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

body,
html {
  height: 100%;
}

#root {
  height: auto !important;
  min-height: 0px !important;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

body {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  background-color: #16151a;
  -webkit-font-smoothing: antialiased;
}

button {
  padding: 0;
  border: none;
  background-color: transparent;
  transition: 0.5s ease;
  transition-property: color, background-color, border-color, box-shadow;
  cursor: pointer;
}

button:focus {
  outline: 0;
}

a {
  transition: 0.5s ease;
  transition-property: color, background-color, border-color, box-shadow;
}

a:active,
a:focus,
a:hover {
  outline: 0;
  text-decoration: none;
}

input,
select {
  padding: 0;
  margin: 0;
  border-radius: 0;
  -webkit-appearance: none;
  appearance: none;
  box-shadow: none;
  transition: 0.5s ease;
  transition-property: color, background-color, border-color, box-shadow;
}

input:focus,
select:focus {
  outline: 0;
}

select::-ms-expand {
  display: none;
}

input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

::selection {
  background: #fff;
  color: #222227;
  text-shadow: none;
}

::-webkit-input-placeholder {
  color: silver;
  opacity: 1;
}

::-moz-placeholder {
  color: silver;
  opacity: 1;
}

:-moz-placeholder {
  color: silver;
  opacity: 1;
}

:-ms-input-placeholder {
  color: silver;
  opacity: 1;
}

:focus {
  outline: -webkit-focus-ring-color auto 0;
}

body::-webkit-scrollbar {
  width: 16px;
}

body::-webkit-scrollbar-track {
  background: #222227;
}

body::-webkit-scrollbar-thumb {
  background-color: silver;
  outline: 1px solid #222227;
}

:focus-visible {
  outline: 0 dotted;
}

.row--grid {
  margin-right: -10px;
  margin-left: -10px;
}

.row--grid .col-12,
.row--grid .col-6 {
  padding-left: 10px;
  padding-right: 10px;
}

@media (min-width: 768px) {
  .row--grid {
    margin-right: -15px;
    margin-left: -15px;
  }

  .row--grid .col-12,
  .row--grid .col-6 {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row--sidebar {
  margin-right: -10px;
  margin-left: -10px;
}

.row--sidebar .col-12,
.row--sidebar .col-6 {
  padding-left: 10px;
  padding-right: 10px;
}

svg {
  width: 24px;
  height: 24px;
  transition: fill 0.4s ease;
  fill: #ffffff;
}

.header {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: auto;
  height: 55px;
  background-color: #16151a;
  border-bottom: 1px solid #222227;
  z-index: 101;
}

.header__content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  position: relative;
  padding: 0 5px;
}

.header__logo {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 70px;
  width: 100%;
}

.header__logo img {
  width: 100px;
  height: auto;
  display: block;
}

.header__actions {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: auto;
  margin-left: auto;
  margin-right: 40px;
}

.header__action-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 36px;
  width: 100%;
}

.header__action-btn svg {
  width: 24px;
  height: auto;
  fill: silver;
  transition: fill 0.5s ease;
}

.header__btn {
  position: absolute;
  width: 24px;
  height: 22px;
  display: block;
  right: 15px;
  top: 18px;
}

.header__btn span {
  position: absolute;
  right: 0;
  width: 24px;
  height: 2px;
  background-color: silver;
  border-radius: 2px;
  transition: 0.5s ease;
}

.header__btn span:first-child {
  top: 0;
}

.header__btn span:nth-child(2) {
  top: 10px;
  width: 16px;
}

.header__btn span:last-child {
  top: 20px;
  width: 8px;
}

.header__btn:hover span {
  background-color: #25a56a;
}

.header__btn--active span {
  background-color: #25a56a;
}

.header__btn--active span:first-child {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  top: 9px;
}

.header__btn--active span:nth-child(2) {
  opacity: 0;
  width: 0;
}

.header__btn--active span:last-child {
  width: 24px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  top: 9px;
}

.header__search {
  position: absolute;
  left: 0;
  top: -71px;
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: #16151a;
  z-index: 1;
  padding: 0 15px;
  border-bottom: 1px solid #222227;
  transition: top 0.5s ease;
}

.header__search input {
  width: calc(100% - 30px);
  height: 40px;
  background-color: #222227;
  color: #fff;
  font-size: 16px;
  border-radius: 12px;
  border: none;
  padding: 0 20px;
  font-family: Inter, sans-serif;
}

.header__search input:focus {
  border-color: #fff;
}

.header__search button {
  position: absolute;
  right: 60px;
  top: 15px;
  height: 40px;
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.header__search button svg {
  width: 20px;
  height: auto;
  fill: silver;
  transition: fill 0.5s ease;
}

.header__search button:hover svg {
  fill: #25a56a;
}

.header__search button.close {
  right: 15px;
}

.header__search--active {
  top: 0;
}

.header__nav {
  display: none;
}

.header__product {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  position: relative;
  padding-left: 56px;
}

.header__product button {
  position: absolute;
  width: 14px;
  bottom: 5px;
  right: 0;
}

.header__product button svg {
  width: 14px;
  height: auto;
  fill: silver;
  transition: fill 0.4s ease;
}

.header__product button:hover svg {
  fill: #25a56a;
}

.header__product img {
  width: 46px;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 6px;
}

.header__product p {
  color: #fff;
  font-size: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  width: 100%;
  display: block;
  margin-bottom: 0;
  font-weight: 400;
}

.header__product p a {
  color: #fff;
}

.header__product p a:hover {
  color: #25a56a;
}

.header__product span {
  padding: 0;
  font-size: 14px;
  color: silver;
  position: relative;
  background-color: transparent;
  top: auto;
  left: auto;
  display: block;
  width: 100%;
  margin-top: 5px;
  line-height: 100%;
}

.header__product:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.header__all {
  display: none;
}

@media (min-width: 400px) {
  .header__action {
    margin-left: 20px;
  }

  .header__action:first-child {
    margin-left: 0;
  }

  .header__actions {
    margin-right: 44px;
  }
}

@media (min-width: 576px) {
  .header__action--signin {
    width: auto;
    padding-left: 22px;
  }

  .header__action--signin:before {
    content: '';
    position: absolute;
    display: block;
    width: 1px;
    height: 24px;
    background-color: #222227;
    top: 50%;
    left: 0;
    margin-top: -11px;
  }

  .header__action--signin:hover a span,
  .header__action--signin:hover button span {
    color: #fff;
  }

  .header__action--signin:hover a svg,
  .header__action--signin:hover button svg {
    fill: #25a56a;
  }

  .header__action-btn span {
    display: block;
    white-space: nowrap;
    color: silver;
    font-size: 16px;
    margin-right: 10px;
    font-family: Inter, sans-serif;
    transition: color 0.5s ease;
  }

  .header__content {
    padding: 0 30px;
  }

  .header__search {
    padding: 0 30px;
  }

  .header__search button {
    right: 75px;
  }

  .header__search button.close {
    right: 30px;
  }

  .header__btn {
    right: 30px;
  }
}

@media (min-width: 768px) {
  .header__action {
    margin-left: 0;
  }

  .header__action--signin {
    padding-left: 20px;
  }
}

@media (min-width: 1200px) {
  .header {
    position: relative;
    padding-left: 280px;
  }

  .header__btn,
  .header__logo {
    display: none;
  }

  .header__actions {
    margin-right: 0;
  }

  .header__action--search {
    display: none;
  }

  .header__search {
    position: relative;
    top: auto;
    left: auto;
    width: 320px;
    padding: 0;
    border: none;
    background-color: transparent;
  }

  .header__search input {
    padding: 0 60px 0 20px;
    width: 100%;
  }

  .header__search button {
    right: 20px;
  }

  .header__search button.close {
    display: none;
  }

  .header__search--active {
    top: auto;
  }

  .header__nav {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-right: 60px;
  }

  .header__nav a {
    font-size: 16px;
    color: silver;
    margin-right: 30px;
    font-family: Inter, sans-serif;
  }

  .header__nav a:last-child {
    margin-right: 0;
  }

  .header__nav a:hover {
    color: #25a56a;
  }

  .header__all {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.1);
    color: silver;
    font-size: 13px;
    font-weight: 400;
    font-family: Inter, sans-serif;
    margin-bottom: 15px;
    height: 24px;
    border-radius: 8px 8px 0 0;
  }

  .header__all:hover {
    color: #25a56a;
  }
}

.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #16151a;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 101;
  width: 280px;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  border-right: 1px solid #222227;
}

.sidebar__logo {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 70px;
  width: 100%;
  padding: 0 30px 0 15px;
  border-bottom: 1px solid #222227;
}

.sidebar__logo img {
  width: 100px;
  height: auto;
  display: block;
}

.sidebar__nav {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  padding: 30px 0 0;
  width: 100%;
  max-height: calc(100vh - 150px);
  overflow-y: auto;
}

.sidebar__nav__mobile {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;
  padding: 0 0;
  width: 100%;
  /* max-height: calc(100vh - 150px); */
  overflow-y: auto;
  z-index: 30;
  font-size: 14px;
}

.sidebar__nav-link {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
  color: silver;
  font-family: Inter, sans-serif;
  margin-left: 15px;
  transition: 0.4s ease;
  position: relative;
}

.sidebar__nav-link svg {
  fill: silver;
  width: 22px;
  height: auto;
  transition: fill 0.5s;
  margin-right: 12px;
  margin-bottom: 2px;
}

.sidebar__nav-link__mobile svg {
  fill: silver;
  width: 22px;
  height: auto;
  transition: fill 0.5s;
  /* margin-bottom: 5px; */
}


.sidebar__nav-link svg:last-child {
  width: 16px;
  margin-right: 0;
  margin-top: 3px;
  margin-left: 2px;
  margin-bottom: 0;
}

.sidebar__nav-link:hover {
  color: #fff;
}

.sidebar__nav-link:hover svg {
  fill: #25a56a;
}

.sidebar__nav-link--active,
.sidebar__nav-link[aria-expanded='true'] {
  color: #fff;
}

.sidebar__nav-link--active svg,
.sidebar__nav-link[aria-expanded='true'] svg {
  fill: #25a56a;
}

.sidebar__nav-link--active:hover svg,
.sidebar__nav-link[aria-expanded='true']:hover svg {
  fill: #25a56a;
}

.sidebar__nav-link[aria-expanded='true'] {
  margin-bottom: 15px;
}

.sidebar__nav-item {
  width: 100%;
  display: block;
  margin-bottom: 15px;
  position: relative;
}

.sidebar__nav-item:last-child {
  margin-bottom: 0;
}

.sidebar__nav-item__bottom {
  display: flex;
  justify-content:center;
  width: 100%;
}

.sidebar--active {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

@media (min-width: 576px) {
  .sidebar__logo {
    padding: 0 30px;
  }

  .sidebar__nav-link {
    margin-left: 30px;
  }
}

@media (min-width: 1200px) {
  .sidebar__nav__mobile {
    display: none;
  }
  .sidebar {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }

  .sidebar__nav {
    max-height: calc(100vh - 332px);
  }
}

.player {
  position: fixed;
  z-index: 102;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #16151a;
  padding: 30px 15px 20px 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-top: 1px solid #222227;
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}

.player__cover {
  width: 100%;
  max-width: 90px;
}

.player__cover img {
  width: 90px;
  height: 90px;
  object-fit: cover;
  display: block;
  border-radius: 12px;
  margin-bottom: 10px;
}

.player__track {
  font-size: 16px;
  line-height: 24px;
  display: block;
  width: 100%;
  text-align: center;
  font-weight: 400;
  color: silver;
  margin-bottom: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}

.player__track b {
  font-weight: 600;
  color: #fff;
}

.player__content {
  width: 100%;
}

.player__btn {
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #16151a;
  border-radius: 12px 12px 0 0;
  height: 40px;
  width: 90px;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  z-index: 103;
  color: #fff;
  font-size: 14px;
  border: 1px solid #222227;
  border-bottom: none;
}

.player__btn svg {
  width: 18px;
  height: auto;
  fill: #25a56a;
  margin-right: 8px;
}

.player--active {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

@media (min-width: 1200px) {
  .player {
    width: 278px;
    padding: 20px 30px;
  }
}

.plyr__controls {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.plyr__actions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.plyr__actions button {
  margin: 0 5px;
}

.plyr__time {
  margin-left: 10px;
  color: silver;
  font-size: 14px;
  line-height: 100%;
}

.plyr--audio .plyr__controls {
  padding: 0;
  color: #fff;
  background-color: transparent;
}

.main {
  flex: 1 1;
  position: relative;
  margin-top: 70px;
  padding: 20px 0 60px;
}

.main__title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.main__title h1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: Inter, sans-serif;
  font-weight: 400;
  color: #fff;
  font-size: 30px;
  line-height: 140%;
  margin-bottom: 0;
  position: relative;
  text-transform: capitalize;
}

.main__title h1 b {
  font-weight: 500;
}

.main__title h1 a {
  color: #fff;
}

.main__title h1 a:hover {
  color: #25a56a;
}

.main__title h2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  font-weight: 400;
  color: #fff;
  font-size: 26px;
  line-height: 100%;
  margin-bottom: 0;
  position: relative;
}

.main__title h2 svg {
  width: 26px;
  height: auto;
  fill: #25a56a;
  margin-right: 10px;
}

.main__title h2 b {
  font-weight: 500;
}

.main__title h2 a {
  color: #fff;
}

.main__title h2 a:hover {
  color: #25a56a;
}

.main__title h3 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: Inter, sans-serif;
  font-weight: 400;
  font-size: 24px;
  color: #fff;
  margin-bottom: 0;
  line-height: 100%;
  position: relative;
}

.main__title p {
  display: block;
  width: 100%;
  font-size: 16px;
  line-height: 26px;
  color: silver;
  margin-top: 20px;
  margin-bottom: 0;
}

.main__title p b {
  font-weight: 600;
}

.main__title p a {
  color: #25a56a;
  text-decoration: underline;
}

.main__title p a:hover {
  text-decoration: none;
}

.main__link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: silver;
  font-weight: 400;
}

.main__link svg {
  width: 22px;
  height: auto;
  fill: silver;
  margin-left: 5px;
  transition: fill 0.5s ease;
}

.main__link:hover {
  color: #fff;
}

.main__link:hover svg {
  fill: #25a56a;
}

.main__nav {
  display: none;
}

@media (min-width: 576px) {
  .main {
    padding: 20px 15px 50px;
  }
}

@media (min-width: 768px) {
  .main {
    padding: 30px 15px 70px;
  }

  .main__title {
    margin-top: 70px;
    margin-bottom: 0;
  }

  .main__title h1 {
    font-size: 36px;
  }

  .main__title h2 {
    font-size: 30px;
  }

  .main__title h2 svg {
    width: 30px;
  }

  .main__title p:last-child {
    margin-bottom: 10px;
  }

  .main__title--sidebar {
    margin-bottom: 10px;
  }

  .main__list {
    margin-top: 0;
  }
}

@media (min-width: 992px) {
  .main__title--sidebar {
    margin-top: 30px;
  }
}

@media (min-width: 1200px) {
  .main {
    padding: 0 15px 70px 295px;
    margin-top: 0;
  }

  .main__nav {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 34px;
    height: 34px;
    right: 0;
    bottom: -14px;
    z-index: 2;
  }

  .main__nav svg {
    width: 30px;
    height: auto;
    fill: silver;
    transition: fill 0.5s ease;
  }

  .main__nav:hover svg {
    fill: #25a56a;
  }

  .main__nav--hero {
    bottom: 15px;
  }

  .main__nav--hero svg {
    fill: #fff;
  }

  .main__nav--hero:hover svg {
    fill: #25a56a;
  }
}


/*==============================
	Profile
==============================*/
.profile {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #16151a;
  padding: 20px 20px 0;
  border-radius: 12px;
  position: relative;
  margin-top: 20px;
  border: 1px solid #222227;
}

.profile__tabs {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  width: 100%;
}

.profile__tabs li {
  margin-right: 20px;
}

.profile__tabs li:last-child {
  margin-right: 0;
}

.profile__tabs span {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  height: 40px;
  color: #c0c0c0;
  position: relative;
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  cursor: pointer;
}

.profile__tabs span:hover {
  color: #fff;
}

.profile__tabs span:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  display: block;
  background-color: #25a56a;
  transition: 0.5s ease;
  -webkit-transform: translateY(2px);
          transform: translateY(2px);
  opacity: 0;
  border-radius: 2px 2px 0 0;
}

.profile__tabs span.active {
  color: #fff;
  cursor: default;
}

.profile__tabs span.active:before {
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.profile__logout {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: auto;
}

.profile__logout span {
  font-size: 16px;
  color: #c0c0c0;
  margin-right: 10px;
  transition: 0.5s ease;
  font-family: 'Inter', sans-serif;
}

.profile__logout svg {
  fill: #c0c0c0;
  width: 24px;
  height: auto;
  transition: fill 0.5s ease;
}

.profile__logout:hover span {
  color: #fff;
}

.profile__logout:hover svg {
  fill: #25a56a;
}

.profile__user {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
}

.profile__meta {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.profile__meta h3 {
  color: #fff;
  font-family: 'Inter', sans-serif;
  display: block;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 7px;
  line-height: 100%;
}

.profile__meta span {
  color: #c0c0c0;
  font-size: 12px;
  line-height: 100%;
}

.profile__avatar {
  display: block;
  position: relative;
  width: 40px;
  overflow: hidden;
  border-radius: 8px;
  margin-right: 15px;
}

.profile__avatar img {
  width: 100%;
}

@media (min-width: 768px) {
  .profile {
    margin-top: 30px;
    padding: 0 20px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .profile__tabs {
    border-bottom: none;
    margin-bottom: 0;
    width: auto;
    margin-right: auto;
  }

  .profile__tabs li {
    margin-right: 30px;
  }

  .profile__tabs li:last-child {
    margin-right: 0;
  }

  .profile__tabs span {
    align-items: center;
    height: 70px;
    font-size: 16px;
  }

  .profile__user {
    margin-bottom: 0;
    margin-right: 60px;
  }

}

.hero {
  display: block;
  width: 100%;
}

.hero__slide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  min-height: 300px;
  border-radius: 12px;
  overflow: hidden;
  padding: 40px 20px;
  position: relative;
}

.hero__slide:before {
  content: '';
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg,
      rgba(0, 0, 0, 0.7) 0,
      rgba(0, 0, 0, 0) 100%);
  z-index: 1;
}

.hero__title {
  position: relative;
  z-index: 2;
  color: #fff;
  font-weight: 400;
  font-size: 30px;
  font-family: Inter, sans-serif;
  margin-bottom: 0;
  line-height: 130%;
}

.hero__text {
  position: relative;
  z-index: 2;
  color: silver;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 0;
  margin-top: 15px;
}

.hero__btns {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 10px;
}

.hero__btn {
  width: auto;
  min-width: 120px;
  height: 50px;
  border-radius: 12px;
  background-color: #222227;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 14px;
  position: relative;
  z-index: 2;
  text-transform: uppercase;
  font-family: Inter, sans-serif;
  font-weight: 500;
  padding: 0 20px;
  margin-top: 20px;
  margin-right: 20px;
}

.hero__btn svg {
  fill: #fff;
  width: 28px;
  height: auto;
  margin-right: 7px;
}

.hero__btn:last-child {
  margin-right: 0;
}

.hero__btn:hover {
  color: #25a56a;
}

.hero__btn--video {
  text-transform: none;
  font-size: 16px;
}

.hero__btn--video svg {
  fill: #25a56a;
  width: 28px;
  height: auto;
  margin-right: 10px;
  transition: fill 0.5s ease;
}

.hero__btn--video:hover {
  background-color: #25a56a;
  color: #fff;
}

.hero__btn--video:hover svg {
  fill: #fff;
}

.hero__btn--green {
  background-color: #25a56a;
  color: #fff;
}

.hero__btn--green:hover {
  background-color: #222227;
  color: #25a56a;
}

@media (min-width: 576px) {
  .hero__slide {
    min-height: 400px;
  }
}

@media (min-width: 768px) {
  .hero__slide {
    padding: 40px 30px;
    padding-right: 10%;
    min-height: 460px;
  }

  .hero__title {
    font-size: 36px;
  }

  .hero__btn {
    margin-top: 30px;
    margin-right: 30px;
    min-width: 160px;
    padding: 0 25px;
  }

  .hero__btn:last-child {
    margin-right: 0;
  }
}

@media (min-width: 992px) {
  .hero__slide {
    padding-right: 25%;
  }
}

@media (min-width: 1200px) {
  .hero {
    margin-top: 30px;
  }

  .hero__title {
    font-size: 44px;
  }

  .hero__text {
    font-size: 17px;
    line-height: 28px;
  }

  .hero__btn {
    margin-top: 40px;
  }

  .hero__slide {
    padding: 60px;
    padding-right: 33%;
  }
}

@media (min-width: 1900px) {
  .hero__slide {
    padding-right: 50%;
  }
}

.album {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 20px;
  width: 100%;
}

.album__cover {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  position: relative;
  overflow: hidden;
  width: 100%;
  background-color: #000;
}

.album__cover a {
  position: relative;
  width: 100%;
}

.album__cover a:not(a.search-item-link):after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.album__cover img {
  width: 100%;
  display: block;
  position: relative;
  z-index: 1;
  opacity: 1;
  transition: .5s ease;
}

.album__cover img:not(img.search-item-img) {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.play-icon {
  position: absolute;
  z-index: 20;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  top: 50%;
  left: 50%;
  margin: -28px 0 0 -28px;
  border-radius: 12px;
  background-color: #222227;
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
  opacity: 0;
  transition: 0.5s ease;
  border: 1px solid rgba(255, 255, 255, 0.05);
  cursor: pointer;
}

.play-icon svg {
  fill: #fff;
  width: 24px;
  height: auto;
  margin: 1px 0 0 1px;
  transition: fill 0.5s ease;
}

.play-icon:hover svg {
  fill: #25a56a;
}

.album__cover:hover img {
  opacity: 0.6;
  -webkit-transform: scale(1.08);
          transform: scale(1.08);
}

.album__cover:hover .play-icon {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
}

.album__cover:hover .album__stat {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
}

.album__title {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-top: 5px;
}

.album__title h3 {
  display: block;
  width: 100%;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}

.album__title h3 a {
  color: #fff;
}

.album__title h3 a:hover {
  color: #25a56a;
}

.album__title span {
  display: block;
  width: 100%;
  color: silver;
  font-size: 14px;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}

.album__title span a {
  color: silver;
}

.album__title span a:hover {
  color: #25a56a;
}

.album__stat {
  position: absolute;
  z-index: 2;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  bottom: 20px;
  left: 0;
  right: 0;
  color: #fff;
  font-size: 12px;
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
  opacity: 0;
  transition: 0.5s ease;
}

.album__stat span {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-right: 15px;
}

.album__stat span:last-child {
  margin-right: 0;
}

.album__stat svg {
  width: 14px;
  height: auto;
  fill: #fff;
  margin-right: 5px;
}

@media (min-width: 768px) {
  .album {
    margin-top: 30px;
  }

  .album--sidebar {
    margin-top: 20px;
  }

  .album__stat {
    font-size: 13px;
  }

  .album__stat span {
    margin-right: 20px;
  }

  .album__stat span:last-child {
    margin-right: 0;
  }

  .album__stat svg {
    width: 16px;
  }
}

.release {
  margin-top: 20px;
  border: 1px solid #222227;
  background-color: #16151a;
  padding: 20px;
  border-radius: 12px;
  position: release;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.release__cover {
  display: block;
  position: relative;
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
}

.release__cover img {
  width: 100%;
}

.release__list {
  position: relative;
  width: 100%;
  margin-top: 20px;
}

.release__list ul {
  height: 330px;
  overflow-y: auto;
  padding-right: 14px;
}

.release__list ul::-webkit-scrollbar {
  width: 4px;
  border-radius: 4px;
}

.release__list ul::-webkit-scrollbar-track {
  background: #222227;
  border-radius: 4px;
}

.release__list ul::-webkit-scrollbar-thumb {
  background: #25a56a;
  border-radius: 4px;
}

.release__list ul::-webkit-scrollbar-thumb:hover {
  background: #222227;
  border-radius: 4px;
}

.release__stat {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: silver;
  font-size: 14px;
  transition: 0.5s ease;
  width: 100%;
  margin-top: 15px;
}

.release__stat span {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-right: 20px;
}

.release__stat span:last-child {
  margin-right: 0;
}

.release__stat svg {
  width: 20px;
  height: auto;
  fill: #25a56a;
  margin-right: 5px;
}

.release__buy {
  width: 100%;
  height: 50px;
  border-radius: 12px;
  background-color: #25a56a;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 14px;
  position: relative;
  z-index: 2;
  text-transform: uppercase;
  font-family: Inter, sans-serif;
  font-weight: 500;
  padding: 0 25px;
  margin-top: 15px;
  cursor: pointer;
}

.release__buy svg {
  fill: #fff;
  width: 28px;
  height: auto;
  margin-right: 7px;
}

.release__buy:hover {
  background-color: #222227;
  color: #fff;
}

@media (min-width: 768px) {
  .release {
    flex-direction: row;
    margin-bottom: 10px;
  }

  .release__list {
    margin-top: 0;
    width: calc(100% - 250px);
  }

  .release__content {
    width: 230px;
    margin-right: 20px;
  }
}

@media (min-width: 1200px) {
  .release__list {
    width: calc(100% - 310px);
    height: 340px;
  }

  .release__content {
    width: 290px;
  }
}

.store-item {
  margin-top: 20px;
  border: 1px solid #222227;
  background-color: #16151a;
  padding: 20px;
  border-radius: 12px;
  position: release;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.store-item__cover {
  display: block;
  position: release;
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
}

.store-item__cover img {
  width: 100%;
}

.store-item__description {
  position: relative;
  width: 100%;
  margin-top: 20px;
}

.store-item__buy {
  width: 100%;
  height: 50px;
  border-radius: 12px;
  background-color: #25a56a;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 14px;
  position: relative;
  z-index: 2;
  text-transform: uppercase;
  font-family: Inter, sans-serif;
  font-weight: 500;
  padding: 0 25px;
  margin-top: 15px;
}

.store-item__buy svg {
  fill: #fff;
  width: 28px;
  height: auto;
  margin-right: 7px;
}

.store-item__buy:hover {
  background-color: #222227;
  color: #fff;
}

.store-item__content {
  width: 100%;
  display: block;
}

.store-item__carousel {
  position: relative;
  width: 100%;
}

@media (min-width: 768px) {
  .store-item {
    flex-direction: row;
  }

  .store-item__description {
    margin-top: 0;
    width: calc(100% - 250px);
  }

  .store-item__content {
    width: 230px;
    margin-right: 20px;
  }
}

@media (min-width: 1200px) {
  .store-item__description {
    width: calc(100% - 310px);
  }

  .store-item__content {
    width: 290px;
  }
}

.artist {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 20px;
  width: 100%;
  position: relative;
}

.artist__cover {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  position: relative;
  overflow: hidden;
  width: 100%;
}

.artist__cover:before {
  content: '';
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg,
      rgba(37, 165, 106, 0.8) 0,
      rgba(37, 165, 106, 0) 100%);
  opacity: 0;
  transition: opacity 0.5s ease;
  z-index: 2;
}

.artist__cover img {
  width: 100%;
  display: block;
  position: relative;
  z-index: 1;
}

.genre-item {
  position: relative;
}

.genre-item:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.genre-item img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.artist__title {
  z-index: 3;
  color: #fff;
  font-size: 16px;
  margin-bottom: 0;
  text-align: center;
  width: 100%;
  transition: color 0.5s ease;
  margin-top: 10px;
  font-family: Inter, sans-serif;
  font-weight: 400;
}

.artist:hover .artist__cover:before {
  opacity: 0.3;
}

.artist:hover .artist__title {
  color: #25a56a;
}

@media (min-width: 768px) {
  .artist {
    margin-top: 30px;
  }
}

.event {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  margin-top: 20px;
  width: 100%;
  border-radius: 12px;
  position: relative;
  overflow: hidden;
  padding: 20px;
  height: 300px;
}

.event:before {
  content: '';
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg,
      rgba(0, 0, 0, 0.8) 0,
      rgba(0, 0, 0, 0) 100%);
  opacity: 0.6;
  transition: opacity 0.5s ease;
  z-index: 1;
}

.event__date {
  position: relative;
  z-index: 2;
  font-size: 18px;
  font-weight: 400;
  color: #fff;
}

.event__time {
  position: relative;
  z-index: 2;
  font-size: 14px;
  color: silver;
  font-weight: 400;
  margin-bottom: 20px;
}

.event__title {
  position: relative;
  z-index: 2;
  font-family: Inter, sans-serif;
  font-weight: 400;
  color: #fff;
  font-size: 24px;
  margin-bottom: 5px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-height: 34px;
}

.event__title a {
  color: #fff;
}

.event__title a:hover {
  color: #25a56a;
}

.event__address {
  position: relative;
  z-index: 2;
  font-size: 14px;
  color: #fff;
  line-height: 24px;
  margin-bottom: 0;
  width: 100%;
}

.event__ticket {
  position: absolute;
  top: 20px;
  left: 20px;
  min-width: 130px;
  height: 40px;
  border-radius: 12px;
  background-color: #222227;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 14px;
  z-index: 2;
  font-family: Inter, sans-serif;
  font-weight: 500;
  padding: 0 15px;
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.event__ticket svg {
  width: 18px;
  height: auto;
  margin-right: 7px;
  fill: #25a56a;
  transition: fill 0.5s ease;
}

.event__ticket:hover {
  color: #fff;
  background-color: #25a56a;
  border-color: #25a56a;
}

.event__ticket:hover svg {
  fill: #fff;
}

.event:hover:before {
  opacity: 0.8;
}

@media (min-width: 768px) {
  .event {
    margin-top: 30px;
    height: 320px;
  }

  .event__title {
    font-size: 26px;
  }
}

.single-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #222227;
  height: 58px;
}

.single-item:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: none;
  height: 48px;
}

.single-item__number {
  color: silver;
  font-size: 22px;
  min-width: 20px;
  width: auto;
  font-family: Inter, sans-serif;
  font-weight: 400;
}

.single-item__cover {
  position: relative;
  min-width: 48px;
  max-width: 48px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  overflow: hidden;
  transition: 0.5s ease;
}

.single-item__export:hover {
  background-color: rgba(128, 81, 212, 0.3);
}

.single-item__cover img {
  width: 100%;
  height: 48px;
  opacity: 1;
  transition: opacity 0.5s ease;
  position: relative;
  object-fit: cover;
}

.single-item__cover svg {
  position: absolute;
  opacity: 0;
  fill: #fff;
  width: 24px;
  height: auto;
  margin: 1px 0 0 1px;
  transition: 0.5s ease;
}

.single-item__cover svg:last-child {
  opacity: 0;
}

.single-item__cover:hover svg {
  fill: #25a56a;
}

.single-item__cover.active {
  background-color: #000;
}

.single-item__cover.active img {
  opacity: 0.35;
}

.single-item__cover.active svg {
  fill: #25a56a;
}

.single-item__title {
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 15px;
  margin-right: auto;
  width: calc(100% - 48px);
}

.single-item__title h4 {
  display: block;
  width: 100%;
  color: #fff;
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.single-item__title h4 a {
  color: #fff;
}

.single-item__title h4 a:hover {
  color: #25a56a;
}

.single-item__title span {
  display: block;
  width: 100%;
  color: silver;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}

.single-item__title span a {
  color: silver;
}

.single-item__title span a:hover {
  color: #25a56a;
}

.single-item__time {
  margin-left: 15px;
  font-size: 13px;
  color: silver;
}

.single-item__time--live {
  background-color: #eb5757;
  color: #fff;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 24px;
  padding: 0 12px;
  border-radius: 8px;
  font-size: 12px;
  font-family: Inter, sans-serif;
  font-weight: 400;
}

.single-item__add {
  margin-left: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 32px;
  max-width: 32px;
  height: 32px;
  border-radius: 8px;
  background-color: rgba(37, 165, 106, 0.15);
  cursor: pointer;
}

.single-item__add svg {
  width: 18px;
  height: auto;
  fill: #25a56a;
}

.single-item__add:hover {
  background-color: rgba(37, 165, 106, 0.3);
}

.single-item__export {
  margin-left: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 32px;
  max-width: 32px;
  height: 32px;
  border-radius: 8px;
  background-color: rgba(128, 81, 212, 0.15);
}

@media only screen and (max-width: 600px) {
  .single-item__export {
    display: none;
  }
}

.single-item__export svg {
  width: 18px;
  height: auto;
  fill: #8051d4;
}

.single-item:hover .single-item__cover {
  background-color: #222227;
}

.single-item:hover .single-item__cover img {
  opacity: 0.35;
}

.single-item:hover .single-item__cover svg {
  opacity: 1;
}

.single-item:hover .single-item__cover svg:last-child {
  opacity: 0;
}

.live {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 20px;
  position: relative;
  overflow: hidden;
}

.live__cover {
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.live__cover img {
  position: relative;
  width: 100%;
  z-index: 1;
  transition: 0.5s ease;
}

.live__cover svg {
  position: absolute;
  display: block;
  fill: #fff;
  width: 46px;
  height: auto;
  transition: 0.5s ease;
  z-index: 3;
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
  opacity: 0;
}

.live__cover:before {
  content: '';
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 2;
  background: linear-gradient(45deg,
      rgba(37, 165, 106, 0.8) 0,
      rgba(37, 165, 106, 0) 100%);
  opacity: 0.1;
  transition: opacity 0.5s ease;
}

.live__value {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  bottom: 20px;
  left: 20px;
  z-index: 3;
  pointer-events: none;
  height: 28px;
  width: auto;
  padding: 0 15px;
  border-radius: 8px;
  background-color: rgba(34, 34, 39, 0.8);
  color: #fff;
  font-size: 12px;
  font-weight: 400;
}

.live__status {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 3;
  pointer-events: none;
  height: 24px;
  width: auto;
  padding: 0 12px;
  border-radius: 8px;
  background-color: #eb5757;
  color: #fff;
  font-size: 12px;
  text-transform: uppercase;
  font-family: Inter, sans-serif;
  font-weight: 400;
}

.live__title {
  font-size: 20px;
  font-family: Inter, sans-serif;
  font-weight: 400;
  color: #fff;
  margin-bottom: 0;
  margin-top: 10px;
  width: 100%;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-wrap: break-word;
  transition: color 0.5s ease;
}

.live__title a {
  color: #fff;
}

.live:hover .live__cover:before {
  opacity: 0.3;
}

.live:hover .live__cover svg {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
}

.live:hover .live__title {
  color: #25a56a;
}

.live:hover .live__title a {
  color: #25a56a;
}

@media (min-width: 768px) {
  .live {
    margin-top: 30px;
  }
}

.product {
  position: relative;
  margin-top: 20px;
  border-radius: 12px;
  width: 100%;
  overflow: hidden;
  background-color: #222227;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px;
}

.product__img {
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
}

.product__img:before {
  content: '';
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, #000 0, rgba(0, 0, 0, 0) 100%);
  opacity: 0;
  transition: opacity 0.5s ease;
  z-index: 2;
}

.product__img img {
  width: 100%;
  display: block;
  position: relative;
  z-index: 1;
}

.product__img:hover:before {
  opacity: 0.3;
}

.product__img:hover .product__add {
  -webkit-transform: scale(1);
          transform: scale(1);
  opacity: 1;
}

.product__add {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  top: 50%;
  left: 50%;
  margin: -24px 0 0 -24px;
  border-radius: 12px;
  background-color: #222227;
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
  opacity: 0;
  transition: 0.5s ease;
  transition-property: opacity, -webkit-transform;
  transition-property: opacity, transform;
  transition-property: opacity, transform, -webkit-transform;
  z-index: 3;
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.product__add svg {
  fill: #fff;
  width: 24px;
  height: auto;
  transition: fill 0.5s ease;
}

.product__add:hover svg {
  fill: #25a56a;
}

.product__title {
  display: block;
  width: 100%;
  font-size: 17px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  margin-top: 15px;
}

.product__title a {
  color: #fff;
}

.product__title a:hover {
  color: #25a56a;
}

.product__price {
  font-size: 16px;
  color: silver;
  margin-top: 5px;
  display: block;
  width: 100%;
  font-family: Inter, sans-serif;
  font-weight: 400;
}

.product__new {
  font-size: 14px;
  color: #eb5757;
  position: absolute;
  bottom: 20px;
  right: 20px;
  font-weight: 600;
}

@media (min-width: 768px) {
  .product {
    margin-top: 30px;
  }
}

.feature {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  overflow: hidden;
  margin-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #222227;
}

.feature__icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 46px;
  height: 46px;
  border-radius: 12px;
  background-color: rgba(37, 165, 106, 0.15);
}

.feature__icon svg {
  fill: #25a56a;
  width: auto;
  height: 26px;
}

.feature__title {
  font-family: Inter, sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 0;
  margin-left: 15px;
}

.feature__text {
  display: block;
  width: 100%;
  font-size: 16px;
  line-height: 26px;
  color: silver;
  margin-bottom: 0;
  margin-top: 15px;
}

@media (min-width: 768px) {
  .feature {
    margin-top: 30px;
    padding-bottom: 0;
    border-bottom: none;
  }
}

@media (min-width: 1200px) {
  .feature {
    margin-top: 40px;
  }
}

.contacts__list {
  margin-top: 20px;
}

.contacts__list li {
  margin-bottom: 15px;
}

.contacts__list li:last-child {
  margin-bottom: 0;
}

.contacts__list a {
  font-size: 16px;
  color: #fff;
  font-family: Inter, sans-serif;
  font-weight: 400;
}

.contacts__list a:hover {
  color: #25a56a;
}

.contacts__social {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
}

.contacts__social a {
  margin-top: 20px;
  margin-right: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  position: relative;
  transition-property: all;
}

.contacts__social a svg {
  width: 100%;
  height: auto;
}

.contacts__social a:last-child {
  margin-right: 0;
}

.contacts__social a:hover {
  opacity: 1 !important;
  -webkit-filter: grayscale(0) !important;
  filter: grayscale(0) !important;
}

.contacts__social:hover a {
  opacity: 0.7;
  -webkit-filter: grayscale(0.7);
  filter: grayscale(0.7);
}

@media (min-width: 768px) {
  .contacts__social {
    margin-top: 10px;
  }
}

.footer {
  margin-top: 100px;
  background-color: #222227;
  padding: 30px 0 20px;
  position: relative;
  overflow: hidden;
}

@media only screen and (max-width: 600px) {
  .footer {
    margin-top: 0px;
  }
}

.footer__logo {
  margin-top: 60px;
}

.footer__logo img {
  width: 100px;
  height: auto;
}

.footer__tagline {
  margin-top: 15px;
  display: block;
  font-size: 14px;
  line-height: 24px;
  color: silver;
  margin-bottom: 0;
}

.footer__links {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-top: 5px;
}

.footer__links a {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
  color: silver;
  font-size: 16px;
}

.footer__links a svg {
  width: 20px;
  height: auto;
  fill: #25a56a;
  margin-right: 10px;
}

.footer__links a:hover {
  color: #25a56a;
}

.footer__social {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
}

.footer__social a {
  margin-top: 20px;
  margin-right: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  position: relative;
  transition-property: all;
}

.footer__social a svg {
  width: 100%;
  height: auto;
}

.footer__social a:last-child {
  margin-right: 0;
}

.footer__social a:hover {
  opacity: 1 !important;
  -webkit-filter: grayscale(0) !important;
  filter: grayscale(0) !important;
}

.footer__social:hover a {
  opacity: 0.6;
  -webkit-filter: grayscale(0.6);
  filter: grayscale(0.6);
}

.footer__content {
  margin-top: 60px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.footer__copyright {
  display: block;
  font-size: 14px;
  color: silver;
  margin-top: 20px;
}

.footer__copyright a {
  color: silver;
}

.footer__copyright a:hover {
  color: #25a56a;
}

.footer__title {
  display: block;
  margin-top: 30px;
  font-size: 16px;
  color: #fff;
  font-family: Inter, sans-serif;
  font-weight: 500;
  margin-bottom: 15px;
}

.footer__nav {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.footer__nav a {
  font-size: 15px;
  line-height: 24px;
  margin-bottom: 10px;
  color: silver;
}

.footer__nav a:last-child {
  margin-bottom: 0;
}

.footer__nav a:hover {
  color: #25a56a;
}

@media (min-width: 576px) {
  .footer {
    padding: 30px 15px 20px;
  }
}

@media (min-width: 768px) {
  .footer {
    padding: 20px 15px 25px;
  }

  .footer__content {
    margin-top: 70px;
    padding-top: 25px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .footer__logo {
    margin-top: 50px;
  }

  .footer__title {
    margin-top: 50px;
  }

  .footer__social {
    width: auto;
    order: 2;
  }

  .footer__social a {
    margin-top: 0;
  }

  .footer__copyright {
    margin-top: 0;
    order: 1;
  }
}

@media (min-width: 1200px) {
  .footer {
    padding-left: 295px;
    padding-right: 15px;
  }

  .footer__social {
    display: inline-flex;
    width: auto;
  }
}

.sign {
  display: block;
  position: relative;
}

.sign__content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  padding: 20px 0 0;
}

.text-error {
  color: #f60000;
  font-size: 14px;
  font-weight: 400;
  padding-top: 6px;
}

.sign__form {
  background-color: #16151a;
  border-radius: 12px;
  padding: 30px 20px;
  position: relative;
  width: 100%;
  max-width: 420px;
  border: 1px solid #222227;
}

.sign__form--contacts,
.sign__form--profile {
  margin-top: 20px;
  max-width: 100%;
  padding: 20px 20px 5px;
  align-items: flex-start;
}

.sign__form--contacts .sign__btn,
.sign__form--profile .sign__btn {
  margin: 0 0 15px;
}

.sign__form--contacts .row,
.sign__form--profile .row {
  margin-left: -10px;
  margin-right: -10px;
}

.sign__form--contacts .col-12,
.sign__form--profile .col-12 {
  padding-left: 10px;
  padding-right: 10px;
}

.sign__form--profile .sign__btn {
  margin: 5px 0 15px;
}

.sign__logo {
  display: block;
  margin-bottom: 30px;
}

.sign__logo a {
  max-width: 100%;
  width: auto;
}

.sign__logo img {
  width: 150px;
}

.sign__title {
  font-size: 16px;
  font-weight: 500;
  font-family: Inter, sans-serif;
  color: #fff;
  margin-bottom: 20px;
}

.sign__label {
  font-weight: 400;
  font-size: 14px;
  color: silver;
  margin-bottom: 5px;
  padding-left: 20px;
}

.sign__label b {
  font-weight: 600;
  color: #fff;
}

.sign__group {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  flex-wrap: wrap;
  position: relative;
  margin-bottom: 20px;
  width: 100%;
}

.sign__textarea {
  background-color: #222227;
  border: 1px solid transparent;
  border-radius: 12px;
  height: 144px;
  position: relative;
  color: #fff;
  font-size: 16px;
  width: 100%;
  padding: 15px 20px;
  resize: none;
  font-family: 'Inter', sans-serif;
}

.sign__input {
  background-color: #222227;
  border: 1px solid transparent;
  border-radius: 12px;
  height: 46px;
  position: relative;
  color: #fff;
  font-size: 16px;
  width: 100%;
  padding: 0 20px;
  font-family: Inter, sans-serif;
}

.sign__input:focus,
.sign__textarea:focus {
  border-color: #25a56a;
}

.sign__select {
  background-size: 18px auto;
  border: 1px solid transparent;
  border-radius: 12px;
  height: 46px;
  position: relative;
  color: #fff;
  font-size: 16px;
  width: 100%;
  padding: 0 20px;
  cursor: pointer;
  font-family: Inter, sans-serif;
}

.sign__select:focus {
  border-color: #25a56a;
}

.sign__btn {
  width: 100%;
  height: 50px;
  border-radius: 12px;
  background-color: #25a56a;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 14px;
  position: relative;
  z-index: 2;
  text-transform: uppercase;
  font-family: Inter, sans-serif;
  font-weight: 500;
  margin: 15px auto;
}

.sign__btn:hover {
  color: #fff;
  background-color: #222227;
}

.sign__text {
  margin-top: 15px;
  font-size: 14px;
  line-height: 24px;
  color: #fff;
  width: 100%;
  text-align: center;
}

.sign__text:first-child {
  margin-top: 0;
}

.sign__text a {
  position: relative;
  color: #25a56a;
}

.sign__text a:hover {
  color: #25a56a;
  text-decoration: underline;
}

.sign__text--small {
  font-size: 13px;
  line-height: 20px;
  text-align: left;
}

.sign__social {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
  margin-top: 15px;
}

.sign__social a {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 46px;
  width: calc(33% - 10px);
  border-radius: 12px;
  color: #fff;
}

.sign__social a svg {
  height: auto;
  fill: #fff;
  transition: 0.5s ease;
}

.sign__social a:hover {
  background-color: silver;
}

.sign__value {
  font-size: 24px;
  color: #fff;
  display: block;
  font-weight: 500;
  margin-left: 10px;
  font-family: Inter, sans-serif;
}

.sign__radio {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-top: 5px;
}

.sign__radio li {
  position: relative;
  overflow: hidden;
  margin-bottom: 10px;
}

.sign__radio li:last-child {
  margin-bottom: 0;
}

.sign__radio input:checked,
.sign__radio input:not(:checked) {
  position: absolute;
  left: -9999px;
}

.sign__radio label {
  display: block;
  margin: 0;
  position: relative;
  font-weight: 400;
  cursor: pointer;
  font-size: 16px;
  color: silver;
  line-height: 24px;
  padding-left: 32px;
  transition: 0.5s ease;
}

.sign__radio label:before {
  content: '';
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  border: 6px solid #222227;
  background-color: transparent;
  border-radius: 50%;
  left: 0;
  top: 2px;
  transition: 0.5s ease;
}

.sign__radio label:hover {
  color: #fff;
}

.sign__radio input:checked+label {
  color: #fff;
}

.sign__radio input:checked+label:before {
  border-color: #25a56a;
}

@media (min-width: 576px) {
  .sign__form {
    padding: 40px;
  }

  .sign__form--contacts,
  .sign__form--profile {
    padding: 20px 20px 5px;
  }

  .sign__logo {
    margin-bottom: 40px;
    text-align: center;
  }
}

@media (min-width: 768px) {

  .sign__form--contacts,
  .sign__form--profile {
    margin-top: 30px;
  }

  .sign__form--contacts .sign__btn,
  .sign__form--profile .sign__btn {
    margin: 10px 0 15px;
  }

  .sign__content {
    padding: 30px 0 0;
  }
}

@media (min-width: 992px) {
  .sign__form--profile .sign__btn {
    width: 180px;
  }
}

.modal {
  position: relative;
  display: block;
  background-color: #16151a;
  margin: 40px auto;
  width: 100%;
  max-width: 420px;
  padding: 20px;
  border-radius: 12px;
  overflow: hidden;
}

.modal__icon {
  display: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 15px;
  right: 15px;
  pointer-events: none;
  opacity: 0.35;
}

.modal__icon svg {
  width: 70px;
  height: auto;
  fill: silver;
}

.modal__icon--green svg {
  fill: #25a56a;
}

.modal--form {
  padding: 20px 20px 5px;
}

.modal__close {
  position: absolute;
  top: 20px;
  right: 20px;
}

.modal__close svg {
  fill: silver;
  opacity: 0.6;
  transition: 0.5s ease;
  height: auto;
  width: 24px;
}

.modal__close:hover svg {
  opacity: 1;
  fill: #25a56a;
}

@media (min-width: 768px) {
  .modal {
    padding: 40px;
  }

  .modal__icon {
    bottom: 30px;
    right: 30px;
  }

  .modal--form {
    padding: 40px 40px 25px;
  }

  .modal--form .modal__close {
    top: 37px;
    right: 35px;
  }

  .modal--form .sign__btn {
    margin-top: 30px;
  }
}

.main__filter {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  background-color: #16151a;
  position: relative;
}

.slider-radio {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #222227;
  padding: 5px 0;
  border-radius: 12px;
  margin-top: 10px;
  position: relative;
  width: 100%;
  z-index: 2;
}

.slider-radio label {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 30px;
  padding: 0 15px;
  font-size: 14px;
  color: silver;
  margin-bottom: 0;
  cursor: pointer;
  border-radius: 10px;
  margin: 0 5px;
  position: relative;
  z-index: 100;
  transition: color 0.5s;
  font-family: Inter, sans-serif;
  font-weight: 400;
}

.slider-radio label:hover {
  color: #fff;
}

.slider-radio input[type='radio']:checked+label {
  color: #fff;
  background-color: #25a56a;
}

@media (min-width: 768px) {
  .slider-radio {
    margin-left: auto;
    margin-top: 0;
    width: auto;
  }
}

.select2-container {
  width: auto !important;
  margin-right: 30px;
}


@media (max-width: 600px) {
  .header__actions .header__action--signin {
    display: none;
  }

  .sidebar .header__action {
    display: block;
  }

  .header__action-btn span {
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 16px;
    color: silver;
    font-family: Inter, sans-serif;
    margin: 0 10px 0 15px;
    transition: 0.4s ease;
    position: relative;
  }

  .header__action-btn {
    justify-content: flex-start;
  }

  .release {
    display: block;
  }

  .album__stat {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
    bottom: 6px;
  }

  .play-icon {
    width: 40px;
    height: 40px;
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
    margin: -20px 0 0 -20px;
    border-radius: 8px;
    opacity: 0.45;
  }

  .single-item__cover img+svg {
    opacity: 1;
  }

  .main__title {
    margin-top: 40px;
  }

  .release {
    padding: 0;
    border: 0px;
  }
}

.infinite-scroll-component {
  overflow: visible !important;
}

.container svg {
  height: unset;
  width: unset;
}

.time_options {
  display: flex;
  justify-content: center;
}

.time_options-item{
  font-size: 30px;
  color: #545454;
  padding: 0 15px;
  cursor: pointer;
}

.time_options-item:active {
  color: #25a56a;
}

.dialog__actions__timer {
  display: flex;
  padding: 32px 0;
  font-size: 32px;
  font-weight: 400;
  justify-content: space-evenly;
}
.container {
  font-family: sans-serif;
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin: 10px;
}

.textContainer {
  position: absolute;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 100px;
  line-height: 90px;
  text-align: center;
  color: #545454;
}

.textContainer .minute {
  font-size: 24px;
  line-height: 20px;
  background: #545454;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.seperate {
  border: 0.5px solid #545454;
}

.timer__button {
  width: 100px !important;
  padding:10px 0 !important;
  
}

.dialog__timer .MuiDialog-paper {
  background: #15161A;
  overflow: hidden;
}

.sleep__timer_button {
  cursor: pointer;
 
}
.music-actions {
  display: flex;
  margin: 10px 0;
  margin-bottom: 32px;
}

.left__action {
  display: flex;
  margin-right:0 !important;
}

.right__action {
  display: flex;
}

.item__action {
  padding-right: 16px;
}

@media only screen and (max-width: 768px) {
  .music-actions {
    display: block !important;
    margin: 8px 0;
  }

  .right__action {
    padding:16px 0;
  }
}

@media only screen and (max-width: 576px) {
  .music-actions {
    display: block !important;
    margin:0 !important;
  }

  .right__action {
    padding: 16px 0;
  }
}

.checkbox--details {
  padding-left: 24px !important;
  display: flex;
}

.title_bottom_nav {
  font-size: 10px;
}

.channel_info {
  display: flex;
  align-items: center;
  padding: 8px 0;
}

.navbar-footer {
  overflow: hidden;
  background-color: #333;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 25;
}

.navbar-footer a {
  float: left;
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 0 16px;
  text-decoration: none;
  font-size: 17px;
}

.navbar-footer a:hover {
 cursor: pointer;
}

.title_bottom_nav {
  font-size: 10px;
}
