@keyframes loader {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        border: 4px solid #f44336;
        border-left-color: transparent;
    }

    50% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
        border: 4px solid #673ab7;
        border-left-color: transparent;
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
        border: 4px solid #f44336;
        border-left-color: transparent;
    }
}

@-webkit-keyframes loader {
    0% {
        -webkit-transform: rotate(0deg);
        border: 4px solid #f44336;
        border-left-color: transparent;
    }

    50% {
        -webkit-transform: rotate(180deg);
        border: 4px solid #673ab7;
        border-left-color: transparent;
    }

    100% {
        -webkit-transform: rotate(360deg);
        border: 4px solid #f44336;
        border-left-color: transparent;
    }
}

/* Indicator styles */


/* Progress indicator */

.ipl-progress-indicator.available {
    opacity: 0
}

.ipl-progress-indicator {
    width: 100%;
    height: 100%;
    top: 0;
    position: fixed;
    opacity: 1;
    pointer-events: none;
    -webkit-transition: opacity cubic-bezier(.4, 0, .2, 1) 436ms;
    -moz-transition: opacity cubic-bezier(.4, 0, .2, 1) 436ms;
    transition: opacity cubic-bezier(.4, 0, .2, 1) 436ms;
    z-index: 9999;
}

.ipl-progress-indicator-head {
    background-color: #c6dafc;
    height: 5px;
    overflow: hidden;
    position: relative
}

.ipl-progress-indicator .first-indicator,
.ipl-progress-indicator .second-indicator {
    background-color: #36a8ff;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    position: absolute;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    transform-origin: left center;
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    transform: scaleX(0)
}

.ipl-progress-indicator .first-indicator {
    -webkit-animation: first-indicator 2s linear infinite;
    -moz-animation: first-indicator 2s linear infinite;
    animation: first-indicator 2s linear infinite
}

.ipl-progress-indicator .second-indicator {
    -webkit-animation: second-indicator 2s linear infinite;
    -moz-animation: second-indicator 2s linear infinite;
    animation: second-indicator 2s linear infinite
}

.ipl-progress-indicator .insp-logo {
    animation: App-logo-spin infinite 20s linear;
    border-radius: 50%;
    -webkit-align-self: center;
    -moz-align-self: center;
    align-self: center
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0)
    }

    to {
        transform: rotate(360deg)
    }
}

@-webkit-keyframes fadein {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@-moz-keyframes fadein {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@keyframes fadein {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@keyframes first-indicator {
    0% {
        transform: translate(0) scaleX(0)
    }

    25% {
        transform: translate(0) scaleX(.5)
    }

    50% {
        transform: translate(25%) scaleX(.75)
    }

    75% {
        transform: translate(100%) scaleX(0)
    }

    100% {
        transform: translate(100%) scaleX(0)
    }
}

@keyframes second-indicator {
    0% {
        transform: translate(0) scaleX(0)
    }

    60% {
        transform: translate(0) scaleX(0)
    }

    80% {
        transform: translate(0) scaleX(.6)
    }

    100% {
        transform: translate(100%) scaleX(.1)
    }
}